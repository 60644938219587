import { QuoteProduct } from './schemas/Product';
import { QuoteAPIProduct } from '../../api/accessexpress/schema';

export const termLengthOptions = [
  { value: '1', label: '1 year' },
  { value: '2', label: '2 years' },
  { value: '3', label: '3 years' },
  { value: '4', label: '4 years' },
  { value: '5', label: '5 years' },
];

export const ipTypeOptions = [
  { value: 'dynamic', label: 'Dynamic IP' },
  { value: 'static', label: 'Static IP' },
];

export const ipBlocksOptions = [
  { value: '/30', label: '/30 - 1 usable IP address' },
  { value: '/29', label: '/29 - 5 usable IP addresses' },
  { value: '/28', label: '/28 - 13 usable IP addresses' },
  { value: '/27', label: '/27 - 29 usable IP addresses' },
  { value: '/26', label: '/26 - 61 usable IP addresses' },
  { value: '/25', label: '/25 - 125 usable IP addresses' },
  { value: '/24', label: '/24 - 253 usable IP addresses' },
];

export const getLabelForIpBlock = (ipBlock: string) => {
  return ipBlocksOptions
    .find((option) => option.value === ipBlock)
    ?.label.split(' - ')[1];
};

export const getTermLabel = (term: string) =>
  termLengthOptions.find((tlo) => tlo.value === term)?.label ?? 'N/A';

export const getIpTypeLabel = (ipType: string) =>
  ipType === 'dynamic' ? 'Dynamic IP' : 'Static IP';

export const getProductTypeLabel = (product: QuoteProduct) =>
  product.type === 'broadband' ? 'Broadband Circuit' : 'DIA';

export const getProductRequirementLabel = (requirement: QuoteAPIProduct) => {
  return requirement.product_type === 'Broadband' ? 'Broadband Circuit' : 'DIA';
};

export const formatProductTypeName = (type: QuoteProduct['type']) => {
  switch (type) {
    case 'dia':
      return type.toUpperCase();
    default:
      // capitalize
      return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

export type QuoteDetailsType = {
  productCount: number;
  streetAddress: string;
  fullAddress: string;
  location: QuoteLocation;
  products: QuoteProductType[];
};

export type QuoteLocation = {
  id: string;
  city: string;
  address1: string;
  address2?: string;
};

export type QuoteProductType = {
  access_type: string | null;
  exclude_carriers: string[] | null;
  id: string;
  include_carriers: string[] | null;
  ip_block: string;
  ip_type: 'STATIC' | 'DYNAMIC';
  location_id: string;
  max_download: string;
  min_download: string;
  preferred_carriers: string[] | null;
  product_type: string;
  term: string;
};
