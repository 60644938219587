export const NUMBERS_ONLY_REGEXP = /^\d+$/;

export const LETTERS_SPACES_HYPENS_REGEXP = /^[a-zA-Z\s-]*$/;

export const LETTERS_SPACES_HYPENS_APOSTROPHE_REGEXP = /^[a-zA-Z\s'-]*$/;

export const MUST_BE_6_DIGITS_REGEXP = /^\d{6}$/;

export const LETTERS_SPACES_SPECIAL_CHARACTERS = /^[a-zA-Z0-9\s'\-•0-9]+\s*$/;

export const LETTERS_SPACES_DOTS_SPECIAL_CHARACTERS =
  /^[a-zA-Z0-9\s'\-•0-9.]+\s*$/;

export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9\s/-]+$/;

export const ALPHANUMERIC_WITH_COMMAS_AND_SPACES_VALIDATOR =
  /^([A-Za-z0-9_\-\\/\\ ]+, )*[A-Za-z0-9_\-\\/\\ ]+$/;

export const VALID_PHONE_NUMBER = /^[2-9]\d{2}[2-9]\d{6}$/;

export const UP_TO_6_DIGITS_REGEXP = /^\d{1,6}$/;

export const LETTERS_NUMBERS_AND_HYPHENS = /^[a-zA-Z0-9-]+$/;

export const NUMBERS_LETTERS_HYPHENS_SPACES_REGEXP = /^[A-Za-z0-9-\s]+$/;

export const NUMBERS_LETTERS_HYPHENS_SPACES_COMMAS_PERIODS_REGEXP =
  /^[A-Za-z0-9-\s,.]+$/;
