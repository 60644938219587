import { useState } from 'react';
import { Modal } from '../../../components/Modal/Modal';
import { ModalParams } from '../../../hooks/useModal';
import { GraniteButton } from '../../../components/V2/Button/GraniteButton';
import { DetailField } from 'screens/TicketDetail/TicketDetailUtil';
import { format } from 'date-fns';
import clsx from 'clsx';
import { Price } from 'components/Price';
import { Fragment, ReactNode } from 'react';
import Checkbox from 'components/Checkbox';

export interface ReviewOrderForm {
  id: string;
  name: string;
  creation_date: Date;
  expiration_date: Date;
  service_categories: string;
  total_services: string;
  total_locations: string;
  total_nonrecurring_charges: number;
  total_monthly_charges: number;
}

export interface OpenTicketConfirmationDialogProps extends ModalParams {
  formData?: ReviewOrderForm;
  onConfirmation: (formData: ReviewOrderForm) => void;
}

const DetailRow = ({ label, value }: { label: string; value: ReactNode }) => (
  <Fragment>
    <span className="justify-self-start text-base font-bold leading-[22px] text-content-base-subdued">
      {label}
    </span>
    <span
      className={clsx(
        'justify-self-end text-base font-bold leading-[22px] text-content-base-default',
      )}
    >
      {value}
    </span>
  </Fragment>
);

export const ReviewOrderConfirmationDialog = ({
  isOpen,
  close,
  formData,
  onConfirmation,
}: OpenTicketConfirmationDialogProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Modal
      isVisible={isOpen}
      close={close}
      className="max-h-[800px] max-w-[624px] overflow-y-auto"
    >
      <div className="flex flex-col gap-2 rounded-t-lg bg-background-base-surface-3 px-8 py-6">
        <h1 className="text-[28px] leading-9 text-content-base-default">
          Review & confirm order
        </h1>
        <p className="font-semibold leading-[22px] text-content-base-subdued">
          Take a moment to review the details of your order. This includes your
          selected plan, any additional services, and the total cost.
        </p>
      </div>
      {formData && (
        <div className="flex flex-col gap-3 p-8">
          <div className="mb-2 flex items-center gap-4">
            <div className="text-2xl font-bold text-content-base-default">
              {formData.name}
            </div>
            <div
              className={`leading-2 flex h-9 w-[124px]  items-center justify-center rounded-lg bg-blue-600 px-[6px] text-center text-base font-bold text-content-base-default`}
            >
              QR #{formData.id}
            </div>
          </div>
          <DetailField
            description="Quote creation date"
            value={format(formData.creation_date, 'MM/dd/yyyy')}
          />
          <DetailField
            description="Quote expiration date"
            value={format(formData.expiration_date, 'MM/dd/yyyy')}
          />
          <div className="mt-3 grid grid-cols-[auto_1fr] items-center gap-y-3 rounded bg-background-base-surface-1 p-4">
            <DetailRow
              label="Service categories"
              value={formData.service_categories}
            />
            <DetailRow
              label="Total services ordered"
              value={formData.total_services}
            />
            <DetailRow
              label="Total locations"
              value={formData.total_locations}
            />
            <DetailRow
              label="Total non-recurring charges"
              value={
                <Price
                  total={formData.total_nonrecurring_charges}
                  className="justify-self-end text-base font-bold"
                />
              }
            />
            <DetailRow
              label="Total monthly recurring charges"
              value={
                <Price
                  total={formData.total_monthly_charges}
                  className="justify-self-end text-base font-bold"
                />
              }
            />
          </div>
          <div className="mt-3 flex flex-col gap-2">
            <p className="text-base font-bold text-content-base-subdued">
              By clicking the following, you are confirming that you have read,
              understood, and agree to{' '}
              <a
                href={`https://www.granitenet.com/Content/pdfs/Granite%20Website%20-%20General%20Terms%20and%20Conditions%20of%20Service%20(May%2023,%202022)%20(00053066xD85FF).pdf`}
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
              >
                {"Granite's"} Terms & Conditions.
              </a>
            </p>
            <Checkbox
              label="I agree to Granite’s Terms & Conditions"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="mt-2 flex gap-5">
              <GraniteButton size="large" variant="secondary" onClick={close}>
                Cancel
              </GraniteButton>
              <GraniteButton
                size="large"
                disabled={!isChecked}
                onClick={() => {
                  if (formData && onConfirmation) {
                    onConfirmation(formData);
                    close();
                  }
                }}
              >
                Agree & submit
              </GraniteButton>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
