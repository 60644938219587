import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { getQuoteBucketsRequest } from '../SelectServiceBundle/utils';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { PageTitleGranite } from 'components';
import Main from '../BaseComponents/MetaInfo/Main';
import Support from '../BaseComponents/MetaInfo/Support';
import LocationSection from '../BaseComponents/LocationSection';
import { SelectedTierInfo } from '../SelectServiceBundle/SelectServiceBundle';
import { useEffect, useState } from 'react';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import { format } from 'date-fns';

export const QuoteRequested = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTiers, setSelectedTiers] = useState<{
    [key: string]: SelectedTierInfo;
  }>({});

  const { data } = useQuery(
    ['access-express-quote-buckets', id],
    () => getQuoteBucketsRequest(id ?? ''),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const breadcrumbs = [
    {
      icon: 'home',
      label: 'AccessExpress',
      onClick: () => navigate('/access-express/index'),
    },
    {
      label: `#${data?.qe_quote_request_id}`,
      onClick: () => navigate(`/access-express/quote-details/${id}`),
    },
  ];

  const locationPricingTiers = data?.pricingTiers;

  useEffect(() => {
    if (locationPricingTiers) {
      const initialSelectedTiers = locationPricingTiers.reduce(
        (acc, loc) => {
          const advancedTier = loc.pricing_tiers.find((tier) => tier.selected);
          acc[loc.id] = {
            tier: advancedTier ? { ...advancedTier } : null,
            sendMePricing: loc.sendMePricing,
          };
          return acc;
        },
        {} as { [key: string]: SelectedTierInfo },
      );
      setSelectedTiers(initialSelectedTiers);
    }
  }, [locationPricingTiers]);

  const mainProps = {
    includedProducts: [
      ...new Set(data?.pricingTiers.map((tier) => tier.product_type)),
    ].join(', '),
    locations: data?.locations.length.toString() ?? '0',
    dateSubmitted: data?.qe_quote_created_at
      ? format(new Date(data?.qe_quote_created_at), 'MM/dd/yyyy')
      : '',
    submittedBy: data?.requester ?? '',
  };

  return (
    <ContentLayout>
      <PageTitleGranite
        breadcrumbs={breadcrumbs}
        title={
          <div className="flex items-center justify-center gap-6">
            <h1 className="text-4xl font-bold text-content-base-default">
              {data?.quote_name}
            </h1>
            <div className="rounded-lg bg-blue-600 px-4 py-[6px]">
              <span className="h-auto text-center text-base font-bold text-content-base-default">
                QR #{data?.qe_quote_request_id}
              </span>
            </div>
          </div>
        }
      />
      <div className="mt-6 grid grid-cols-5 items-end gap-1">
        <WizardStateBlock
          label="Add locations"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Choose services"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Select service bundle"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Request quote"
          isActive={true}
          isCompleted={true}
        />
        <WizardStateBlock
          label="Submit order"
          isActive={false}
          isCompleted={false}
        />
      </div>
      <div className="mt-12">
        <Main {...mainProps} />
        <Support
          description="A formal quote is being prepared. Once it's ready, you will be
          notified to review pricing and select services."
        />
      </div>
      <div className="mt-8 grid grid-cols-1 items-start gap-8 lg:grid-cols-[2fr_minmax(0,_502px)]">
        <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex w-full flex-col items-start justify-start">
            <h2 className="mb-2 text-2xl font-bold text-content-base-default">
              You may review the details of this quote request but you cannot
              make adjustments at this time.{' '}
            </h2>
          </div>
          {locationPricingTiers?.map((location) => {
            return (
              <LocationSection
                key={location.id}
                location={location}
                onSelectedTier={console.log}
                selectedTier={selectedTiers[location.id]?.tier}
                pricingAvailable={true}
                isPreview={true}
              />
            );
          })}
        </div>
        <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex gap-4">
            <GraniteButton
              size="large"
              className="w-full"
              onClick={console.log}
              disabled={true}
            >
              Quote requested
            </GraniteButton>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
