import { z } from 'zod';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import { RecaptchaSchema } from 'api/common-schemas';

export const CUSTOMER_DESCRIPTION_TYPES = z.enum([
  'Existing Customer',
  'New Customer',
  'Agent/TEM/Vendor',
  'Wholesale',
]);

const PasswordSchema = z
  .object({
    password: z
      .string()
      .min(12, { message: 'Password must be at least 12 characters long' })
      .regex(/[a-z]/, { message: 'Password must contain lowercase letters' })
      .regex(/[A-Z]/, { message: 'Password must contain uppercase letters' })
      .regex(/[0-9]/, { message: 'Password must contain numbers' }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

const SignupWithoutPassword = z
  .object({
    first_name: z
      .string()
      .nonempty('First name is required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_HYPENS_REGEXP, {
        message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
      }),
    last_name: z
      .string()
      .nonempty('Last name is required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_HYPENS_REGEXP, {
        message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
      }),
    email: z.string().nonempty('Email is required').email(),
    phone_number: z
      .string()
      .nonempty('Phone number is required')
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    org_name: z.string().nonempty('Company is required'),
    type: z.object({
      label: CUSTOMER_DESCRIPTION_TYPES,
      value: CUSTOMER_DESCRIPTION_TYPES,
    }),
  })
  .merge(RecaptchaSchema);

export const SignUpSchema = z.intersection(
  SignupWithoutPassword,
  PasswordSchema,
);

export type SignUpForm = z.infer<typeof SignUpSchema>;

export const SignupResponseSchema = z.object({
  message: z.string(),
  status: z.string(),
  info: z.object({
    match: z.string(),
    org_created: z.boolean(),
    company_id: z.string(),
    data: z.object({
      email: z.string(),
      first_name: z.string(),
      last_name: z.string(),
      name: z.string(),
      org_name: z.string(),
      phone_number: z.string(),
      type: z.string(),
    }),
  }),
});

export type SignupResponse = z.infer<typeof SignupResponseSchema>;
