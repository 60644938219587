import React, { ReactNode, useState } from 'react';
import { TabsContainer } from './Tabs.styles';
import TabComponent from './TabComponent';

export interface Tab {
  icon?: ReactNode;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface TabsProps {
  tabs: Tab[];
  variant?: 'compact' | 'medium' | 'large';
  className?: string;
  withUnderline?: boolean;
  defaultActiveTab?: number;
}

const Tabs = ({
  tabs,
  variant = 'medium',
  className,
  withUnderline = true,
  defaultActiveTab = 0,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const handleTabClick = (tab: Tab, index: number) => {
    tab.onClick();
    setActiveTab(index);
  };

  return (
    <TabsContainer className={className}>
      {tabs.map((tab, index) => (
        <TabComponent
          key={index}
          variant={variant}
          title={tab.title}
          icon={tab.icon}
          disabled={tab.disabled}
          onClick={() => handleTabClick(tab, index)}
          active={index === activeTab}
          withUnderline={withUnderline}
        />
      ))}
      {withUnderline && (
        <div className="grow border-b-2 border-stroke-base-default" />
      )}
    </TabsContainer>
  );
};

export default Tabs;
