import { FC, PropsWithChildren, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiClient } from '../../api/apiClient';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getErrorResponse } from './util';

export const AxiosInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  useEffect(() => {
    const interceptorRequest = apiClient.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const controller = new AbortController();
        try {
          const token = await getAccessTokenSilently();
          config.headers['Authorization'] = `Bearer ${token}`;
        } catch (error) {
          controller.abort();
          await logout({
            logoutParams: {
              returnTo: window.location.origin + '/session-expired',
            },
          });
        }
        return { ...config, signal: controller.signal };
      },
    );

    const interceptorResponse = apiClient.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.reason === 'token is expired'
        ) {
          return logout({
            logoutParams: {
              returnTo: window.location.origin + '/session-expired',
            },
          });
        }
        return getErrorResponse(error);
      },
    );

    return () => {
      apiClient.interceptors.request.eject(interceptorRequest);
      apiClient.interceptors.response.eject(interceptorResponse);
    };
  }, [getAccessTokenSilently, logout]);

  return <>{children}</>;
};
