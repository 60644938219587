import { OpenNetOpsTicketLayout } from '../BaseComponents/OpenNetOpsTicketLayout';
import { OpenNetOpsTicketBaseForm } from '../BaseComponents/OpenNetOpsTicketBaseForm';
import FormSection from 'components/FormSection';
import {
  Controller,
  DeepPartial,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { GraniteSelect } from 'components/Select/Select';
import GraniteTextArea from 'components/TextArea/TextArea';
import Divider from 'components/Divider/Divider';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import AddEmailRecipient from 'screens/LetUsHelp/utils/AddEmailRecipient';
import ContactEmailTag from 'components/ContactEmailTag/ContactEmailTag';
import { FormFragment } from 'components/FormFragment/FormFragment';
import { useAuth0User } from 'hooks/useAuth0User';
import { TicketDetails, TicketDetailsFormSchema } from './schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import Checkbox from 'components/Checkbox';
import { FileUpload } from 'components/V2/FileUpload/FileUpload';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import SiteInformation from '../BaseComponents/SiteInformation';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import {
  WizardAction,
  useOpenNetOpsTicketContext,
} from '../WizardProvider/WizardReducer';
import { FindLocationOrServiceForm } from '../FindLocationOrService/schemas';
import { Navigate, useNavigate } from 'react-router-dom';
import { Dispatch, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import showToast from 'components/Toast/Toast';
import { createTicket, fetchNOCStaticData } from 'api/nocexpress/api';
import { ticketDetailsFormToRequest } from './ticketDetailsFormToRequest';
import { LocationsTable } from '../FindLocationOrService/LocationsTable';
import { BulkLocations } from '../FindLocationOrService/BulkAddLocations/schemas';
import { Configurations } from 'api/nocexpress/schemas/ConfigurationsSchema';
import isSiteAddress from '../utils/isSiteAddress';
import { determineHighestPriorityTicket } from '../utils/prioritizationList';
import { preventSubmitOnEnter } from 'shared/util/preventSubmitOnEnter';

const getInformation = (data?: FindLocationOrServiceForm) => {
  const service = isSiteAddress(data?.selectedIdentifier)
    ? data?.service[0].label
    : data?.selectedIdentifier?.service;
  const cID = isSiteAddress(data?.selectedIdentifier)
    ? data?.service[0].value
    : data?.selectedIdentifier?.id;
  const address = isSiteAddress(data?.selectedIdentifier)
    ? `${data?.selectedIdentifier?.address_line_1}, ${data?.selectedIdentifier?.city}, ${data?.selectedIdentifier?.state}, ${data?.selectedIdentifier?.zip}`
    : [
        data?.selectedIdentifier?.site.address_line_1,
        data?.selectedIdentifier?.site.city,
        data?.selectedIdentifier?.site.state,
        data?.selectedIdentifier?.site.zip,
      ]
        .filter(Boolean)
        .join(', ');

  const accountName = isSiteAddress(data?.selectedIdentifier)
    ? data?.selectedIdentifier?.account_name
    : data?.selectedIdentifier?.site.account_name;
  const siteName = isSiteAddress(data?.selectedIdentifier)
    ? data?.selectedIdentifier.site_name
    : data?.selectedIdentifier?.site.name;
  return [
    {
      label: 'Service',
      value: service ?? '--',
    },
    {
      label: 'Circuit ID',
      value: cID?.toString() ?? '--',
    },
    {
      label: 'Address',
      value: address ?? '--',
    },
    {
      label: 'Account',
      value: accountName ?? '--',
    },
    {
      label: 'Site name',
      value: siteName ?? '--',
    },
  ];
};

interface TicketDetailsProps {
  findLocationOrServiceForm?: FindLocationOrServiceForm;
  ticketDetailsDefaultValues?: DeepPartial<TicketDetails>;
  services?: Configurations[];
  bulkLocations?: BulkLocations['locations'];
  dispatch: Dispatch<WizardAction>;
}

const TicketDetailsForm = ({
  findLocationOrServiceForm,
  bulkLocations,
  services,
  ticketDetailsDefaultValues,
  dispatch,
}: TicketDetailsProps) => {
  const navigate = useNavigate();
  const [ticketType, setTicketType] = useState<string | null>(null);
  const [ticketSubtype, setTicketSubtype] = useState<string | null>(null);

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    setValue,
    formState: { errors, isDirty },
  } = useForm<TicketDetails>({
    resolver: zodResolver(TicketDetailsFormSchema),
    defaultValues: {
      isSendNotificationsViaEmailEnabled: true,
      ...ticketDetailsDefaultValues,
    },
  });

  watch((formData) => {
    if (isDirty) {
      dispatch({
        type: 'SaveDetailsPartial',
        partialTicketDetails: formData,
      });
    }
  });

  useEffect(() => {
    if (findLocationOrServiceForm?.type === 'multi-site') {
      setTicketType('Multi-Site Service Request');
      setTicketSubtype(findLocationOrServiceForm.site_type?.value as string);
      return;
    }

    if (
      findLocationOrServiceForm?.service.find(
        (item) => item.value === 'service_not_listed',
      ) &&
      findLocationOrServiceForm.service_category
    ) {
      const [type_name, subtype_name] =
        findLocationOrServiceForm.service_category.value.split('|||');
      setTicketType(type_name);
      setTicketSubtype(subtype_name);
      return;
    }

    if (!services || services.length === 0) {
      showToast.error({
        message: 'Cannot open a ticket under this account and service.',
      });
      navigate('/noc-express/open-ticket');
      return;
    }

    const highestPriorityService = determineHighestPriorityTicket(services);
    if (
      !highestPriorityService ||
      !highestPriorityService.ticket_type ||
      !highestPriorityService.ticket_subtype
    ) {
      showToast.error({
        message: 'Cannot open a ticket under this account and service.',
      });
      navigate('/noc-express/open-ticket');
      return;
    }
    setTicketType(highestPriorityService.ticket_type);
    setTicketSubtype(highestPriorityService.ticket_subtype!);
  }, [
    services,
    navigate,
    findLocationOrServiceForm?.type,
    findLocationOrServiceForm?.site_type?.value,
    findLocationOrServiceForm?.service,
    findLocationOrServiceForm?.service_category,
    findLocationOrServiceForm,
  ]);

  const [canTestingBeDoneAnytime, maintenanceWindow] = watch([
    'canTestingBeDoneAnytime',
    'maintenance_window',
  ]);

  const { data: staticData } = useQuery(
    ['whatNeedsToBeDone-options'],
    () => fetchNOCStaticData(ticketType!, ticketSubtype!),
    {
      enabled: !!ticketType && !!ticketSubtype,
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (canTestingBeDoneAnytime) {
      resetField('maintenance_window', { defaultValue: '' });
    }
  }, [canTestingBeDoneAnytime, maintenanceWindow, resetField]);

  useEffect(() => {
    // workaround to make maintenance_window optional
    if (findLocationOrServiceForm?.type === 'multi-site') {
      setValue('canTestingBeDoneAnytime', true);
    }
  }, [findLocationOrServiceForm?.type, setValue]);

  const { email: ticketCreatorEmail } = useAuth0User();

  const createTicketMutation = useMutation(
    ({ newTicket }: { newTicket: FormData }) => createTicket(newTicket),
    {
      onSettled: () => {
        navigate('/noc-express?tab=tickets');
      },
      onSuccess: (data) => {
        showToast.confirmation({
          title: 'Ticket has been successfully opened',
          message: `Ticket #${data.id}`,
        });
        data.attachments
          ?.filter((a) => a.status === 'failure')
          .forEach((a) =>
            showToast.error({
              message: `The attachment '${a.filename}' could not be uploaded.`,
            }),
          );
      },
    },
  );

  const user = useAuth0User();

  const onSubmit = async (data: TicketDetails) => {
    createTicketMutation.mutate({
      newTicket: ticketDetailsFormToRequest(
        data,
        findLocationOrServiceForm!,
        user,
        ticketType!,
        ticketSubtype!,
        bulkLocations,
      ),
    });
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const isSendNotificationsViaEmailEnabled = watch(
    'isSendNotificationsViaEmailEnabled',
  );

  const onError = (err: unknown) => console.log(err);
  return (
    <OpenNetOpsTicketLayout>
      <OpenNetOpsTicketBaseForm
        className="min-h-[662px] gap-16"
        id="noc-ticket-details-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormSection title="Service details" gridClassName="grid-cols-1">
          <Controller
            name="whatNeedsToBeDone"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                error={errors.whatNeedsToBeDone?.message}
                value={value}
                onChange={onChange}
                options={staticData?.ticket_issues_dropdown_values?.map(
                  (item) => ({
                    label: item,
                    value: item,
                  }),
                )}
                label="What is the issue that needs to be fixed?"
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          <Controller
            name="initialDescription"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteTextArea
                {...field}
                onChange={onChange}
                value={value}
                error={errors.initialDescription?.message}
                label="Initial description of the issue"
                inputClassName="resize-y"
              />
            )}
          />
        </FormSection>
        <Divider />
        <FormSection title="Ticket details" gridClassName="grid-cols-2">
          <div className="col-span-2 flex flex-col bg-background-base-surface-1">
            <div className="flex flex-col gap-2 px-4 py-2">
              <div className="flex w-full items-center justify-between">
                <span className="w-1/2 text-base font-bold text-content-base-subdued">
                  Ticket type
                </span>
                <span className="w-1/2 text-left text-base font-bold text-content-base-default">
                  {ticketType}
                </span>
              </div>
            </div>
            <div className="w-full">
              <Divider />
            </div>
            <div className="flex flex-col gap-2 px-4 py-2">
              <div className="flex w-full items-center justify-between">
                <span className="w-1/2 text-base font-bold text-content-base-subdued">
                  Ticket subtype
                </span>
                <span className="w-1/2 text-left text-base font-bold text-content-base-default">
                  {ticketSubtype}
                </span>
              </div>
            </div>
          </div>
          <Controller
            name="customer_name"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteInput
                {...field}
                onChange={onChange}
                value={value}
                label="Customer ticket # (optional)"
                subtitle="Multiple entries should be separated by commas"
                className="col-span-1"
                error={errors.customer_name?.message}
              />
            )}
          />
          <Controller
            name="prior_ticket"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteInput
                {...field}
                onChange={onChange}
                value={value}
                label="Prior ticket # (optional)"
                subtitle="Tickets that share info pertaining to this issue"
                className="col-span-1"
                error={errors.prior_ticket?.message}
              />
            )}
          />
        </FormSection>
        <Divider />
        <FormSection
          title="Local contact"
          gridClassName="grid-cols-2"
          subtitle="A local contact should be someone on site that can provide access to the dispatch location."
        >
          <Controller
            name="local_name"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteInput
                {...field}
                onChange={onChange}
                value={value}
                label="Name"
                error={errors.local_name?.message}
                className="col-span-1"
              />
            )}
          />
          <PhoneNumberInput
            error={errors.local_number?.message}
            name={`local_number`}
            control={control}
            label="Phone number"
          />
        </FormSection>
        <Divider />
        {findLocationOrServiceForm?.type === 'multi-site' && (
          <LocationsTable locations={bulkLocations!} />
        )}
        {findLocationOrServiceForm?.type !== 'multi-site' && (
          <>
            <FormSection title="Maintenance window" gridClassName="grid-cols-1">
              <Controller
                name="maintenance_window"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <GraniteTextArea
                    {...field}
                    onChange={onChange}
                    value={maintenanceWindow}
                    label="Maintenance window"
                    subtitle="For intrusive testing purposes, list your location’s maintenance window for each day of the week."
                    inputClassName="resize-y"
                    placeholder="E.g., Mon-Fri 8:00AM-5:00PM EST; Sat-Sun 10:00AM-4:00PM EST"
                    error={errors.maintenance_window?.message}
                    disabled={canTestingBeDoneAnytime}
                  />
                )}
              />
              <div className="col-span-full">
                <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                  Allow intrusive testing regardless of date or time
                </h3>
                <h4 className="mb-2 text-sm text-content-base-subdued">
                  Check the box below if intrusive testing may be done at
                  anytime Mon-Fri.
                </h4>
                <Controller
                  name="canTestingBeDoneAnytime"
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Checkbox
                      {...field}
                      label="Yes, intrusive testing can be done anytime"
                      className="col-span-full"
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </FormSection>
            <Divider />
          </>
        )}
        <FormFragment title="Notifications" gridClassName="!gap-y-4">
          <div className="col-span-full flex flex-col gap-y-4">
            <div className="col-span-full">
              <h3 className="mb-2 text-base font-bold text-content-base-subdued">
                Would you like to receive notifications for this ticket?
              </h3>
              <Controller
                name="isSendNotificationsViaEmailEnabled"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Checkbox
                    {...field}
                    label="Send me updates via email"
                    className="col-span-full"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
            {isSendNotificationsViaEmailEnabled && (
              <div className="flex flex-wrap">
                <div className="flex h-8 items-center rounded-[32px] border border-stroke-base-subdued bg-background-base-surface-3 px-2">
                  <span className="fill-content-base-subdued pr-1 text-content-base-default">
                    {ticketCreatorEmail}
                  </span>
                </div>
              </div>
            )}
          </div>
          <AddEmailRecipient appendFunc={append} />
          <div className="col-span-full flex">
            <div className="flex flex-wrap gap-2">
              {(fields?.length ?? 0) > 0 &&
                fields?.map((field, i) => (
                  <ContactEmailTag
                    email={field.email}
                    index={i}
                    remove={remove}
                    key={i}
                  />
                ))}
            </div>
          </div>
        </FormFragment>
        <Divider />
        <FormSection title="Attachments">
          <Controller
            name="attachments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FileUpload
                label="Upload any additional files (optional)"
                subtitle="Documents pertaining to dispatch such as floor plan, install guide, survey document, photos of broken items, etc. Up to 25 MB of documents (.pdf, .doc, .csv, .xlsx) and images (.png, .jpg, .jpeg) are allowed"
                className="col-span-2"
                accept=".pdf,.docx,.doc,.xlsx,.csv,.png,.jpeg,.jpg"
                multiple
                value={value}
                onChange={(files) => {
                  onChange(files);
                }}
              />
            )}
          />
        </FormSection>
      </OpenNetOpsTicketBaseForm>
      <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
        {findLocationOrServiceForm?.type !== 'multi-site' &&
          !findLocationOrServiceForm?.service.find(
            (item) => item.value === 'service_not_listed',
          ) && (
            <div>
              <h3 className="text-base font-bold text-content-base-subdued">
                Service summary
              </h3>
              <SiteInformation
                showTitle={false}
                information={getInformation(findLocationOrServiceForm!)}
                chronic={false}
              />
            </div>
          )}
        <div className="flex gap-4">
          <GraniteButton
            className="w-full"
            variant="secondary"
            size="large"
            onClick={() => navigate(-1)}
          >
            Back
          </GraniteButton>
          <GraniteButton
            className="w-full"
            variant="primary"
            size="large"
            type="submit"
            form="noc-ticket-details-form"
            disabled={createTicketMutation.isLoading}
          >
            Submit
          </GraniteButton>
        </div>
      </div>
    </OpenNetOpsTicketLayout>
  );
};

export const TicketDetailsFormWrapper = () => {
  const { state, dispatch } = useOpenNetOpsTicketContext();

  if (
    state.state === 'FindLocationOrService' &&
    !state.findLocationOrServiceForm
  ) {
    // User is trying to access the route directly
    return <Navigate to="/noc-express/open-ticket" />;
  }

  if (state.state !== 'TicketDetails') {
    if (state.state === 'FindLocationOrService') {
      // User coming from FindLocationOrServiceForm using the browser forward button
      dispatch({
        type: 'GoToTicketDetails',
        partialTicketDetails: state.ticketDetails,
      });
    }
  }

  return (
    <TicketDetailsForm
      findLocationOrServiceForm={state.findLocationOrServiceForm}
      ticketDetailsDefaultValues={state.ticketDetails}
      services={state.services}
      bulkLocations={state.bulkLocations}
      dispatch={dispatch}
    />
  );
};
