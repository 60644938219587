import HorizontalBarChart, {
  BarChartData,
} from 'components/Charts/HorizontalBarChart';
import DonutChart, { DonutChartData } from 'components/Charts/DonutChart';
import TicketTypesChart, {
  TicketTypesChartData,
} from '../../components/Charts/TicketTypesChart';
import theme from '../../shared/theme/custom-theme';
import {
  ON_HOLD,
  PENDING_CUSTOMER,
  PENDING_TECH_ASSIGNMENT,
  TECH_ASSIGNED,
  TECH_CHECKED_IN,
} from '../../api/techexpress/schema';
import {
  getTicketPriorityMetrics,
  getTicketStatusMetrics,
  getTicketTypeMetrics,
} from '../../api/techexpress/api';
import { useQuery } from 'react-query';
import StatusIndicator from 'components/StatusIndicator';
import Divider from 'components/Divider';
import { TicketTypeMetricResponse } from '../../api/techexpress/schemas/MetricsSchemas';
import { Fragment, useMemo } from 'react';
import { EmptyState } from 'components/EmptyState/EmptyState';
import Skeleton from 'components/Skeleton/Skeleton';

const buildTicketTypesChartData = (
  data: TicketTypeMetricResponse | undefined,
): TicketTypesChartData[] => {
  return Object.entries(data ?? {})
    .sort(([_k1, v1], [_k2, v2]) => {
      return v2 - v1;
    })
    .filter(([_, val]) => val > 0)
    .map(([key, val]) => ({ ticketType: key, ticketCount: val }));
};

const buildBarChartData = (
  p1Count: number | undefined,
  p2Count: number | undefined,
  p3Count: number | undefined,
): BarChartData[] => {
  return [
    {
      category: 'Priority 3',
      value: p3Count ?? 0,
      backgroundColor: theme.colors.bar.priority3,
    },
    {
      category: 'Priority 2',
      value: p2Count ?? 0,
      backgroundColor: theme.colors.bar.priority2,
    },
    {
      category: 'Priority 1',
      value: p1Count ?? 0,
      backgroundColor: theme.colors.bar.priority1,
      color: theme.colors.text.white,
    },
  ];
};

const buildDonutChartData = (
  techCheckedInCount: number | undefined,
  techAssignedCount: number | undefined,
  pendingTechAssignmentCount: number | undefined,
  pendingCustomerCount: number | undefined,
  onHoldCount: number | undefined,
): DonutChartData[] => {
  return [
    {
      x: TECH_CHECKED_IN,
      y: techCheckedInCount ?? 0,
      backgroundColor: theme.colors.donut.techCheckedIn,
    },
    {
      x: TECH_ASSIGNED,
      y: techAssignedCount ?? 0,
      backgroundColor: theme.colors.donut.techAssigned,
    },
    {
      x: PENDING_TECH_ASSIGNMENT,
      y: pendingTechAssignmentCount ?? 0,
      backgroundColor: theme.colors.donut.pendingTechAssignment,
    },
    {
      x: PENDING_CUSTOMER,
      y: pendingCustomerCount ?? 0,
      backgroundColor: theme.colors.donut.pendingCustomer,
    },
    {
      x: ON_HOLD,
      y: onHoldCount ?? 0,
      backgroundColor: theme.colors.donut.onHold,
    },
  ];
};

export const TechExpressMetrics: React.FC = () => {
  const { data: statusMetrics, isLoading } = useQuery(
    ['tech-express', 'status-metrics'],
    () => getTicketStatusMetrics(),
  );
  const { data: priorityMetrics } = useQuery(
    ['tech-express', 'priority-metrics'],
    () => getTicketPriorityMetrics(),
  );
  const { data: typeMetrics } = useQuery(['tech-express', 'type-metrics'], () =>
    getTicketTypeMetrics(),
  );
  const openedTickets = Object.values(statusMetrics ?? {}).reduce(
    (total, next) => total + next,
    0,
  );
  const ticketTypeData = useMemo(
    () => buildTicketTypesChartData(typeMetrics),
    [typeMetrics],
  );
  const statusChartData = useMemo(
    () =>
      buildDonutChartData(
        statusMetrics?.['Tech Checked In'],
        statusMetrics?.['Tech Assigned'],
        statusMetrics?.['Pending Tech Assignment'],
        statusMetrics?.['Pending Customer'],
        statusMetrics?.['On Hold'],
      ),
    [statusMetrics],
  );
  const priorityChartData = useMemo(
    () =>
      buildBarChartData(
        priorityMetrics?.p1,
        priorityMetrics?.p2,
        priorityMetrics?.p3,
      ),
    [priorityMetrics],
  );

  return (
    <div className="grid w-full auto-rows-min grid-cols-2 gap-8 rounded bg-background-base-surface-2 p-4 md:gap-16 md:px-6 md:py-6">
      <div className="col-span-2 md:col-span-1">
        <h2 className="mb-4 text-xl font-bold text-content-base-default md:text-2xl">
          Total active tickets
        </h2>
        <h2 className="m-0 text-6xl font-bold text-content-base-default md:text-8xl">
          {isLoading ? <Skeleton className="h-[96px]" /> : openedTickets}
        </h2>
      </div>
      <div className="col-span-2 md:col-span-1">
        <TicketTypesChart isLoading={isLoading} data={ticketTypeData} />
      </div>
      <div className="col-span-2 hidden w-full max-w-[442px] md:col-span-1 md:block">
        <HorizontalBarChart
          data={priorityChartData}
          title="By priority"
          isLoading={isLoading}
        />
      </div>
      <div className="col-span-2 hidden w-full max-w-[442px] md:col-span-1 md:block">
        <DonutChart
          data={statusChartData}
          title="By status"
          isLoading={isLoading}
        />
      </div>
      <div className="relative col-span-2 flex w-full flex-col items-start justify-start gap-2 md:hidden">
        <p className="mb-4 text-base font-bold text-content-base-default">
          By priority
        </p>
        {isLoading ? (
          <Skeleton className="h-[170px] w-full" />
        ) : (
          priorityChartData?.map((item, index, arr) => (
            <Fragment key={item.category}>
              <div className="flex w-full flex-col items-center justify-between">
                <div className="flex w-full items-center justify-between">
                  <div className="flex w-full items-center justify-start gap-1.5 ">
                    <StatusIndicator backgroundColor={item.backgroundColor} />
                    <p className="text-base font-bold text-content-base-subdued">
                      {item.category}
                    </p>
                  </div>
                  <p className="font-bold text-content-base-default">
                    {item.value}
                  </p>
                </div>
              </div>
              {index < arr.length - 1 ? <Divider className="w-full" /> : null}
            </Fragment>
          ))
        )}
      </div>
      <div className="relative col-span-2 flex w-full flex-col items-start justify-start gap-2 md:hidden">
        <p className="mb-4 text-base font-bold text-content-base-default">
          By status
        </p>
        {isLoading ? (
          <Skeleton className="h-[170px] w-full" />
        ) : statusChartData?.filter(({ y }) => y > 0).length > 0 ? (
          statusChartData?.map((item, index, arr) => (
            <Fragment key={item.x}>
              <div className="flex w-full flex-col items-center justify-between">
                <div className="flex w-full items-center justify-between">
                  <div className="flex w-full items-center justify-start gap-1.5 ">
                    <StatusIndicator backgroundColor={item.backgroundColor} />
                    <p className="text-base font-bold text-content-base-subdued">
                      {item.x}
                    </p>
                  </div>
                  <p className="font-bold text-content-base-default">
                    {item.y}
                  </p>
                </div>
              </div>
              {index < arr.length - 1 ? <Divider className="w-full" /> : null}
            </Fragment>
          ))
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};
