import { TokenUserRoles } from '../../api/users/schemas/Users';
import { Fragment, ReactNode } from 'react';
import { useWithUserPermissions } from '../../hooks/useWithUserPermissions';

export interface DisplayForRoleProps {
  roles: TokenUserRoles[];
  children: ReactNode;
}

export const DisplayForRole = ({ roles, children }: DisplayForRoleProps) => {
  const { isVisible } = useWithUserPermissions(roles);

  if (isVisible) {
    return children;
  } else {
    return <Fragment />;
  }
};
