import { apiClient } from 'api/apiClient';
import axios from 'axios';
import { SignUpForm, SignupResponse } from './schemas/signup';

export const signup = async (
  request: SignUpForm,
): Promise<Partial<SignupResponse>> => {
  const payload = { ...request, type: request.type.value };
  const response = await apiClient.post('/api/v1/auth/signup', payload);
  return response.data;
};

export const changePassword = async (email: string): Promise<string> => {
  const response = await axios.post(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: { 'content-type': 'application/json' },
    },
  );

  return response.data;
};
