import { zodResolver } from '@hookform/resolvers/zod';
import FormSection from 'components/FormSection';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import {
  FullServiceDetailsFormType,
  FullServiceDetailsFormSchema,
} from './schemas';
import { Controller, useForm } from 'react-hook-form';
import Checkbox from 'components/Checkbox';
import Divider from 'components/Divider';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { states } from './utils';

interface ServiceDetailsFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onFormSubmit: (data: FullServiceDetailsFormType) => void;
  initialData: FullServiceDetailsFormType | null;
  onFormChange: (data: FullServiceDetailsFormType) => void;
}
const ServiceDetailsForm = ({
  setStep,
  onFormSubmit,
  initialData,
  onFormChange,
}: ServiceDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FullServiceDetailsFormType>({
    resolver: zodResolver(FullServiceDetailsFormSchema),
    defaultValues: {
      ...initialData,
      billing_address: true,
      country: { label: 'USA', value: 'USA' },
      billing_country: { label: 'USA', value: 'USA' },
    },
  });

  const onSubmit = (data: FullServiceDetailsFormType) => {
    onFormSubmit(data);
  };
  const onError = (error: unknown) => {
    console.log('onSubmitError called', error);
  };

  const [
    selectedState,
    selectedCountry,
    billingAddress,
    selectedBillingCountry,
    selectedBillingState,
  ] = watch([
    'state',
    'country',
    'billing_address',
    'billing_country',
    'billing_state',
  ]);
  const requiredFieldError = 'Required';

  watch((formData) => {
    onFormChange(formData as FullServiceDetailsFormType);
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      id="ticket-review-form"
      className="flex grid-cols-12 flex-col gap-6 rounded bg-background-base-surface-2 p-8 shadow"
    >
      <FormSection gridClassName="grid-cols-12">
        <GraniteInput
          label="Location name"
          placeholder="Business name"
          className="col-span-4"
          {...register('business_name')}
          error={errors.business_name?.message}
        />
        <GraniteInput
          label="Street address"
          placeholder="123 Main Street"
          className="col-span-8"
          {...register('address_line_1')}
          error={errors.address_line_1?.message}
        />
      </FormSection>
      <FormSection gridClassName="grid-cols-12">
        <GraniteInput
          label="Apt/suite/etc. (optional)"
          placeholder="Suite 1"
          className="col-span-6"
          {...register('address_line_2')}
          error={errors.address_line_2?.message}
        />
        <GraniteInput
          label="City"
          placeholder="City"
          className="col-span-6"
          {...register('city')}
          error={errors.city?.message}
        />
      </FormSection>
      <FormSection gridClassName="grid-cols-12">
        <GraniteSelect
          {...register('state')}
          options={states.map((state) => ({
            label: state.name,
            value: state.abbreviation,
          }))}
          label="State"
          onChange={(selectedOption: OptionType | null) => {
            if (selectedOption) {
              setValue('state', selectedOption);
            }
          }}
          value={selectedState}
          error={errors.state && requiredFieldError}
          className="col-span-3"
        />
        <GraniteInput
          label="Zip code"
          placeholder="Zip code"
          className="col-span-3"
          innerInputClassName="!w-full !box-border"
          {...register('zip_code')}
          error={errors.zip_code?.message}
        />
        <GraniteSelect
          {...register('country')}
          options={[{ label: 'USA', value: 'USA' }]}
          onChange={() => {}}
          label="Country"
          value={selectedCountry}
          error={errors.country && requiredFieldError}
          className="col-span-6"
          isDisabled={true}
        />
      </FormSection>
      <Divider />
      <div className="flex w-full flex-col gap-2">
        <label className="font-bold text-content-base-subdued">
          Billing address
        </label>
        <Controller
          name="billing_address"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <Checkbox
              {...field}
              label="Same as above"
              className="col-span-full"
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      {!billingAddress && (
        <>
          <FormSection gridClassName="grid-cols-12">
            <GraniteInput
              label="Location name"
              placeholder="Business name"
              className="col-span-4"
              {...register('billing_business_name')}
              error={errors.billing_business_name?.message}
            />
            <GraniteInput
              label="Contact name"
              placeholder="John Doe"
              className="col-span-4"
              {...register('billing_contact_name')}
              error={errors.billing_contact_name?.message}
            />{' '}
            <PhoneNumberInput
              label="Contact phone number"
              name="billing_contact_phone_number"
              className="col-span-4"
              control={control}
              error={errors.billing_contact_phone_number?.message}
              format="+1 (###) ###-####"
              placeholder="+1 (---) --- ----"
            />
          </FormSection>
          <GraniteInput
            label="Street address"
            placeholder="123 Main Street"
            className="col-span-full"
            {...register('billing_address_line_1')}
            error={errors.billing_address_line_1?.message}
          />
          <FormSection gridClassName="grid-cols-12">
            <GraniteInput
              label="Apt/suite/etc. (optional)"
              placeholder="Suite 1"
              className="col-span-4"
              {...register('billing_address_line_2')}
              error={errors.billing_address_line_2?.message}
            />
            <GraniteInput
              label="City"
              placeholder="City"
              className="col-span-8"
              {...register('billing_city')}
              error={errors.billing_city?.message}
            />
          </FormSection>
          <FormSection gridClassName="grid-cols-12">
            <GraniteSelect
              {...register('billing_state')}
              options={states.map((state) => ({
                label: state.name,
                value: state.abbreviation,
              }))}
              label="State"
              onChange={(selectedOption: OptionType | null) => {
                if (selectedOption) {
                  setValue('billing_state', selectedOption);
                }
              }}
              value={selectedBillingState}
              error={errors.billing_state && requiredFieldError}
              className="col-span-3"
            />
            <GraniteInput
              label="Zip code"
              placeholder="Zip code"
              className="col-span-3"
              innerInputClassName="!w-full !box-border"
              {...register('billing_zip_code')}
              error={errors.billing_zip_code?.message}
            />
            <GraniteSelect
              {...register('billing_country')}
              options={[{ label: 'USA', value: 'USA' }]}
              label="Country"
              onChange={() => {}}
              value={selectedBillingCountry}
              error={errors.billing_country && requiredFieldError}
              className="col-span-6 !box-border !w-full"
              isDisabled={true}
            />
          </FormSection>
        </>
      )}
      <div className="mt-6 flex gap-5">
        <GraniteButton
          size="large"
          variant="secondary"
          onClick={() => setStep(1)}
        >
          Back
        </GraniteButton>
        <GraniteButton size="large" type="submit" disabled={isSubmitting}>
          Create account
        </GraniteButton>
      </div>
    </form>
  );
};

export default ServiceDetailsForm;
