import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { ReCAPTCHAInput } from 'components/ReCAPTCHA';
import {
  CUSTOMER_DESCRIPTION_TYPES,
  SignUpForm,
  SignUpSchema,
  SignupResponse,
} from 'api/auth/schemas/signup';
import { PasswordInput } from 'components/V2/Input/PasswordInput';
import { GraniteSelect } from 'components/Select/Select';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { signup } from 'api/auth/auth';
import showToast from 'components/Toast/Toast';
import Loader from 'components/Loader';

export const SignUp = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: zodResolver(SignUpSchema),
  });

  const { mutate, isLoading } = useMutation(
    ({ payload }: { payload: SignUpForm }) => signup(payload),
    {
      onSuccess: (data: Partial<SignupResponse>) => {
        if (data.status === 'success') {
          navigate('/not-verified', {
            state: {
              message:
                data.message === 'New customer'
                  ? 'Your sign up request has been approved! A Granite representative will be in touch shortly to verify your identity and finalize your account.'
                  : 'Your sign up request has been approved! Please click the link in the verification email to confirm your identity. ',
            },
          });
        } else if (data.status === 'fall_out') {
          navigate('/signup-received');
        }
      },
      onError: ({ response }) => {
        showToast.error({
          message: response.data.message ?? 'Something went wrong',
        });
      },
    },
  );

  const onSubmit = (payload: SignUpForm) => {
    mutate({ payload });
  };

  const onError = (err: unknown) => {
    console.log({ err });
  };

  return (
    <div className="flex h-screen flex-wrap items-center justify-center md:py-6">
      <div className="flex min-h-full w-full flex-col justify-center gap-8 bg-background-base-surface-2 px-6 py-12 md:min-h-0 md:w-[670px] md:px-12">
        <div className="flex flex-col items-center gap-6">
          <Logo className="!h-auto !w-[126px]" />
          <h1 className="font-semibold text-content-base-default">
            Sign up for Granite360
          </h1>
        </div>
        <form
          autoComplete="off"
          className="grid grid-cols-2 gap-6"
          onSubmit={handleSubmit(onSubmit, onError)}
          id="signup-form"
        >
          <Input className="col-span-2 sm:col-span-1" label="First name">
            <GraniteInput
              variant="outlined"
              className="w-full"
              innerInputClassName="w-full"
              placeholder="John"
              {...register('first_name')}
              error={errors.first_name?.message}
            />
          </Input>
          <Input className="col-span-2 sm:col-span-1" label="Last name">
            <GraniteInput
              variant="outlined"
              className="w-full"
              placeholder="Doe"
              innerInputClassName="w-full"
              {...register('last_name')}
              error={errors.last_name?.message}
            />
          </Input>
          <Input className="col-span-2 sm:col-span-1" label="Email">
            <GraniteInput
              variant="outlined"
              className="w-full"
              innerInputClassName="w-full"
              placeholder="jdoe@email.com"
              {...register('email')}
              error={errors.email?.message}
            />
          </Input>
          <Input className="col-span-2 sm:col-span-1" label="Phone number">
            <PhoneNumberInput
              variant="outlined"
              name="phone_number"
              innerInputClassName="w-full"
              label=""
              control={control}
              error={errors.phone_number?.message}
              className="w-full !bg-background-base-surface-2"
            />
          </Input>
          <Controller
            name="password"
            control={control}
            render={({ field: { ref: _ref, ...field } }) => (
              <Input
                className="col-span-2 sm:col-span-1"
                label="Create password"
              >
                <PasswordInput
                  {...field}
                  className="w-full"
                  innerInputClassName="w-full"
                  variant="outlined"
                  autoComplete="new-password"
                  placeholder="Create password"
                  error={errors.password?.message}
                />
              </Input>
            )}
          />

          <Controller
            name="confirmPassword"
            control={control}
            render={({ field: { ref: _ref, ...field } }) => (
              <Input
                className="col-span-2 sm:col-span-1"
                label="Confirm password"
              >
                <PasswordInput
                  {...field}
                  className="w-full"
                  variant="outlined"
                  innerInputClassName="w-full"
                  showCriteria={false}
                  placeholder="Confirm password"
                  error={errors.confirmPassword?.message}
                />
              </Input>
            )}
          />
          <Input className="col-span-2 sm:col-span-1" label="Company name">
            <GraniteInput
              variant="outlined"
              innerInputClassName="w-full"
              className="w-full"
              placeholder="Company name"
              {...register('org_name')}
              error={errors.org_name?.message}
            />
          </Input>
          <Input
            className="col-span-2 sm:col-span-1"
            label="Which of these best describes you?"
          >
            <Controller
              name="type"
              control={control}
              render={({ field: { ref: _ref, ...field } }) => (
                <GraniteSelect
                  {...field}
                  options={CUSTOMER_DESCRIPTION_TYPES.options.map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  className="w-full !bg-background-base-surface-2"
                  placeholder="Select"
                  classNames={{
                    control: () => '!bg-background-base-surface-2',
                  }}
                  error={errors.type?.message}
                />
              )}
            />
          </Input>
          <div className="col-span-2">
            <ReCAPTCHAInput control={control} name="recaptcha" />
          </div>
        </form>
        <div className="flex flex-col items-center gap-4">
          <GraniteButton
            size="large"
            variant="primary"
            type="submit"
            form="signup-form"
            className="w-full !p-0"
            disabled={isLoading}
          >
            Sign up
            {isLoading && (
              <Loader animationClassname="!w-5 !h-5 !border-background_base_surface-3_subdued !border-t-button-background-primary-default" />
            )}
          </GraniteButton>
          <div className="flex items-center gap-2">
            <span className="text-content-base-default">
              Already have an account?
            </span>
            <Link
              className="cursor-pointer text-content-accent-default"
              to="/login"
            >
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Input = ({
  className = '',
  label,
  children,
}: {
  className?: string;
  label: string;
  children: JSX.Element;
}) => {
  return (
    <div className={clsx('group relative flex h-full rounded', className)}>
      <label className="absolute left-4 top-0 z-10 -translate-y-1/2 bg-background-base-surface-2 !py-[2px] px-[6px] text-xs text-stroke-base-heavy transition-all group-focus-within:text-input-stroke-focus group-hover:text-input-stroke-focus">
        {label}
      </label>
      {children}
    </div>
  );
};
