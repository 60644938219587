import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from 'shared/constants/error-labels';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  NUMBERS_ONLY_REGEXP,
  VALID_PHONE_NUMBER,
} from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const TicketDetailsBaseFormSchema = z.object({
  whatNeedsToBeDone: z.object({
    label: z.string(),
    value: z.string(),
  }),
  initialDescription: z.string().trim().nonempty('Required'),
  customer_name: z.string().optional(),
  prior_ticket: z
    .string()
    .nonempty({ message: 'Required' })
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    })
    .or(z.literal(''))
    .optional(),
  local_name: z
    .string()
    .trim()
    .nonempty('Required')
    .max(50, 'Only 50 characters allowed')
    .regex(LETTERS_SPACES_HYPENS_REGEXP, {
      message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
    }),
  local_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  attachments: z.array(z.any()).optional(),
  isSendNotificationsViaEmailEnabled: z.boolean().optional(),
  recipients: z.array(z.object({ email: z.string().email().nonempty() })),
});

const TicketDetailsWithMaintenanceRequired = TicketDetailsBaseFormSchema.extend(
  {
    canTestingBeDoneAnytime: z.literal(false),
    maintenance_window: z.string().nonempty('Required'),
  },
);

const TicketDetailsWithMaintenanceOptional = TicketDetailsBaseFormSchema.extend(
  {
    canTestingBeDoneAnytime: z.literal(true),
    maintenance_window: z.string().optional(),
  },
);

const TicketDetailsWithMaintenanceUndefined =
  TicketDetailsBaseFormSchema.extend({
    canTestingBeDoneAnytime: z.undefined(),
    maintenance_window: z.string().nonempty('Required'),
  });

export const TicketDetailsFormSchema = z.discriminatedUnion(
  'canTestingBeDoneAnytime',
  [
    TicketDetailsWithMaintenanceRequired,
    TicketDetailsWithMaintenanceOptional,
    TicketDetailsWithMaintenanceUndefined,
  ],
);

export type TicketDetails = z.infer<typeof TicketDetailsFormSchema>;
