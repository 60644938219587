import { CreateTicketRequest } from 'api/nocexpress/schema';
import { TicketDetails as TicketDetailsForm } from './schemas';
import { FindLocationOrServiceForm } from '../FindLocationOrService/schemas';
import { BulkLocations } from '../FindLocationOrService/BulkAddLocations/schemas';
import { UseAuth0UserReturn } from 'hooks/useAuth0User';
import isSiteAddress from '../utils/isSiteAddress';

export const ticketDetailsFormToRequest = (
  formData: TicketDetailsForm,
  findLocationOrServiceForm: FindLocationOrServiceForm,
  user: UseAuth0UserReturn,
  ticketType: string,
  ticketSubtype: string,
  bulkLocations?: BulkLocations['locations'],
) => {
  const site_ids =
    findLocationOrServiceForm.type === 'multi-site' &&
    bulkLocations &&
    bulkLocations.length > 0
      ? bulkLocations[0].id.toString()
      : isSiteAddress(findLocationOrServiceForm.selectedIdentifier)
        ? findLocationOrServiceForm.selectedIdentifier?.id.toString()
        : findLocationOrServiceForm.selectedIdentifier?.site_id.toString();
  // <customer> | <macnum> | <first 50 characters of “Initial description of the issue” input>
  const summary = `${
    user.email
  } | ${site_ids} | ${formData.initialDescription.slice(0, 50)}`;
  const sites =
    findLocationOrServiceForm.type === 'multi-site'
      ? bulkLocations?.map((item) => ({
          id: item.id,
          maintenance_window: item.canTestingBeDoneAnytime
            ? 'Mon-Fri, 8:00AM-5:00PM'
            : item.maintenance_window,
        }))
      : [
          {
            id:
              findLocationOrServiceForm.type === 'config'
                ? findLocationOrServiceForm.selectedIdentifier.site_id
                : findLocationOrServiceForm.selectedIdentifier?.id,
            maintenance_window: formData.canTestingBeDoneAnytime
              ? 'Mon-Fri, 8:00AM-5:00PM'
              : formData.maintenance_window,
          },
        ];
  const requestData: CreateTicketRequest = {
    summary: summary,
    issue_name: formData.whatNeedsToBeDone.value,
    initial_description: formData.initialDescription,
    type_name: ticketType,
    subtype_name: ticketSubtype,
    automatic_email_contact: !!formData.isSendNotificationsViaEmailEnabled,
    automatic_email_cc: formData.recipients.length > 0,
    automatic_email_cc_address: formData.recipients
      .map((r) => r.email)
      .join(';'),
    // comma delimited string
    configuration_ids: findLocationOrServiceForm.service_identifier
      ?.map((item) => Number(item.value))
      .join(','),
    customer_ticket_number: formData.customer_name,
    prior_ticket: formData.prior_ticket
      ? Number(formData.prior_ticket)
      : undefined,
    contact_name: user.name!,
    contact_email_address: user.email!,
    local_contact_name: formData.local_name,
    local_contact_number: Number(formData.local_number),
    sites: sites!,
  };
  const openTicketFormData = new FormData();
  Object.entries(requestData).forEach(([key, value]) => {
    if (value === undefined || value === '') return;
    if (typeof value === 'object' && value !== null) {
      openTicketFormData.append(key, JSON.stringify(value));
    } else {
      openTicketFormData.append(key, `${value}`);
    }
  });
  formData.attachments?.forEach((file) => {
    openTicketFormData.append('file', file);
  });
  return openTicketFormData;
};
