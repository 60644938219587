import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

const FEATURE_FLAGS_KEYS = [
  'NOTIFICATIONS_ENABLED',
  'NOTIFICATIONS_EMAIL_ENABLED',
  'NOTIFICATIONS_SMS_ENABLED',
  'CLOSE_NOC_TICKET_ENABLED',
  'TECHEXPRESS_TEMPLATES_ENABLED',
  'NOCEXPRESS_REOPEN_TICKET',
  'RESOURCES_ENABLED',
  'CREATE_CHILD_ACCOUNT',
  'PROVIDE_FEEDBACK_ENABLED',
] as const;

export type FEATURE_FLAG = (typeof FEATURE_FLAGS_KEYS)[number];

export type FEATURE_FLAGS = {
  [key in FEATURE_FLAG]: boolean;
};
type FEATURE_FLAG_CONTEXT = {
  flags: FEATURE_FLAGS;
};

const FeatureFlagContext = createContext<FEATURE_FLAG_CONTEXT>(
  {} as FEATURE_FLAG_CONTEXT,
);

export const FeatureFlagProvider: FC<PropsWithChildren> = ({ children }) => {
  const [flags] = useState<FEATURE_FLAGS>({
    NOTIFICATIONS_ENABLED: true,
    NOTIFICATIONS_EMAIL_ENABLED: false,
    NOTIFICATIONS_SMS_ENABLED: false,
    CLOSE_NOC_TICKET_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    TECHEXPRESS_TEMPLATES_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    NOCEXPRESS_REOPEN_TICKET: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    RESOURCES_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    CREATE_CHILD_ACCOUNT: process.env.REACT_APP_CURRENT_ENV !== 'prod',
    PROVIDE_FEEDBACK_ENABLED: process.env.REACT_APP_CURRENT_ENV !== 'prod',
  });

  return (
    <FeatureFlagContext.Provider value={{ flags }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagContext);
