import { changePassword } from 'api/auth/auth';
import showToast from 'components/Toast/Toast';
import { useMutation } from 'react-query';

export const useChangePasswordMutation = () => {
  return useMutation((email: string) => changePassword(email), {
    onSuccess: (data: string) => {
      showToast.confirmation({
        message: data,
      });
    },
    onError: (error: { message: string }) => {
      console.error(error, 'error');
      showToast.error({
        message: error.message || 'Something went wrong!',
      });
    },
  });
};
