import React, { useCallback, useEffect, useMemo, useState } from 'react';
import sortBy from 'lodash/sortBy';

import Checkbox from 'components/Checkbox';
import Divider from 'components/Divider';
import RadioButton from 'components/RadioButton';
import { GraniteSelect } from 'components/Select/Select';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';

import { ProductOfferingType, ProductType } from './schemas';
import {
  TermType,
  buildNestedObjectWithIds,
  groupByMultipleKeys,
} from './utils';
import { ServiceSummary } from './ServiceSummary';

const keys = [
  'provider',
  'term',
  'bandwidth_download',
  'bandwidth_upload',
  'ip_type',
  'ip_block',
];

const SEPARATOR = '|||';

interface ServiceDetailsProps {
  data: ProductOfferingType[];
  onChange: (product: ProductType, mns: boolean) => void;
  selected: boolean;
}

interface SelectOptions {
  [key: string]: Array<{ label: string; value: string }> | undefined;
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({
  data,
  onChange,
  selected,
}) => {
  const normData = data
    .map((d) => ({
      ...d,
      ip_block: d.ip_block ?? 'N/A',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ip_type: d?.ip_type ?? 'STATIC',
    }))
    .map((d) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (d.bandwidth) {
        return {
          ...d,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_download: d.bandwidth,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          bandwidth_upload: d.bandwidth,
        };
      }
      return d;
    });
  const result = groupByMultipleKeys(sortBy(normData, keys), keys);

  const keysOptionsList = Object.entries(result).map(([key, values]) => {
    return { key, id: values[0].id };
  });

  const nestedList = buildNestedObjectWithIds(keysOptionsList);

  const [selectedValues, setSelectedValues] = useState<Record<string, string>>(
    {},
  );
  const [options, setOptions] = useState<SelectOptions>({
    provider: [],
    term: [],
    bandwidth_download: [],
    bandwidth_upload: [],
    ip_block: [],
  });

  useEffect(() => {
    preselectOptions('provider', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const preselectOptions = (
    key: string,
    newSelectedValues: Record<string, string>,
  ) => {
    const start = keys.indexOf(key);
    const newList: Record<string, string> = {};

    const selectedOptions: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let next: any = nestedList;

    for (let i = 0; i < keys.indexOf(key); i++) {
      selectedOptions.push(newSelectedValues[keys[i]]);
      next = next[newSelectedValues[keys[i]]];
    }

    const newOptions: Record<
      string,
      Array<{ label: string; value: string }>
    > = {};

    for (let i = start; i < keys.length; i++) {
      newOptions[keys[i]] = Object.keys(next).map((opt) => ({
        value: opt,
        label: opt ?? 'N/A',
      }));

      newList[keys[i]] = Object.keys(next)[0];

      next = next[newList[keys[i]]];
    }

    setOptions((prevOptions) => ({
      ...prevOptions,
      ...newOptions,
    }));

    setSelectedValues((prevOptions) => ({
      ...prevOptions,
      ...newList,
    }));
  };

  const handleSelectChange = (
    key: string,
    value: string,
    // autoSelectNext = false,
  ) => {
    const newSelectedValues = {
      ...selectedValues,
      [key]: value,
    };
    setSelectedValues(newSelectedValues);

    const nextIndex = keys.indexOf(key) + 1;

    if (nextIndex < keys.length) {
      preselectOptions(keys[nextIndex], newSelectedValues);
    }
  };

  const [mns, setMns] = useState(true);
  const handleSelectMNSChange = useCallback(() => {
    setMns((prev) => !prev);
  }, []);

  const summaryData = useMemo(() => {
    const key = keys.map((key) => selectedValues[key] || 'N/A').join(SEPARATOR);
    const product = result[key]?.[0] as unknown as ProductType;
    const term = result[key]?.[0].term as TermType;
    onChange(product, mns);
    return {
      ...product,
      term,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, mns]);

  const accessTypeOptions = [
    { value: 'fe', label: 'FE' },
    {
      value: summaryData?.type === 'broadband' ? 'cable' : 'ge',
      label: summaryData?.type === 'broadband' ? 'Cable' : 'GE',
    },
  ];

  return (
    <>
      <div className="flex-1 flex-col bg-background-base-surface-2 p-4">
        <div className="flex flex-col gap-6">
          <GraniteLabel label="Connection type" element="div">
            <GraniteSelect
              className="w-full"
              value={accessTypeOptions[1]}
              options={accessTypeOptions}
              isDisabled
            />
          </GraniteLabel>
          <GraniteLabel label="Carrier name" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('provider', selectedOption?.value || '')
              }
              value={
                selectedValues.provider
                  ? {
                      value: selectedValues.provider,
                      label: selectedValues.provider,
                    }
                  : null
              }
              name="provider"
              options={options.provider}
            />
          </GraniteLabel>
          <GraniteLabel label="Term length" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('term', selectedOption?.value || '')
              }
              value={
                selectedValues.term
                  ? { value: selectedValues.term, label: selectedValues.term }
                  : null
              }
              name="term"
              options={options.term}
              isDisabled={!selectedValues.provider}
            />
          </GraniteLabel>
          <GraniteLabel label="Download" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_download',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_download
                  ? {
                      value: selectedValues.bandwidth_download,
                      label: selectedValues.bandwidth_download,
                    }
                  : null
              }
              name="bandwidth_download"
              options={options.bandwidth_download}
              isDisabled={!selectedValues.term}
            />
          </GraniteLabel>

          <GraniteLabel label="Upload" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange(
                  'bandwidth_upload',
                  selectedOption?.value || '',
                )
              }
              value={
                selectedValues.bandwidth_upload
                  ? {
                      value: selectedValues.bandwidth_upload,
                      label: selectedValues.bandwidth_upload,
                    }
                  : null
              }
              name="bandwidth_upload"
              options={options.bandwidth_upload}
            />
          </GraniteLabel>
          <GraniteLabel label="IP type" element="div">
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'DYNAMIC')}
              options={[{ value: 'DYNAMIC', label: 'Dynamic IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'DYNAMIC')}
              className="mb-2"
            />
            <RadioButton
              disabled={!options.ip_type?.some((i) => i.value === 'STATIC')}
              options={[{ value: 'STATIC', label: 'Static IP' }]}
              selectedValue={selectedValues.ip_type}
              onChange={() => handleSelectChange('ip_type', 'STATIC')}
            />
          </GraniteLabel>
          {/* <GraniteLabel label="IP type" element="div">
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('ip_type', selectedOption!.value)
              }
              value={
                selectedValues.ip_type
                  ? {
                      value: selectedValues.ip_type,
                      label: selectedValues.ip_type,
                    }
                  : null
              }
              name="ip_type"
              options={options.ip_type?.map((i) => ({
                ...i,
                label: i.label,
              }))}
              // isDisabled={!selectedValues.bandwidth_upload}
            />
          </GraniteLabel> */}
          <GraniteLabel
            label="IP block"
            element="div"
            className={selectedValues.ip_type === 'DYNAMIC' ? 'hidden' : ''}
          >
            <GraniteSelect
              className="w-full"
              onChange={(selectedOption) =>
                handleSelectChange('ip_block', selectedOption!.value)
              }
              value={
                selectedValues.ip_block
                  ? {
                      value: selectedValues.ip_block,
                      label: selectedValues.ip_block,
                    }
                  : null
              }
              name="ip_block"
              options={options.ip_block?.map((i) => ({
                ...i,
                label: i.label,
              }))}
            />
          </GraniteLabel>
          <div className="hidden">
            <Divider />
            <GraniteLabel
              label="Add Granite Managed Services bundle"
              element="div"
            >
              <Checkbox
                label={
                  summaryData?.type === 'broadband'
                    ? 'MNS Essentials'
                    : 'MNS Enhanced'
                }
                checked={mns}
                onChange={handleSelectMNSChange}
              />
            </GraniteLabel>
          </div>
        </div>
      </div>
      <div className="flex-1 rounded border border-input-stroke-unfilled p-4">
        <ServiceSummary data={summaryData} selected={selected} mns={mns} />
      </div>
    </>
  );
};
