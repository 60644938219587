import { useEffect, useRef, useState } from 'react';
import { BasePagination, PaginableRequest } from '../api/common-schemas';
import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { QueryKey, useQuery } from 'react-query';

export interface UsePaginatedTableReturn<T> {
  paginationState: PaginationState;
  onPaginationChange: OnChangeFn<PaginationState>;
  itemCount: number;
  pageCount: number;
  isFetchingData?: boolean;
  isFetchingError?: boolean;
  data: T[];
}

export const usePaginatedTable = <ResultData>(
  getDataFn: (params: PaginableRequest) => Promise<BasePagination<ResultData>>,
  filters: Record<string | number | symbol, unknown>,
  queryKey: QueryKey,
  options = {},
  paginationOptions = {
    pageIndex: 0,
    pageSize: 20,
  },
): UsePaginatedTableReturn<ResultData> => {
  const { pageIndex, pageSize } = paginationOptions;
  const [paginationState, setPagination] = useState<PaginationState>({
    pageIndex,
    pageSize,
  });

  // reset page to 0 on change of filters
  const lastFiltersRef = useRef<Record<string, unknown>>(filters);
  useEffect(() => {
    for (const key in filters) {
      if (
        Object.prototype.hasOwnProperty.call(filters, key) &&
        filters[key] !== lastFiltersRef.current[key]
      ) {
        setPagination({
          pageIndex,
          pageSize,
        });
        lastFiltersRef.current = filters;
        break;
      }
    }
  }, [filters, pageIndex, pageSize]);

  const {
    data,
    isFetching: isFetchingData,
    isError: isFetchingError,
  } = useQuery<BasePagination<ResultData>>(
    [
      ...(typeof queryKey === 'string' ? [queryKey] : queryKey),
      paginationState,
    ],

    () =>
      getDataFn({
        page: paginationState.pageIndex + 1,
        page_size: paginationState.pageSize,
      }),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  return {
    paginationState,
    onPaginationChange: setPagination,
    data: data?.data ?? [],
    pageCount: data?.metadata.total_pages ?? 0,
    itemCount: data?.metadata.total_items ?? 0,
    isFetchingData,
    isFetchingError,
  };
};
