import { zodResolver } from '@hookform/resolvers/zod';
import FormSection from 'components/FormSection';
import { GraniteSelect, OptionType } from 'components/Select/Select';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import {
  AccountInformationForm as AccountInformationFormType,
  AccountInformationFormSchema,
  InvoiceStyleEnum,
} from './schemas';
import { useForm } from 'react-hook-form';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import { PropsValue } from 'react-select';
import { FieldTooltip } from 'components/FieldTooltip/FieldTooltip';
import { useQuery } from 'react-query';
import { getParentAccounts } from 'api/techexpress/api';
import { useState } from 'react';
import clsx from 'clsx';
import { useFeatureFlags } from 'feature-flags';

const billinTypeOptions = [
  {
    label: 'Bill to Parent account',
    value: '4',
  },
  {
    label: 'Bill to this account',
    value: '5',
  },
];

const invoiceDeliveryOptions = [
  {
    label: 'Paper only',
    value: '1',
  },
  {
    label: 'Paper & email',
    value: '2',
  },
  {
    label: 'Email only',
    value: '3',
  },
];

interface AccountInformationFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onFormSubmit: React.Dispatch<
    React.SetStateAction<AccountInformationFormType | null>
  >;
  onClose: () => void;
  initialData: AccountInformationFormType | null;
}

const AccountInformationForm = ({
  setStep,
  onFormSubmit,
  onClose,
  initialData,
}: AccountInformationFormProps) => {
  const [parentAccountOptions, setParentAccountOptions] = useState<
    { label: string; value: string; subvalue?: string }[]
  >([]);
  const { flags } = useFeatureFlags();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AccountInformationFormType>({
    resolver: zodResolver(AccountInformationFormSchema),
    defaultValues: initialData ?? {},
  });

  useQuery(['parent-accounts'], () => getParentAccounts(), {
    enabled: flags.CREATE_CHILD_ACCOUNT,
    onSuccess: (data) => {
      setParentAccountOptions(
        data.map((item) => {
          const parts = item.name
            ? item.name.split('-').map((part) => part.trim())
            : ['', ''];
          return {
            label: parts[0],
            value: parts[0],
            subvalue: parts[1],
          };
        }),
      );
    },
  });

  const onSubmit = (data: AccountInformationFormType) => {
    onFormSubmit(data);
    setStep(2);
  };

  const onError = (error: unknown) => {
    console.log('onSubmitError called', error);
  };

  const [
    selectedParentAccount,
    selectedBillingType,
    selectedInvoiceMethod,
    selectedInvoiceStyle,
  ] = watch([
    'parent_account',
    'billing_type',
    'invoice_delivery_method',
    'invoice_style',
  ]);

  const requiredFieldError = 'Required';

  const formatSelectedOption = (option: {
    label: string;
    subvalue?: string;
  }) => {
    return option.subvalue
      ? `${option.label} - ${option.subvalue}`
      : option.label;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onError)}
      id="ticket-review-form"
      className="flex grid-cols-2 flex-col gap-6 rounded bg-background-base-surface-2 p-8 shadow"
    >
      <FormSection gridClassName="grid-cols-2">
        <GraniteSelect
          {...register('parent_account')}
          options={parentAccountOptions}
          label="Parent account"
          onChange={(selectedOption: OptionType | null) => {
            if (selectedOption) {
              //@ts-expect-error error
              setValue('parent_account', selectedOption);
            }
          }}
          formatOptionLabel={(option, { context }) =>
            context === 'value' ? formatSelectedOption(option) : option.label
          }
          value={selectedParentAccount}
          error={errors.parent_account && requiredFieldError}
          className="col-span-1"
        />
        <GraniteSelect
          {...register('billing_type')}
          options={billinTypeOptions}
          label="Billing type"
          onChange={(selectedOption: OptionType | null) => {
            if (selectedOption) {
              setValue('billing_type', selectedOption);
            }
          }}
          value={selectedBillingType}
          error={errors.billing_type && requiredFieldError}
          className="col-span-1"
          rightContent={
            <FieldTooltip title="Billing type">
              <ul className="ml-[20px] list-outside list-disc text-left text-base text-content-base-subdued">
                <li className="pl-0">
                  <span className="font-bold">Billing to Parent account</span>{' '}
                  is equivalent to Child-Par pays. This means that all charges
                  associated with this account will be the responsibility of the
                  Parent.{' '}
                </li>
                <li className="pl-0">
                  <span className="font-bold">Billing to this account</span> is
                  equivalent to Child-Child pays. This means that the charges
                  associated with this account will be the responsibility of the
                  individual location.
                </li>
              </ul>
            </FieldTooltip>
          }
        />
      </FormSection>
      <FormSection gridClassName="grid-cols-2">
        <GraniteSelect
          {...register('invoice_delivery_method')}
          options={invoiceDeliveryOptions}
          label="Invoice delivery method"
          onChange={(selectedOption: OptionType | null) => {
            if (selectedOption) {
              setValue('invoice_delivery_method', selectedOption);
            }
          }}
          value={selectedInvoiceMethod as unknown as PropsValue<OptionType>}
          error={errors.invoice_delivery_method && requiredFieldError}
          className="col-span-1"
        />
        <GraniteSelect
          {...register('invoice_style')}
          options={InvoiceStyleEnum.options.map((item) => ({
            label: item,
            value: item,
          }))}
          label="Invoice style"
          onChange={(selectedOption: OptionType | null) => {
            if (selectedOption) {
              //@ts-expect-error error
              setValue('invoice_style', selectedOption);
            }
          }}
          value={selectedInvoiceStyle}
          error={errors.invoice_style && requiredFieldError}
          className="col-span-1"
          rightContent={
            <FieldTooltip title="Invoice style">
              <ul className="ml-[20px] list-outside list-disc text-left text-base text-content-base-subdued">
                <li className="pl-0">
                  <span className="font-bold">Summary bill: </span> displays a
                  detailed breakdown of chares for each location.
                </li>
                <li className="pl-0">
                  <span className="font-bold">IW only: </span> is for IW only
                  accounts. Non-IW details will not be displayed but amounts
                  will be included.
                </li>
                <li className="pl-0">
                  <span className="font-bold">Send no invoice:</span> no invoice
                  will be sent via mail or email.
                </li>
              </ul>
            </FieldTooltip>
          }
        />
      </FormSection>
      <FormSection gridClassName="grid-cols-2">
        <GraniteInput
          label="Store ID (optional)"
          placeholder="E.g., 12345"
          className="col-span-1"
          {...register('store_id')}
          error={errors.store_id?.message}
        />
        <GraniteInput
          label="General ledger # (optional)"
          placeholder="General ledger number"
          className="col-span-1"
          {...register('general_ledger')}
          error={errors.general_ledger?.message}
          rightContent={
            <FieldTooltip title="General ledger">
              <p className="text-left text-base text-content-base-subdued">
                The code that is used to record and categorize financial
                transactions within your company&apos;s main accounting record.
              </p>
            </FieldTooltip>
          }
        />
      </FormSection>
      <FormSection gridClassName="grid-cols-12">
        <GraniteInput
          label="Local contact name"
          placeholder="John Doe"
          className="col-span-4"
          {...register('local_contact_name')}
          error={errors.local_contact_name?.message}
        />
        <GraniteInput
          label="Local contact email"
          placeholder="jdoe@email.com"
          className={clsx('col-span-4', errors && '!w-[101.5%]')}
          {...register('local_contact_email')}
          error={errors.local_contact_email?.message}
        />
        <PhoneNumberInput
          label="Local contact phone number"
          name="local_contact_phone_number"
          control={control}
          className="col-span-4"
          error={errors.local_contact_phone_number?.message}
          format="+1 (###) ###-####"
          placeholder="+1 (---) --- ----"
        />
        <div />
      </FormSection>
      <div className="mt-6 flex gap-5">
        <GraniteButton
          size="large"
          variant="secondary"
          onClick={() => {
            onClose();
            reset({});
          }}
        >
          Cancel
        </GraniteButton>
        <GraniteButton size="large" type="submit" disabled={isSubmitting}>
          Next
        </GraniteButton>
      </div>
    </form>
  );
};

export default AccountInformationForm;
