import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Logo from 'components/Logo';
import { useAuth0 } from '@auth0/auth0-react';

const SignupReceived = () => {
  const { logout } = useAuth0();

  return (
    <div className="m-auto h-screen w-screen bg-background-base-surface-1">
      <Modal
        isVisible={true}
        close={close}
        className="w-[624px]"
        hideCloseButton
      >
        <div className="flex flex-col items-start gap-8 rounded bg-background-base-surface-2 px-8 py-6">
          <Logo />
          <div>
            <h1 className="mb-1 rounded-t-lg text-[28px] font-bold leading-9 text-content-base-default">
              Sign up received!
            </h1>
            <p className="text-xl font-semibold text-content-base-subdued">
              Thanks for your interest in the Granite360 Platform! Your signup
              request is received. You will receive an email notification to
              login once approved by an Administrator.{' '}
            </p>
          </div>
          <div className="mt-4 flex gap-5">
            <GraniteButton
              size="large"
              variant="primary"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Return to login
            </GraniteButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignupReceived;
