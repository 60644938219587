import * as z from 'zod';

import { QuoteLocationSchema } from 'api/accessexpress/schema';

const DefaultProductSchema = z.object({
  term: z.string(),
  min_download: z.number(),
  max_download: z.number(),
  ip_type: z.string(),
  ip_block: z.string(),
});

const BroadbandProductSchema = z.object({
  product_type: z.literal('Broadband'),
  ...DefaultProductSchema.shape,
});

export type BroadbandProductType = z.infer<typeof BroadbandProductSchema>;

const DIAProductSchema = z.object({
  product_type: z.literal('DIA'),
  ...DefaultProductSchema.shape,
  include_carriers: z.string().optional(),
  exclude_carriers: z.string().optional(),
  preferred_carriers: z.string().optional(),
  diversity_needed: z.boolean(),
});

export type DIAProductType = z.infer<typeof DIAProductSchema>;

const LocationSchema = z.object({
  ...QuoteLocationSchema.shape,
  products: z
    .array(z.union([BroadbandProductSchema, DIAProductSchema]))
    .min(1)
    .max(2),
});

export type LocationType = z.infer<typeof LocationSchema>;

const PayloadSchema = z.object({
  customer_name: z.string(),
  requester: z.string(),
  business_unit: z.string(),
  email: z.string().email(),
});

export const ChooseServicesSchema = z.object({
  locations: z
    .array(QuoteLocationSchema)
    .min(1, 'At least one location is required'),
  // products: z
  //   .array(z.union([BroadbandProductSchema, DIAProductSchema]))
  //   .min(1)
  //   .max(2),
});

export type ChooseServicesSchemaType = z.infer<typeof ChooseServicesSchema>;

export const OpenQuoteAPISchema = z.object({
  ...PayloadSchema.shape,
  locations: z.array(LocationSchema),
});
