import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Add } from 'react-ionicons';
import { zodResolver } from '@hookform/resolvers/zod';

import FormSection from 'components/FormSection';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import Divider from 'components/Divider';
import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import { Locations } from './Locations/Locations';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { DIAServices } from './DIAServices';
import { BroadbandServices } from './BroadbandService';
import { Controller, useForm } from 'react-hook-form';
import { ChooseServicesSchema, ChooseServicesSchemaType } from './schemas';
import { useQuery } from 'react-query';
import { fetchAccessStatisData } from 'api/accessexpress/api';
import { useAuth0User } from 'hooks/useAuth0User';
import { createQuoteRequest } from './utils';
import { useModal } from 'hooks/useModal';
import DiscardProgressModal from '../DiscardProgressModal/DiscardProgressModal';
import CheckAvailabilityDialog from '../BaseComponents/CheckAvailabilityDialog';

type ServiceType = 'broadband' | 'dia';

type ServiceCombinationType = ServiceType[] & { length: 0 | 1 | 2 };

export const ChooseServices = () => {
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] =
    useState<ServiceCombinationType>(
      location.state?.selectedOptions || ['broadband'],
    );
  const locationValue = location.state?.addressValue;
  const { wizardStore } = useOpenQuoteContext();
  const { open, ...discardModalProps } = useModal();
  const { open: openCheckAvailabilityModal, ...checkAvailabilityModalProps } =
    useModal();

  const { data: staticData } = useQuery(
    ['static-data'],
    () => fetchAccessStatisData(),
    {
      retry: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    wizardStore.setStep(2);
  }, [wizardStore]);

  const userInfo = useAuth0User();

  const { control, handleSubmit, formState } =
    useForm<ChooseServicesSchemaType>({
      resolver: zodResolver(ChooseServicesSchema),
      mode: 'all', // Enable validation on input changes
      defaultValues: {
        locations: locationValue ? [locationValue] : [],
      },
    });
  const products = useRef<Record<ServiceType, unknown>>({
    broadband: null,
    dia: null,
  });

  const onSubmit = async ({
    data,
    quoteName,
  }: {
    data: ChooseServicesSchemaType;
    quoteName: string;
  }) => {
    await createQuoteRequest(
      userInfo,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      data.locations.map((d) => {
        return {
          ...d,
          address_line_1: d.street || d.address_line_1,
          products: Object.values(products.current).filter(Boolean),
        };
      }),
      quoteName,
    );
  };

  const handleAddService = useCallback(
    (service: ServiceType) => {
      setSelectedOptions([
        ...selectedOptions,
        service,
      ] as ServiceCombinationType);
    },
    [selectedOptions],
  );

  const handleCheckAvailabilityClick = () => {
    openCheckAvailabilityModal();
  };

  return (
    <form>
      <OpenQuoteLayout>
        <div className="flex min-h-[662px] flex-col gap-16 rounded bg-background-base-surface-2 p-6 shadow">
          <FormSection title="Your locations">
            <Controller
              control={control}
              name="locations"
              render={({ field }) => (
                <Locations locations={field.value} onChange={field.onChange} />
              )}
            />
          </FormSection>
          <div className="-my-8">
            <Divider />
          </div>

          {selectedOptions.map((service, index, services) => (
            <React.Fragment key={service}>
              {service === 'broadband' && (
                <>
                  {staticData && (
                    <BroadbandServices
                      staticData={staticData}
                      onChange={(data) => {
                        products.current.broadband = data;
                      }}
                    />
                  )}
                  {!selectedOptions.includes('dia') && (
                    <GraniteButton
                      variant="secondary"
                      className="!box-border !max-w-[200px]"
                      onClick={() => handleAddService('dia')}
                    >
                      <span className="whitespace-nowrap">
                        Add DIA services
                      </span>
                      <Add width="20px" height="20px" color="inherit" />
                    </GraniteButton>
                  )}
                </>
              )}
              {service === 'dia' && (
                <>
                  {staticData && (
                    <DIAServices
                      staticData={staticData}
                      onChange={(data) => {
                        products.current.dia = data;
                      }}
                    />
                  )}
                  {!selectedOptions.includes('broadband') && (
                    <GraniteButton
                      variant="secondary"
                      className="!box-border !max-w-[240px]"
                      onClick={() => handleAddService('broadband')}
                    >
                      <span className="whitespace-nowrap">
                        Add Broadband services
                      </span>
                      <Add width="20px" height="20px" color="inherit" />
                    </GraniteButton>
                  )}
                </>
              )}
              {index < services.length - 1 && (
                <div className="-my-8">
                  <Divider />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex gap-4">
            <GraniteButton
              size="large"
              variant="secondary"
              className="w-full"
              onClick={open}
            >
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              className={clsx(
                `button primary large w-full`,
                // 0 === 0 && 'disabled-link',
              )}
              type="button"
              disabled={!formState.isValid}
              onClick={handleCheckAvailabilityClick}
            >
              Check availability
            </GraniteButton>
          </div>
        </div>
        <DiscardProgressModal {...discardModalProps} />
        <CheckAvailabilityDialog
          {...checkAvailabilityModalProps}
          onConfirmation={async ({ quoteName }) => {
            return await handleSubmit((data) =>
              onSubmit({ data, quoteName }),
            )();
          }}
        />
      </OpenQuoteLayout>
    </form>
  );
};
