import { useAuth0 } from '@auth0/auth0-react';
import {
  G360Pillars,
  TokenUserRoles,
  TokenUserRolesSet,
  canViewAccessExpress,
} from '../api/users/schemas/Users';
import { useCallback, useMemo } from 'react';

export interface UseAuth0UserReturn {
  email?: string;
  email_verified?: boolean;
  name?: string;
  nickname?: string;
  org_id?: string;
  picture?: string;
  sub?: string;
  updated_at?: string;
  companyId: string;
  companyName: string;
  roles: TokenUserRoles[];
  pillars: Set<G360Pillars>;
  hasPillarAccess: (pillar: G360Pillars) => boolean;
  hasRoleAssigned: boolean;
  isQRAdminRole: boolean;
}

export const useAuth0User = (): UseAuth0UserReturn => {
  const { user } = useAuth0();

  const {
    email,
    email_verified,
    name,
    nickname,
    org_id,
    picture,
    sub,
    updated_at,
  } = user || {};

  const companyId =
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/organization`].company_id;
  const companyName =
    user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/organization/name`];

  const auth0Roles = useMemo(
    () =>
      (user?.[`${process.env.REACT_APP_AUTH0_NAMESPACE}/roles`] as (
        | TokenUserRoles
        | G360Pillars
      )[]) ?? [],
    [user],
  );

  const roles = auth0Roles.filter((r) =>
    TokenUserRolesSet.has(r as TokenUserRoles),
  ) as TokenUserRoles[];

  const pillars = useMemo(() => {
    const pillarsSet = new Set<G360Pillars>();
    if (roles.some((role) => canViewAccessExpress(role))) {
      pillarsSet.add(G360Pillars.AccessExpress);
    }
    return pillarsSet;
  }, [roles]);

  const hasPillarAccess = useCallback(
    (pillar: G360Pillars) => {
      return roles.includes(TokenUserRoles.SUPER_ADMIN) || pillars.has(pillar);
    },
    [pillars, roles],
  );

  const hasRoleAssigned = useMemo(() => {
    return roles.some((role) => Object.values(TokenUserRoles).includes(role));
  }, [roles]);

  const isQRAdminRole = useMemo(() => {
    return roles.some((role) => role === TokenUserRoles.QR_ADMIN);
  }, [roles]);

  return {
    email,
    email_verified,
    name,
    nickname,
    org_id,
    picture,
    sub,
    updated_at,
    companyId,
    companyName,
    roles,
    pillars,
    hasPillarAccess,
    hasRoleAssigned,
    isQRAdminRole,
  };
};
