import { apiClient } from '../apiClient';
import {
  UpdateUserProfileRequest,
  UserProfileRequest,
  UserProfileResponse,
  UserProfileResponseSchema,
} from './schemas/UserProfile';
import { GetUsersParams, UsersResponse } from './schemas/Users';
import {
  InvitationResponse,
  InvitationResponseSchema,
  InviteUserRequest,
  InviteUserResponse,
} from './schemas/Invitations';

export const updateUserProfile = async (
  request: UserProfileRequest,
): Promise<UserProfileRequest> => {
  const response = await apiClient.patch(`/api/v1/users/profile`, request);

  return response.data;
};

export const getProfileUser = async (): Promise<UserProfileResponse> => {
  const response = await apiClient.get(`/api/v1/users/profile`);
  return response.data;
};

export const getUsers = async (
  args: GetUsersParams = { page: 1, page_size: 100 },
): Promise<UsersResponse[]> => {
  const response = await apiClient.get(`/api/v1/users/`, {
    params: args,
  });
  return response.data.users;
};

export const getUserByEmail = async ({
  email,
}: {
  email: string | undefined;
}): Promise<UserProfileResponse> => {
  if (!email) throw new Error('Email is required');
  const response = await apiClient.get(`/api/v1/users/${email}`);
  return UserProfileResponseSchema.parse(
    response.data?.role
      ? response.data
      : { ...response.data, role: 'Pending User' },
  );
};

export const updateUser = async (
  email: string | undefined,
  request: UpdateUserProfileRequest,
): Promise<UserProfileResponse> => {
  if (!email) {
    throw new Error('Email is required');
  }

  const response = await apiClient.put(`/api/v1/users/${email}`, request);
  return UserProfileResponseSchema.parse(response.data);
};

export const deleteUser = async ({ userId }: { userId: string }) => {
  const response = await apiClient.delete(`/api/v1/users/${userId}`);
  return response.data;
};

export const inviteUser = async (
  request: InviteUserRequest,
): Promise<InviteUserResponse[]> => {
  const response = await apiClient.post('/api/v1/invitations', request);

  return response.data;
};

export const getInvitedUsers = async (
  args: GetUsersParams = { page: 1, page_size: 100 },
): Promise<InvitationResponse> => {
  const response = await apiClient.get('/api/v1/invitations', {
    params: args,
  });
  return InvitationResponseSchema.parse(response.data);
};

export const cancelInvitation = async (inviteId: string): Promise<boolean> => {
  const response = await apiClient.delete(`/api/v1/invitations/${inviteId}`);
  return response.status === 204;
};
