import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from 'shared/theme';
import AppRouter from './appRouter';
import { QueryClientProvider } from 'react-query';
import './index.css';
import ToastContainer from 'components/Toast/ToastContainer';
import { ReactQueryDevtools } from 'react-query/devtools';
import { FeatureFlagProvider } from 'feature-flags';
import { getQueryClient } from 'query-client';

const queryClient = getQueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <FeatureFlagProvider>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <AppRouter />
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastContainer />
        </QueryClientProvider>
      </ThemeProvider>
    </FeatureFlagProvider>
  </StrictMode>,
);
