import styled from 'styled-components';

export const Title = styled.h1<{ heightSpace?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 600px;
  color: ${({ theme }) => theme.colors.text.white};
  font-size: ${({ theme }) => theme.heading.size['2xl']};
  line-height: ${({ theme, heightSpace }) =>
    heightSpace ? theme.heading.size['3xl'] : theme.heading.size['2xl']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
export const TitleActionButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
