import clsx from 'clsx';
import { forwardRef } from 'react';
import GoogleReCAPTCHA from 'react-google-recaptcha';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

const sitekey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY as string;

interface ReCAPTCHAProps {
  onChange: (validation: string | null) => void;
  onExpired?: () => void;
}

interface ReCAPTCHAInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
}

export const ReCAPTCHA = forwardRef<GoogleReCAPTCHA, ReCAPTCHAProps>(
  ({ onChange, onExpired }, ref) => (
    <GoogleReCAPTCHA
      ref={ref}
      sitekey={sitekey}
      onChange={onChange}
      onExpired={onExpired}
      theme={'dark'}
    />
  ),
);

ReCAPTCHA.displayName = 'ReCAPTCHA';

export const ReCAPTCHAInput = <T extends FieldValues>({
  control,
  name,
}: ReCAPTCHAInputProps<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });
  return (
    <div
      className={clsx(
        'flex w-fit flex-col gap-3',
        value !== 'expired' &&
          error &&
          'rounded-sm border border-[#FF0101] px-2 pb-3 pt-2',
      )}
    >
      <ReCAPTCHA onChange={onChange} onExpired={() => onChange('expired')} />
      {/* do not display the expired error message as it is handled by the recaptcha library itself */}
      {value !== 'expired' && error && (
        <p className="text-xs text-[#FF0101]">{error.message}</p>
      )}
    </div>
  );
};
