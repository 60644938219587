import {
  ConfigurationsSchema,
  NOCSiteAddressSchema,
} from 'api/nocexpress/schemas/ConfigurationsSchema';
import { OptionLabelValueSchema } from 'screens/LetUsHelp/utils/schemas';
import { nullToUndefined } from 'shared/util/schema-utils';
import { z } from 'zod';

export const IDENTIFIERS = z.enum([
  'Address',
  'Site name/store number',
  'Account',
  'Serial number',
  'Service identifier',
]);

const nonEmptyOptionLabelValueArray = z.array(OptionLabelValueSchema).nonempty({
  message: 'Required',
});

const BaseFormSchema = z.object({
  identifier: z.object({
    label: IDENTIFIERS,
    value: IDENTIFIERS,
  }),
  service: nonEmptyOptionLabelValueArray,
  service_identifier: z.array(OptionLabelValueSchema).optional(),
  service_category: OptionLabelValueSchema.nullable().optional(),
});

const SiteFormSchema = BaseFormSchema.extend({
  type: z.literal('site'),
  selectedIdentifier: NOCSiteAddressSchema,
  site: NOCSiteAddressSchema.nullish().transform(nullToUndefined),
  site_type: OptionLabelValueSchema.nullable().optional(),
});

const ConfigurationsFormSchema = BaseFormSchema.extend({
  type: z.literal('config'),
  selectedIdentifier: ConfigurationsSchema,
  site: NOCSiteAddressSchema.nullish().transform(nullToUndefined),
  site_type: OptionLabelValueSchema.nullable().optional(),
});

const MultiSiteFormSchema = BaseFormSchema.extend({
  type: z.literal('multi-site'),
  selectedIdentifier: NOCSiteAddressSchema.optional(),
  site: NOCSiteAddressSchema.nullish().transform(nullToUndefined).optional(),
  site_type: OptionLabelValueSchema,
});

export const FindLocationOrServiceFormSchema = z
  .discriminatedUnion('type', [
    SiteFormSchema,
    ConfigurationsFormSchema,
    MultiSiteFormSchema,
  ])
  .refine(
    (data) => {
      const serviceNotListed = data.service.some(
        (item) => item.value === 'service_not_listed',
      );
      if (!serviceNotListed && data?.service_identifier?.length === 0) {
        return false; // This will make service_identifier required
      }
      return true;
    },
    {
      message: 'Required',
      path: ['service_identifier'],
    },
  )
  .refine(
    (data) => {
      const serviceNotListed = data.service.some(
        (item) => item.value === 'service_not_listed',
      );
      if (serviceNotListed && !data?.service_category) {
        return false; // This will make service_category required
      }
      return true;
    },
    {
      message: 'Required',
      path: ['service_category'],
    },
  );

export type FindLocationOrServiceForm = z.infer<
  typeof FindLocationOrServiceFormSchema
>;
