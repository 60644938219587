import { NUMBERS_ONLY_REGEXP } from 'shared/constants/validation-regex-constants';
import { z } from 'zod';

export const UploadQRCodeSchema = z.object({
  qe_quote_request_id: z
    .string()
    .nonempty('Required')
    .regex(NUMBERS_ONLY_REGEXP, {
      message: 'Only numbers allowed',
    }),
  file: z.array(z.any()),
});

export type UploadQRCodeType = z.infer<typeof UploadQRCodeSchema>;
