import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from 'components/Modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import {
  CloseAndResolveTicketForm,
  CloseAndResolveTicketFormType,
  CloseAndResolveTicketRequestType,
  EscalateTicketFormType,
  NetOpsResponseForm,
} from './form-schema';
import { GraniteSelect } from 'components/Select/Select';
import TextAreaGranite from 'components/TextArea/TextArea';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useMutation, useQueryClient } from 'react-query';
import { closeNocTicket } from 'api/nocexpress/api';
import { ExtendedTicket } from 'api/techexpress/schema';

const options = [
  'The reported problem is resolved.',
  'This ticket is a duplicate or was opened in error.',
  'The reported problem cleared prior to isolation.',
  'The reported problem was resolved - issue on customer equipment.',
];

const CloseTicket = ({
  isOpen,
  close,
  ticket,
}: {
  isOpen: boolean;
  close: () => void;
  ticket: NetOpsResponseForm | ExtendedTicket;
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CloseAndResolveTicketFormType>({
    resolver: zodResolver(CloseAndResolveTicketForm),
  });

  const queryClient = useQueryClient();

  const closeMutation = useMutation(
    ({
      ticketId,
      data,
    }: {
      ticketId: number;
      data: CloseAndResolveTicketRequestType;
    }) => closeNocTicket(ticketId, data),
    {
      onSuccess: async () => {
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-details-noc',
        ]);
        queryClient.refetchQueries([
          ticket?.id?.toString(),
          'ticket-noc-notes',
        ]);
        close();
      },
    },
  );

  const onSubmit = (payload: EscalateTicketFormType) => {
    const request = {
      resolution_reason: payload.reason.value,
      additional_details: payload.additional_notes,
    };

    closeMutation.mutate({ ticketId: Number(ticket?.id), data: request });
  };

  const onError = (err: unknown) => console.log(err);

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        className="flex flex-col gap-2 rounded-lg rounded-t-lg bg-background-base-surface-2"
      >
        <div className="flex flex-col items-start justify-start rounded-t-lg bg-background-base-surface-3 px-8 py-6 font-bold leading-9">
          <h1 className="text-[28px] text-content-base-default">
            Close ticket
          </h1>
          <p className="text-base font-semibold text-content-base-subdued">
            Tickets can be closed if the reported issue no longer needs to be
            investigated.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
          <Controller
            name="reason"
            control={control}
            render={({ field: { onChange, value, ...field } }) => (
              <GraniteSelect
                {...field}
                className={`col-span-full`}
                onChange={onChange}
                label="Reason for closing ticket"
                value={value}
                options={options.map((item) => {
                  return {
                    value: item,
                    label: item,
                  };
                })}
                classNames={{
                  control: () => '!rounded-r-none',
                }}
              />
            )}
          />
          <div className="flex w-full flex-col items-start justify-start gap-2">
            <TextAreaGranite
              {...register('additional_notes')}
              className="w-full"
              error={errors.additional_notes?.message}
              placeholder="Add specific details regarding the resolution of the reported issue"
              label="Additional details (optional)"
            />
            <GraniteLabel className="text-sm">
              This response will be added directly into the notes section of
              this ticket.
            </GraniteLabel>
          </div>
        </div>
        <div className="flex items-center justify-start gap-5 px-8 pb-8">
          <GraniteButton size="large" variant="secondary" onClick={close}>
            Cancel
          </GraniteButton>
          <GraniteButton size="large" variant="primary" type="submit">
            Close ticket
          </GraniteButton>
        </div>
      </form>
    </Modal>
  );
};

export default CloseTicket;
