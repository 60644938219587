import { TypeOf, ZodTypeAny, z } from 'zod';

export const assertSchema = <T extends z.ZodTypeAny>(
  schema: T,
  data: unknown,
): data is TypeOf<T> => {
  schema.parse(data);
  return true;
};

/**
 * Strip constraints and make all schema fields nullable and optional
 */
export const makeSchemaOptional = (schema: ZodTypeAny): ZodTypeAny => {
  if (schema instanceof z.ZodString) {
    return z.string().nullable().optional();
  } else if (schema instanceof z.ZodNumber) {
    return z.number().nullable().optional();
  } else if (schema instanceof z.ZodBoolean) {
    return z.boolean().nullable().optional();
  } else if (schema instanceof z.ZodDate) {
    return z.coerce.date().nullable().optional();
  } else if (schema instanceof z.ZodObject) {
    const shape = schema.shape;
    const newShape: Record<string, ZodTypeAny> = {};
    for (const key in shape) {
      newShape[key] = makeSchemaOptional(shape[key]);
    }
    return z.object(newShape).nullable().optional();
  } else if (schema instanceof z.ZodArray) {
    return z.array(makeSchemaOptional(schema.element)).nullable().optional();
  } else if (schema instanceof z.ZodTuple) {
    return z.tuple(schema.items.map(makeSchemaOptional)).nullable().optional();
  } else if (schema instanceof z.ZodIntersection) {
    return z
      .intersection(
        makeSchemaOptional(schema._def.left),
        makeSchemaOptional(schema._def.right),
      )
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodUnion) {
    return z
      .union(schema.options.map(makeSchemaOptional))
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodDiscriminatedUnion) {
    const optionsArray = Array.from(schema.optionsMap.values());
    const newOptionsArray = optionsArray.map((option) =>
      makeSchemaOptional(option),
    );
    const optionsMap = new Map();
    schema.optionsMap.forEach((option, key) => {
      optionsMap.set(key, makeSchemaOptional(option));
    });
    return new z.ZodDiscriminatedUnion({
      discriminator: schema.discriminator,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: newOptionsArray as any,
      optionsMap: optionsMap,
      typeName: schema._def.typeName,
    })
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodEffects) {
    const innerSchema = makeSchemaOptional(schema.innerType());
    // override effect to strip refinements
    const effect = { ...schema._def.effect };
    if (effect.type === 'refinement') effect.refinement = () => true;
    return new z.ZodEffects({
      ...schema._def,
      schema: innerSchema,
      effect,
    })
      .nullable()
      .optional();
  } else if (schema instanceof z.ZodOptional) {
    return makeSchemaOptional(schema.unwrap()).optional().nullable();
  } else {
    return schema.nullable().optional();
  }
};
