import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

type StyleProps = {
  background: string;
  zIndex?: number;
  borderColor?: string;
  color?: string;
};

const SliderContainer = styled.div`
  position: relative;
  border-radius: 10px;
  padding-top: 48px;
`;

const Slider = styled.input.attrs({
  type: 'range',
  className:
    'slider h-1 bg-transparent rounded-lg appearance-none cursor-pointer absolute w-full',
})<StyleProps>`
  pointer-events: none;
  &::-webkit-slider-thumb {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
    border: 2px solid ${(props) => props.borderColor};
    box-sizing: content-box;
  }

  &::-moz-range-thumb {
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    margin-top: 0;
    pointer-events: all;
    z-index: 2;
    border: 2px solid ${(props) => props.borderColor};
    box-sizing: content-box;
  }

  width: calc(100%);
  height: 20px;
  z-index: ${(props) => props.zIndex};
  border-radius: 10px;
`;

const SliderBackgroundWrapper = styled.div.attrs({
  className:
    'slider h-1 bg-transparent rounded-xl appearance-none cursor-pointer absolute',
})`
  pointer-events: none;
  left: 0;
  right: 0;
  height: 20px;
  background: #191925;
`;

const SliderBackground = styled.div.attrs({
  className:
    'slider h-1 bg-transparent rounded-lg appearance-none cursor-pointer absolute',
})<{ background: string }>`
  pointer-events: none;

  height: 20px;
  background: ${(props) => props.background};
  border-radius: 10px;
`;

const Label = styled.div.attrs({
  className: 'absolute',
})`
  top: -5px;
  pointer-events: none;
`;

interface DualRangeSliderProps {
  color: string;
  background: string;
  allowedValues: number[];
  value: [number, number];
  onChange: (values: [number, number]) => void;
}

const DualRangeSlider = ({
  color,
  background,
  allowedValues,
  value: [minValue, maxValue],
  onChange,
}: DualRangeSliderProps) => {
  const min = allowedValues.findIndex((v) => v === minValue);
  const max = allowedValues.findIndex((v) => v === maxValue);

  const allowedValuesCount = allowedValues.length;

  const setMinValue = (value: number) => {
    onChange([allowedValues[value], allowedValues[max]]);
  };
  const setMaxValue = (value: number) => {
    onChange([allowedValues[min], allowedValues[value]]);
  };

  const [positions, setPositions] = useState<[number, number]>([0, 1]);
  const [activeHandle, setActiveHandle] = useState<'min' | 'max'>('min');

  useEffect(() => {
    fillSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue]);

  const fillSlider = () => {
    const range = allowedValuesCount;
    const values = [min, max].sort((a, b) => a - b);
    const fromPosition = values[0] / (range - 1);
    const toPosition = values[1] / (range - 1);
    setPositions([fromPosition, toPosition]);
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    setActiveHandle('min');
    setMinValue(newValue);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    setActiveHandle('max');
    setMaxValue(newValue);
  };

  return (
    <SliderContainer>
      <SliderBackgroundWrapper>
        <SliderBackground
          background={background}
          style={{
            left: `${positions[0] * 100 * 0.98}%`,
            right: `${(1 - positions[1]) * 100 * 0.98}%`,
            display: min === max ? 'none' : 'block',
          }}
        />
      </SliderBackgroundWrapper>
      <Slider
        value={min}
        min={0}
        max={allowedValuesCount - 1}
        step="1"
        onChange={handleMinChange}
        background={'#f00'}
        zIndex={activeHandle === 'min' ? 2 : 1}
        borderColor={background}
        color={color}
      />
      <div
        style={{
          width: 'calc(100% - 20px)',
          position: 'relative',
          left: 10,
          right: 10,
        }}
      >
        <Label
          style={{
            left: `calc(${(min / (allowedValuesCount - 1)) * 100}%)`,
          }}
        >
          <Tooltip text={formatBytes(minValue)} />
        </Label>
      </div>
      <Slider
        value={max}
        min={0}
        max={allowedValuesCount - 1}
        step="1"
        onChange={handleMaxChange}
        background={'#f00'}
        zIndex={activeHandle === 'max' ? 2 : 1}
        borderColor={background}
        color={color}
      />
      <div
        style={{
          width: 'calc(100% - 20px)',
          position: 'relative',
          left: 10,
          right: 10,
        }}
      >
        <Label
          style={{
            left: `calc(${(max / (allowedValuesCount - 1)) * 100}%)`,
          }}
        >
          <Tooltip text={formatBytes(maxValue)} />
        </Label>
      </div>
      <div className="mt-7 flex w-full items-center justify-between px-2 text-xs">
        <span>{formatBytes(allowedValues[0], ' ', 'b/s')}</span>
        <span>
          {formatBytes(allowedValues[allowedValues.length - 1], ' ', 'b/s')}
        </span>
      </div>
    </SliderContainer>
  );
};

interface TooltipProps {
  text: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <div className="relative flex items-center">
      <div
        className="absolute bottom-full mb-2 w-auto rounded-md bg-background-base-surface-1 p-2 text-sm text-content-base-subdued shadow-lg"
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {text}
        <div className="absolute bottom-[-6px] left-1/2 -translate-x-1/2 transform">
          <div className="h-0 w-0 border-x-[6px] border-t-[6px] border-x-transparent border-t-background-base-surface-1"></div>
        </div>
      </div>
    </div>
  );
};

export default DualRangeSlider;

function formatBytes(bytes: number, prefix = '', suffix = ''): string {
  if (bytes < 1000) {
    return bytes + prefix + 'M' + suffix;
  } else if (bytes < 10000) {
    return (bytes / 1000).toFixed(1) + prefix + 'G' + suffix;
  } else {
    return (bytes / 1000).toFixed(0) + prefix + 'G' + suffix;
  }
}
