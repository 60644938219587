import RecentTickets from './RecentTickets';
import { useQuery } from 'react-query';
import { getRecentlyCompletedTickets } from '../../api/techexpress/api';
import {
  RecentlyCompletedTicketResponse,
  RecentlyCompletedTicketsResponse,
} from '../../api/techexpress/schema';
import { RecentTicket } from './RecentTickets';

const buildRecentTicketsData = (
  apiData: RecentlyCompletedTicketsResponse,
): RecentTicket[] => {
  // Sort by most recently closed just to be sure
  return apiData
    .map((ticket) => buildRecentTicketData(ticket))
    .sort((a, b) => {
      return b.dateResolved.getTime() - a.dateResolved.getTime();
    });
};

const buildRecentTicketData = (
  apiTicketData: RecentlyCompletedTicketResponse,
): RecentTicket => {
  return {
    id: apiTicketData.id,
    address1: apiTicketData.address_1,
    address2: apiTicketData.address_2 ?? '',
    city: apiTicketData.city ?? '',
    state: apiTicketData.state_identifier ?? '',
    zip: apiTicketData.zip ?? '',
    priority: apiTicketData.sub_type ?? '',
    type: apiTicketData.type_name,
    dateResolved: apiTicketData.date_resolved,
  };
};

const TechExpressRecentTickets: React.FC = () => {
  const { data: recentTickets, isLoading } = useQuery(
    ['tech-express', 'recent-tickets'],
    () => getRecentlyCompletedTickets(),
  );

  return (
    <div className="w-full rounded bg-background-base-surface-2 p-4 md:px-6 md:py-6 xl:max-w-[504px]">
      <RecentTickets
        tickets={buildRecentTicketsData(recentTickets ?? [])}
        isLoading={isLoading}
      />
    </div>
  );
};

export default TechExpressRecentTickets;
