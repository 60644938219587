import { WizardStateBlock } from '../../../components/StepperWizard/WizardStateBlock';

export interface WizardProgressProps {
  step: number;
}

export const WizardProgress = ({ step }: WizardProgressProps) => {
  return (
    <div className="mb-12 grid grid-cols-3 items-end gap-1 text-xs">
      <WizardStateBlock
        label="Get started"
        isActive={step >= 1}
        isCompleted={step > 1}
      />
      <WizardStateBlock
        label="Add ticket details"
        isActive={step >= 2}
        isCompleted={step > 2}
      />
      <WizardStateBlock
        label="Finalize & review your ticket"
        isActive={step >= 3}
        isCompleted={step > 3}
      />
    </div>
  );
};
