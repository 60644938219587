import {
  CardLogo,
  GraniteSupportButton,
  HomeHref,
  HomeLayoutFragment,
  HomeLink,
  HomeServicesGrid,
  HomeServicesHeader,
  HomeWelcomeContainer,
  HomeWelcomeDate,
  HomeWelcomeQuickLink,
  HomeWelcomeQuickLinkBar,
  HomeWelcomeTitle,
  UICard,
  UICardFooter,
  UICardHeader,
} from './Home.styles';
import techexpressLogo from '../../assets/images/techexpress.svg';
import accessexpressLogo from '../../assets/images/accessexpress.svg';
import nocexpressLogo from '../../assets/images/nocexpress.svg';
import { ConstructSharp, Exit, InformationCircle, Wifi } from 'react-ionicons';
import format from 'date-fns/format';
import { ReactComponent as Footsteps } from '../../assets/icon/footsteps.svg';
import CompanyName from 'components/HeaderNav/CompanyName';
import { useAuth0User } from 'hooks/useAuth0User';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { DisplayForRole } from '../../components/DisplayForRole/DisplayForRole';
import { G360Pillars, TokenUserRoles } from '../../api/users/schemas/Users';
import { CompanyAdminsDialog } from './CompanyAdminsDialog';
import { useModal } from 'hooks/useModal';

const Home = () => {
  const { name, companyName, hasPillarAccess, hasRoleAssigned, isQRAdminRole } =
    useAuth0User();

  const { open, ...props } = useModal();
  return (
    <div className="flex w-full flex-col items-start justify-start gap-6 p-8">
      {!hasRoleAssigned && (
        <div className="mx-auto -mb-10 flex w-full max-w-[1376px] justify-between rounded border border-status-info-default bg-background-base-surface-1 p-4">
          <div className="flex items-center gap-1">
            <InformationCircle width="22px" height="22px" color="#9796F3" />
            <span className="text-base font-bold text-content-base-default">
              Your account is pending an assigned role from your Company Admin.
            </span>
          </div>
          <GraniteButton variant="primary" size="small" onClick={open}>
            See Company Admin
          </GraniteButton>
        </div>
      )}
      <HomeWelcomeContainer className="bg-background-base-surface-2/70">
        <HomeWelcomeDate>
          {format(new Date(), 'eeee, MMMM do, yyyy')}
        </HomeWelcomeDate>
        <HomeWelcomeTitle>Welcome, {name ?? ''}</HomeWelcomeTitle>
        <CompanyName name={companyName} textClasses="text-2xl" />
        {!isQRAdminRole && (
          <HomeWelcomeQuickLinkBar>
            {hasPillarAccess(G360Pillars.AccessExpress) && (
              <HomeWelcomeQuickLink to="/access-express">
                <Wifi width="24px" height="24px" />
                Order services
              </HomeWelcomeQuickLink>
            )}
            <DisplayForRole roles={[TokenUserRoles.USER]}>
              <HomeWelcomeQuickLink to="/tech-express/let-us-help">
                <Footsteps />
                Request dispatch
              </HomeWelcomeQuickLink>
            </DisplayForRole>
            <DisplayForRole roles={[TokenUserRoles.USER]}>
              <HomeWelcomeQuickLink to="/noc-express/open-ticket">
                <ConstructSharp width="24px" height="24px" color="inherit" />
                Open repair ticket
              </HomeWelcomeQuickLink>
            </DisplayForRole>
          </HomeWelcomeQuickLinkBar>
        )}
      </HomeWelcomeContainer>
      <HomeLayoutFragment>
        <HomeServicesHeader>Your services</HomeServicesHeader>
        <HomeServicesGrid>
          {hasPillarAccess(G360Pillars.AccessExpress) && (
            <UICard>
              <CardLogo alt="Access Express logo" src={accessexpressLogo} />
              <p>
                Browse and order Granite products and services available at your
                service locations.
              </p>
              <UICardFooter>
                <div className="flex justify-start gap-4">
                  <HomeLink to="access-express/index">
                    <GraniteButton
                      size="large"
                      variant={`${isQRAdminRole ? 'secondary' : 'primary'}`}
                      className="self-end"
                    >
                      {isQRAdminRole ? 'Go to quote requests' : 'View quotes'}
                    </GraniteButton>
                  </HomeLink>
                  {isQRAdminRole && (
                    <HomeLink to="/upload-qr">
                      <GraniteButton
                        size="large"
                        variant="primary"
                        className="self-end"
                      >
                        Upload quote
                      </GraniteButton>
                    </HomeLink>
                  )}
                </div>
              </UICardFooter>
            </UICard>
          )}
          {hasRoleAssigned && !isQRAdminRole && (
            <UICard>
              <CardLogo alt="Tech Express Logo" src={techexpressLogo} />
              <p>
                Request a service technician to any location serviced by Granite
                for a range of different service needs.
              </p>
              <UICardFooter>
                <HomeLink to="/tech-express">
                  <GraniteButton size="large">View your tickets</GraniteButton>
                </HomeLink>
              </UICardFooter>
            </UICard>
          )}
          {hasRoleAssigned && !isQRAdminRole && (
            <UICard>
              <CardLogo alt="NOC Express Logo" src={nocexpressLogo} />
              <p>
                View all repair tickets, as well as a personalized dashboard
                based on the performance of your company’s network of Granite
                provided services.
              </p>
              <UICardFooter>
                <HomeLink to="/noc-express">
                  <GraniteButton size="large">View your tickets</GraniteButton>
                </HomeLink>
              </UICardFooter>
            </UICard>
          )}
          <UICard>
            <UICardHeader>Granite support</UICardHeader>
            <p>
              Need assistance? Visit our help center for support from Granite’s
              24/7 Customer Service team.
            </p>
            <UICardFooter>
              <HomeHref href="https://granitenet.com/support">
                <GraniteSupportButton>
                  Visit help center
                  <Exit width="16px" height="16px" color />
                </GraniteSupportButton>
              </HomeHref>
            </UICardFooter>
          </UICard>
        </HomeServicesGrid>
      </HomeLayoutFragment>
      <CompanyAdminsDialog {...props} />
    </div>
  );
};

export default Home;
