import { z } from 'zod';
import { createPaginatedResponseSchema } from 'api/common-schemas';
import { nullToUndefined } from 'shared/util/schema-utils';

export type CreateTicketRequest = z.infer<typeof CreateTicketRequestSchema>;
export const CreateTicketRequestSchema = z.object({
  summary: z.string(),
  issue_name: z.string(),
  initial_description: z.string().optional(),
  type_name: z.string().optional(),
  subtype_name: z.string().optional(),
  automatic_email_contact: z.boolean(),
  automatic_email_cc: z.boolean(),
  automatic_email_cc_address: z.string().email().optional(),
  configuration_ids: z.string().optional(),
  customer_ticket_number: z.string().optional(),
  prior_ticket: z.number().nullish().transform(nullToUndefined),
  contact_name: z.string(),
  contact_email_address: z.string(),
  local_contact_name: z.string(),
  local_contact_number: z.number(),
  sites: z.array(
    z.object({
      id: z.number(),
      maintenance_window: z.string(),
    }),
  ),
});

export const CreateTicketResponseSchema = z.object({
  id: z.number(),
  attachments: z
    .array(
      z.object({
        filename: z.string(),
        status: z.enum(['success', 'failure']),
      }),
    )
    .nullish()
    .transform(nullToUndefined),
});

export type CreateTicketResponse = z.infer<typeof CreateTicketResponseSchema>;

const ConfigurationItemSchema = z.object({
  id: z.number(),
  type: z.string(),
  name: z.string(),
  location_name: z.string().nullable(),
});

export const NetOpsTicketStatuses = z.enum([
  'Open',
  'In Progress',
  'Pending Action',
  'Pending Customer',
  'Pending NI',
  'Resolved',
]);

const NetOpsItemSchema = z.object({
  id: z.number(),
  summary: z.string(),
  city: z.string(),
  state_identifier: z.string().optional(),
  zip: z.string(),
  issue: z.string().nullable(),
  status_name: NetOpsTicketStatuses,
  last_updated: z.coerce.date(),
  date_entered: z.coerce.string(),
  company_name: z.string(),
  site_name: z.string(),
  state: z.string(),
  address_1: z.string(),
  address_2: z.string().optional(),
  configurations: z.array(ConfigurationItemSchema).nullable(),
  service_name: z.string().nullable(),
  service_id: z.string().nullable(),
  is_followed: z.boolean().default(false),
});

export type NetOpsItem = z.infer<typeof NetOpsItemSchema>;

export const NetOpsFilterSearchParamsSchema = z
  .object({
    // filters
    search: z.string(),
    status: z.string(),
    issue: z.string(),
    services: z.string(),
    date_order: z.string(),
    last_updated_order: z.string(),
    configuration_ids: z.string(),
    // pagination
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type NetOpsFilterSearchParams = z.infer<
  typeof NetOpsFilterSearchParamsSchema
>;

export const NetOpsIndexAPIResponseSchema =
  createPaginatedResponseSchema(NetOpsItemSchema);

export type NetOpsIndexAPIResponse = z.infer<
  typeof NetOpsIndexAPIResponseSchema
>;
