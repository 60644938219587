import { Modal } from 'components/Modal/Modal';
import { WizardStateBlock } from 'components/StepperWizard/WizardStateBlock';
import React, { useState } from 'react';
import StarRating from './StarRating';
import {
  BaseFeedbackResponseSchema,
  FeedbackSchemaForm,
  FeedbackSchemaRequestType,
  FeedbackSchemaResponse,
  FeedbackSchemaType,
} from './form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import GraniteTextArea from '../../../components/TextArea/TextArea';
import { RadioButtonGranite } from 'components';
import { useMutation } from 'react-query';
import { postTicketFeedback } from 'api/techexpress/api';
import { ticketFeedbackFormToRequest } from './utils';
import showToast from 'components/Toast/Toast';

const ProvideFeedbackModal = ({
  ticketId,
  isOpen,
  close,
  feedback,
}: {
  ticketId: string;
  isOpen: boolean;
  close: () => void;
  feedback?: BaseFeedbackResponseSchema;
}) => {
  const [step, setStep] = useState(1);
  const safeFormat = FeedbackSchemaResponse.safeParse(feedback);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
  } = useForm<FeedbackSchemaType>({
    resolver: zodResolver(FeedbackSchemaForm),
    defaultValues: safeFormat.success
      ? {
          contact_me_flag: safeFormat.data.contact_me_flag ? 'yes' : 'no',
          footer_response: safeFormat.data.footer_response,
          techArrivedInTimeRating: safeFormat.data.results.find(
            (item) => item.question_id === 5,
          )?.answer
            ? 'yes'
            : 'no',
          deliverablesRating: safeFormat.data.results.find(
            (item) => item.question_id === 6,
          )?.answer
            ? 'yes'
            : 'no',
          overallRating:
            (safeFormat.data.results.find((item) => item.question_id === 4)
              ?.answer ?? 0) + 1,
          communationRating:
            (safeFormat.data.results.find((item) => item.question_id === 1)
              ?.answer ?? 0) + 1,
          professionalismRating:
            (safeFormat.data.results.find((item) => item.question_id === 2)
              ?.answer ?? 0) + 1,
          qualityRating:
            (safeFormat.data.results.find((item) => item.question_id === 3)
              ?.answer ?? 0) + 1,
        }
      : {
          contact_me_flag: 'no',
          techArrivedInTimeRating: 'yes',
          deliverablesRating: 'yes',
        },
  });

  const createTicketFeedbackMutation = useMutation(
    ({ feedback }: { feedback: FeedbackSchemaRequestType }) =>
      postTicketFeedback(ticketId, feedback),
    {
      onSuccess: () => {
        showToast.confirmation({
          title: 'Feedback submitted!',
          message: `Ticket #${ticketId}`,
        });
        close();
      },
    },
  );

  const onSubmit = (data: FeedbackSchemaType) => {
    const request: FeedbackSchemaRequestType =
      ticketFeedbackFormToRequest(data);
    createTicketFeedbackMutation.mutate({ feedback: request });
  };

  const handleNextClick = async () => {
    const overallRating = watch('overallRating');
    if (step === 1 && (overallRating === undefined || overallRating === null)) {
      trigger('overallRating');
      return;
    }
    if (step === 2 && !safeFormat.success) {
      handleSubmit(onSubmit)();
      return;
    }
    setStep(2);
  };

  return (
    <Modal isVisible={isOpen} close={close} className="w-full max-w-[624px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start justify-start">
          <div className="flex flex-col items-start justify-start gap-2 bg-background-base-surface-3 px-8 py-6">
            <h1 className="text-[28px] font-bold leading-9 text-content-base-default">
              Rate your experience
            </h1>
            <p className="text-base font-semibold text-content-base-subdued">
              Your feedback is important to us. Please rate your dispatch
              experience to help us enhance our future services.
            </p>
            <div className="grid w-full grid-cols-2 items-end gap-1">
              <WizardStateBlock isActive={step >= 1} />
              <WizardStateBlock isActive={step >= 2} />
            </div>
          </div>
          <div className="justify-col flex w-full flex-col items-start justify-start gap-6 bg-background-base-surface-2 p-8">
            {step === 1 && (
              <>
                <Controller
                  name="overallRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Overall quality of dispatch"
                      value={field.value}
                      onRate={field.onChange}
                      error={errors.overallRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
                <RadioButtonGranite
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  containerClass="!flex !flex-row !gap-6 mt-2"
                  selectedValue={watch('contact_me_flag')}
                  label="Contact me about this dispatch "
                  error={errors.contact_me_flag && ''}
                  {...register('contact_me_flag', { required: true })}
                  disabled={safeFormat.success}
                  onChange={(value: string) =>
                    setValue('contact_me_flag', value as 'yes' | 'no')
                  }
                />
                <GraniteTextArea
                  label="Additional feedback (optional)"
                  inputClassName="resize-y"
                  placeholder="Add additional notes about your experience"
                  error={errors.footer_response?.message}
                  disabled={safeFormat.success}
                  className="w-full"
                  {...register('footer_response')}
                />
              </>
            )}
            {step === 2 && (
              <>
                <RadioButtonGranite
                  {...register('techArrivedInTimeRating')}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  containerClass="!flex !flex-row !gap-6 mt-2"
                  selectedValue={watch('techArrivedInTimeRating')}
                  onChange={(value: string) =>
                    setValue('techArrivedInTimeRating', value as 'yes' | 'no')
                  }
                  label="Did the techncician arrive on time? (optional)"
                  error={errors.contact_me_flag && ''}
                  disabled={safeFormat.success}
                />
                <RadioButtonGranite
                  {...register('deliverablesRating')}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  containerClass="!flex !flex-row !gap-6 mt-2"
                  selectedValue={watch('deliverablesRating')}
                  onChange={(value: string) =>
                    setValue('deliverablesRating', value as 'yes' | 'no')
                  }
                  label="Were all deliverables received? (optional)"
                  error={errors.contact_me_flag && ''}
                  disabled={safeFormat.success}
                />
                <Controller
                  name="qualityRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Quality of work (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.qualityRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
                <Controller
                  name="professionalismRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Professionalism (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.professionalismRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
                <Controller
                  name="communationRating"
                  control={control}
                  render={({ field }) => (
                    <StarRating
                      label="Communication (optional)"
                      value={field.value as number}
                      onRate={field.onChange}
                      error={errors.communationRating?.message}
                      disabled={safeFormat.success}
                    />
                  )}
                />
              </>
            )}
            <div className="mt-6 flex items-start justify-start gap-5">
              <GraniteButton
                size="large"
                variant="secondary"
                onClick={() => setStep(1)}
              >
                {step === 1 ? 'Cancel' : 'Back'}
              </GraniteButton>
              {step === 2 && safeFormat.success ? (
                <GraniteButton size="large" onClick={close}>
                  Close
                </GraniteButton>
              ) : (
                <GraniteButton size="large" onClick={handleNextClick}>
                  {step === 2 ? 'Submit' : 'Next'}
                </GraniteButton>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ProvideFeedbackModal;
