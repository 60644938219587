import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CloseCircle } from 'react-ionicons';
import { clsx } from 'clsx';

export interface ModalProps {
  isVisible: boolean;
  close: () => void;
  className?: string;
  children: ReactNode;
  enableScrolling?: boolean;
  hideCloseButton?: boolean;
  closeOnlyOnIconClick?: boolean;
  overlayClassName?: string;
}

export const Modal = ({
  className,
  isVisible,
  close,
  children,
  closeOnlyOnIconClick = false,
  enableScrolling = false,
  hideCloseButton = false,
  overlayClassName,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const closeOnClickOutside = (ev: MouseEvent) => {
      if (
        !closeOnlyOnIconClick &&
        isVisible &&
        modalRef.current &&
        ev.target instanceof Node &&
        !modalRef.current.contains(ev.target)
      ) {
        close();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'hidden';
    } else {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', closeOnClickOutside);
      document.body.style.overflow = 'auto';
    };
  }, [close, isVisible, closeOnlyOnIconClick]);

  return isVisible
    ? createPortal(
        <div
          className={clsx(
            'fixed left-0 top-0 z-50 flex h-full w-full cursor-pointer items-center justify-center bg-background-base-overlay',
            overlayClassName,
          )}
        >
          <div
            className={clsx(
              'relative flex cursor-default flex-col rounded-lg bg-background-base-surface-2 shadow',
              className,
              {
                'max-h-[90vh]': enableScrolling,
              },
            )}
            ref={modalRef}
            role="dialog"
          >
            {!hideCloseButton && (
              <button
                className="absolute right-0 mt-[-3rem] flex h-12 w-12 items-center justify-center self-end fill-button-content-ghost-default"
                title="close dialog"
                onClick={close}
              >
                <CloseCircle
                  color="inherit"
                  width="32px"
                  height="32px"
                  style={{ textAlign: 'center' }}
                />
              </button>
            )}
            <div
              className={clsx({
                '!overflow-auto scrollbar-thin scrollbar-track-background-base-surface-1 scrollbar-thumb-stroke-base-subdued':
                  enableScrolling,
              })}
            >
              {children}
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
};
