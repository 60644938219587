import React from 'react';
import anchor from 'assets/icon/anchor.svg';

export type ArrowDirection = 'left' | 'up' | 'right' | 'down';
export type ArrowPosition = 'left' | 'top' | 'middle' | 'bottom' | 'right';

interface TooltipProps {
  arrowDirection: ArrowDirection;
  arrowPosition: ArrowPosition;
  content: string;
  showHeader?: boolean;
}

const directionRotations: Record<ArrowDirection, string> = {
  down: '-rotate-180',
  left: '-rotate-90',
  right: 'rotate-90',
  up: '',
};

const getPositionStyles = (
  direction: ArrowDirection,
  position: ArrowPosition,
): string => {
  const positions: Record<ArrowPosition, Record<ArrowDirection, string>> = {
    top: {
      up: '-top-[10px] left-[16px]',
      left: 'top-[16px] -left-[18px]',
      right: 'top-[16px] -right-[16px]',
      down: '-bottom-[10px] left-[16px]',
    },
    middle: {
      up: '-top-[10px] left-[152px]',
      left: 'top-[42px] -left-[18px]',
      right: 'top-[40px] -right-[16px]',
      down: '-bottom-[10px] left-[152px]',
    },
    bottom: {
      up: '-top-[10px] right-[16px]',
      left: 'bottom-[16px] -left-[18px]',
      right: 'bottom-[16px] -right-[16px]',
      down: '-bottom-[10px] right-[16px]',
    },
    right: {
      up: '-top-[10px] right-[16px]',
      left: 'bottom-[16px] -left-[18px]',
      right: 'bottom-[16px] -right-[16px]',
      down: '-bottom-[10px] right-[16px]',
    },
    left: {
      up: '-top-[10px] left-[16px]',
      left: 'top-[16px] -left-[18px]',
      right: 'top-[16px] -right-[16px]',
      down: '-bottom-[10px] left-[16px]',
    },
  };
  return positions[position][direction];
};

const TooltipArrow: React.FC<{
  direction: ArrowDirection;
  position: ArrowPosition;
}> = ({ direction, position }) => {
  const baseClass = `absolute bg-transparent ${
    directionRotations[direction]
  } ${getPositionStyles(direction, position)}`;

  return <img src={anchor} className={baseClass} />;
};

const TooltipContent: React.FC<{ content: string; showHeader?: boolean }> = ({
  content,
  showHeader,
}) => {
  return (
    <div className="relative w-[328px] rounded-[8px] bg-background-base-surface-0 p-[20px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
      {showHeader && <div className="mb-1 font-bold">Tooltip</div>}
      {content}
    </div>
  );
};

const Tooltip: React.FC<TooltipProps> = ({
  arrowDirection,
  arrowPosition,
  content,
  showHeader,
}) => {
  return (
    <div className="relative inline-block">
      <TooltipArrow direction={arrowDirection} position={arrowPosition} />
      <TooltipContent content={content} showHeader={showHeader} />
    </div>
  );
};

export default Tooltip;
