import { useEffect, useState } from 'react';
import { AlertCircle, Close } from 'react-ionicons';

const InformationalMessage = () => {
  const [hideInformationalMessage, setHideInformationalMessage] =
    useState(false);

  useEffect(() => {
    const isDismissed = localStorage.getItem('informationalMessageDismissed');
    if (isDismissed) {
      setHideInformationalMessage(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('informationalMessageDismissed', 'true');
    setHideInformationalMessage(true);
  };

  if (hideInformationalMessage) return null;

  return (
    <div className="mb-6 flex items-center gap-2 rounded border border-status-info-default bg-background-base-surface-1 p-4">
      <AlertCircle width="22px" height="22px" color="#9796F3" />
      <span className="text-base font-bold text-content-base-default">
        Expand cards individually and click the content to access additional
        data.{' '}
      </span>
      <div className="ml-auto cursor-pointer">
        <Close
          width="22px"
          height="22px"
          color="#F8FAFC"
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default InformationalMessage;
