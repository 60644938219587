import { useEffect, useState } from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import clsx from 'clsx';

const Switch = ({
  disabled = false,
  label,
  onChange,
  isOn = false,
  labelClassName,
  size = 'default',
  classNames,
}: {
  disabled?: boolean;
  label?: string;
  onChange: (value: boolean) => void;
  isOn?: boolean;
  size?: 'default' | 'small';
  labelClassName?: string;
  classNames?: string;
}) => {
  const [on, setOn] = useState(isOn);
  const handleOnChange = (value: boolean) => {
    setOn(value);
    onChange(value);
  };
  useEffect(() => {
    setOn(isOn);
  }, [isOn]);

  return (
    <HeadlessSwitch.Group>
      <div className={clsx(`flex items-center justify-between`, classNames)}>
        {label && (
          <HeadlessSwitch.Label
            className={clsx('mr-4 font-normal', labelClassName)}
            passive
          >
            {label}
          </HeadlessSwitch.Label>
        )}
        <HeadlessSwitch
          checked={on}
          disabled={disabled}
          onChange={handleOnChange}
          className={clsx(
            `${
              on && !disabled
                ? 'bg-input-background-selected'
                : disabled
                  ? 'bg-input-stroke-disabled'
                  : 'bg-input-stroke-unfilled'
            } inline-flex h-6 w-11 items-center rounded-full border-opacity-[.5] py-[6px] hover:border-input-background-selected`,
            size === 'small' && '!h-[18px] !w-8',
          )}
        >
          <span
            className={clsx(
              `${on ? 'translate-x-6' : 'translate-x-1'} ${
                disabled
                  ? 'bg-button-background-primary-disabled'
                  : 'bg-background-base-surface-0'
              }
            inline-block h-4 w-4 transform rounded-full transition`,
              size === 'small' &&
                `${
                  on ? '!translate-x-4' : '!translate-x-0.5'
                } !h-[14px] !w-[14px]`,
            )}
          />
        </HeadlessSwitch>
      </div>
    </HeadlessSwitch.Group>
  );
};

export default Switch;
