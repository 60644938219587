import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import * as process from 'process';
import { PropsWithChildren } from 'react';

const INVALID_REQUEST = 'invalid_request';
const ACCESS_DENIED = 'access_denied';
const NO_ORG_MEMBER =
  'client requires organization membership, but user does not belong to any organization';
const EMAIL_NOT_VERIFIED = 'Please verify your email before logging in.';

type ErrorCode = typeof INVALID_REQUEST | typeof ACCESS_DENIED;
type ErrorDescription = typeof NO_ORG_MEMBER | typeof EMAIL_NOT_VERIFIED;
type ErrorRedirectPathMapType = {
  [key in ErrorCode]: Partial<Record<ErrorDescription, string>>;
};

const ErrorRedirectPathMap: ErrorRedirectPathMapType = {
  [INVALID_REQUEST]: {
    [NO_ORG_MEMBER]: '/not-verified',
  },
  [ACCESS_DENIED]: {
    [EMAIL_NOT_VERIFIED]: '/not-verified',
  },
};

const getRedirectPathForError = (
  errorCode: ErrorCode | null,
  errorDescription: ErrorDescription | null,
) => {
  if (!errorCode || !errorDescription) return;
  return ErrorRedirectPathMap[errorCode]?.[errorDescription];
};

export const Auth0ProviderWithNavigate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const errorPath = getRedirectPathForError(
    searchParams.get('error') as ErrorCode | null,
    searchParams.get('error_description') as ErrorDescription | null,
  );
  if (errorPath) return <Navigate to={errorPath} />;

  const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope: 'openid profile email',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      skipRedirectCallback={window.location.pathname === '/signup-received'}
    >
      {children}
    </Auth0Provider>
  );
};
