import { z } from 'zod';
import { nullToUndefined } from '../../shared/util/schema-utils';
import { createPaginatedResponseSchema } from '../common-schemas';
import { ResponseScheduleSchema } from './schemas/SiteDispatchDateScheduleSchemas';
import {
  GetStartedQuestionnaireBaseFormSchema,
  GetStartedQuestionnaireFormSchema,
} from 'screens/LetUsHelp/GetStarted/schemas';
import { TicketDetailFormSchemas } from 'screens/LetUsHelp/TicketDetails/schemas';
import { ReviewTicketFormSchema } from 'screens/LetUsHelp/Review/schemas';
import { makeSchemaOptional } from 'api/schema-utils';
import { ReviewTemplateFormSchema } from 'screens/TechExpress/Templates/schemas';

export const TicketSchema = z.object({
  address_1: z.string(),
  address_2: z.string().nullish().transform(nullToUndefined),
  automatic_email_cc: z.boolean(),
  automatic_email_cc_address: z
    .string()
    .nullish()
    .transform((address) =>
      address
        ? address.split(';').filter((a) => a.trim().length > 0)
        : undefined,
    ),
  automatic_email_contact: z.boolean(),
  contact_email_address: z.string().nullish().transform(nullToUndefined),
  board_name: z.string(),
  category: z.string(),
  city: z.string(),
  company_id: z.number(),
  company_name: z.string(),
  customer_ticket_number: z.string().nullish().transform(nullToUndefined),
  customer_preferred_tech: z.boolean().nullish(),
  prior_ticket: z.number().nullish().transform(nullToUndefined),
  date_entered: z.string().datetime(),
  date_resolved: z.string().nullish().transform(nullToUndefined),
  id: z.number(),
  last_updated: z.string().datetime().nullish().transform(nullToUndefined),
  local_contact_name: z.string().nullish().transform(nullToUndefined),
  local_contact_number: z.number().nullish().transform(nullToUndefined),
  location_contact_email: z.string().nullish().transform(nullToUndefined),
  location_contact_ext: z.string().nullish().transform(nullToUndefined),
  location_name: z.string().nullish().transform(nullToUndefined),
  location_number: z.string().nullish().transform(nullToUndefined),
  non_standard_tools: z.string().nullish().transform(nullToUndefined),
  secondary_lcon_email: z.string().nullish().transform(nullToUndefined),
  secondary_lcon_ext: z.string().nullish().transform(nullToUndefined),
  secondary_lcon_name: z.string().nullish().transform(nullToUndefined),
  secondary_lcon_number: z.string().nullish().transform(nullToUndefined),
  site_name: z.string().nullish().transform(nullToUndefined),
  special_instructions: z.string().nullish().transform(nullToUndefined),
  state_identifier: z.string(),
  status_name: z
    .string()
    .transform(
      (sn) => sn.substring(0, 1).toUpperCase() + sn.substring(1).toLowerCase(),
    ),
  sub_type: z.string().toUpperCase(),
  po_number: z.string().nullish().transform(nullToUndefined),
  summary: z.string(),
  technician_id: z.string().nullish().transform(nullToUndefined),
  technician_name: z.string().nullish().transform(nullToUndefined),
  technician_phone_number: z.string().nullish().transform(nullToUndefined),
  type_name: z.string(),
  zip: z.string(),
  priority: z.string().nullish().transform(nullToUndefined),
  schedule: z
    .discriminatedUnion('scheduling_type', [
      z.object({
        scheduling_type: z.literal('Requested Window'),
        start_date: z.string().datetime(),
        end_date: z.string().datetime(),
      }),
      z.object({
        scheduling_type: z.literal('Hard Start'),
        start_date: z.string().datetime(),
      }),
    ])
    .nullish()
    .catch(undefined)
    .transform(nullToUndefined),
});

export type Ticket = z.infer<typeof TicketSchema>;

const LocationsSchemaRequest = z.object({
  sub_type_name: z.string(),
  local_contact_number: z.union([z.string(), z.number()]).optional(),
  location_name: z.string().optional(),
  location_number: z.union([z.string(), z.number()]).optional(),
  site: z.number(),
  summary: z.string(),
  local_contact_name: z.string().optional(),
  scheduling_type: z.enum(['Hard Start', 'Requested Window']),
  start_date: z.string().datetime(),
  end_date: z.string().datetime().optional(),
});

export const CreateTicketRequestSchema = z.object({
  automatic_email_cc: z.boolean(),
  automatic_email_cc_address: z.string().email().optional(),
  automatic_email_contact: z.boolean(),
  board_name: z.string(),
  contact_name: z.string(),
  contact_email_address: z.string(),
  customer_ticket_number: z.string().optional(),
  customer_preferred_tech: z.boolean().optional(),
  prior_ticket: z.number().optional(),
  end_date: z.string().datetime().optional(),
  initial_description: z.string(),
  location_contact_email: z.string().optional(),
  location_contact_ext: z.string().optional(),
  non_standard_tools: z.string().optional(),
  secondary_lcon_email: z.string().optional(),
  secondary_lcon_ext: z.string().optional(),
  secondary_lcon_name: z.string().optional(),
  secondary_lcon_number: z.string().optional(),
  po_number: z.string().optional(),
  special_instructions: z.string().optional(),
  type_name: z.string(),
  locations: z.array(LocationsSchemaRequest).optional(),
});

export type CreateTicketRequest = z.infer<typeof CreateTicketRequestSchema>;

export const ExtendedTicketSchema = TicketSchema.merge(
  z.object({
    date_entered: z.coerce.date(),
    date_resolved: z.coerce.date().nullish().transform(nullToUndefined),
    initial_description: z.string().nullish().transform(nullToUndefined),
    last_updated: z.coerce.date(),
    schedule: ResponseScheduleSchema.nullish()
      .catch(undefined)
      .transform(nullToUndefined),
    company_identifier: z.string(),
  }),
);

export type ExtendedTicket = z.infer<typeof ExtendedTicketSchema>;

export const CreateTicketResponseSchema = ExtendedTicketSchema.merge(
  z.object({
    attachments: z
      .array(
        z.object({
          filename: z.string(),
          status: z.enum(['success', 'failure']),
        }),
      )
      .nullish()
      .transform(nullToUndefined),
  }),
);

export type CreateTicketResponse = z.infer<typeof CreateTicketResponseSchema>;

export const TicketEditResponseSchema = z.object({
  automatic_email_cc: z.boolean().nullish().transform(nullToUndefined),
  automatic_email_cc_address: z
    .string()
    .nullish()
    .transform((address) =>
      address
        ? address.split(';').filter((a) => a.trim().length > 0)
        : undefined,
    ),
  automatic_email_contact: z.boolean().nullish().transform(nullToUndefined),
  contact_name: z.string().nullish().transform(nullToUndefined),
  customer_ticket_number: z.string().nullish().transform(nullToUndefined),
  customer_preferred_tech: z.boolean().nullish().transform(nullToUndefined),
  prior_ticket: z.number().nullish(),
  initial_description: z.string().nullish().transform(nullToUndefined),
  local_contact_name: z.string().nullish().transform(nullToUndefined),
  local_contact_number: z.number().nullish().transform(nullToUndefined),
  location_contact_email: z.string().nullish().transform(nullToUndefined),
  location_contact_ext: z.string().nullish().transform(nullToUndefined),
  location_name: z.string().nullish().transform(nullToUndefined),
  location_number: z.string().nullish().transform(nullToUndefined),
  special_instructions: z.string().nullish().transform(nullToUndefined),
  summary: z.string().nullish().transform(nullToUndefined),
});

export type TicketEditResponse = z.infer<typeof TicketEditResponseSchema>;

export const PENDING_TECH_ASSIGNMENT = 'Pending tech assignment';
export const TECH_ASSIGNED = 'Tech assigned';
export const TECH_CHECKED_IN = 'Tech checked in';
export const COMPLETE = 'Complete';
export const CANCELLED = 'Cancelled';
export const PENDING_CUSTOMER = 'Pending customer';
export const ON_HOLD = 'On hold';

export const RecentlyCompletedTicketResponseSchema = z.object({
  address_1: z.string(),
  address_2: z.string().optional(),
  city: z.string(),
  company_id: z.number(),
  date_resolved: z.coerce.date(),
  id: z.number(),
  site_name: z.string().optional(),
  state_identifier: z.string(),
  status_name: z.string(),
  sub_type: z.string(),
  type_name: z.string(),
  zip: z.string(),
});

export type RecentlyCompletedTicketResponse = z.infer<
  typeof RecentlyCompletedTicketResponseSchema
>;
export type RecentlyCompletedTicketsResponse = z.infer<
  typeof RecentlyCompletedTicketResponseSchema
>[];

export const TicketAttachmentMetadataResponseSchema = z.object({
  id: z.number().optional(),
  filename: z.string(),
});

export type TicketAttachmentsMetadataResponse = z.infer<
  typeof TicketAttachmentMetadataResponseSchema
>;

export type TicketUpdatesResponse = {
  date_entered: string;
  old_status: string;
  new_status: string;
};

export const GetTicketFilterSearchParamsSchema = z
  .object({
    site: z.number(),
    scheduled_date: z.string().datetime(),
    status: z.string(),
    priority: z.string(),
    date_range_start: z.string().datetime(),
    date_range_end: z.string().datetime(),
    date_order: z.string(),
    type: z.string(),
    page: z.number(),
    page_size: z.number(),
    search: z.string(),
  })
  .partial();

export type GetTicketFilterSearchParams = z.infer<
  typeof GetTicketFilterSearchParamsSchema
>;

export const TicketAPIResponseSchema =
  createPaginatedResponseSchema(TicketSchema);

export type TicketAPIResponse = z.infer<typeof TicketAPIResponseSchema>;

const DraftSchema = z.object({
  step: z.number(),
  state: z.string(),
  getStartedQuestionnaire: GetStartedQuestionnaireFormSchema,
  ticketDetailForm: TicketDetailFormSchemas,
  reviewForm: ReviewTicketFormSchema,
});

const DraftItemResponseSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  user_id: z.string(),
  organization_id: z.string(),
  data: DraftSchema,
});

export type Draft = z.infer<typeof DraftItemResponseSchema>;

export const DraftAPIResponseSchema = createPaginatedResponseSchema(
  DraftItemResponseSchema,
);

export type DraftAPIResponse = z.infer<typeof DraftAPIResponseSchema>;

export const GetDraftFilterSearchParamsSchema = z
  .object({
    date_range_start: z.string().datetime(),
    date_range_end: z.string().datetime(),
    date_order: z.string(),
    type: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type GetDraftFilterSearchParams = z.infer<
  typeof GetDraftFilterSearchParamsSchema
>;

export const DraftAPIResponseOptionalSchema = makeSchemaOptional(
  DraftAPIResponseSchema,
);

export const GetTemplatesFilterSearchParamsSchema = z
  .object({
    date_order: z.string(),
    search: z.string(),
    type: z.string(),
    page: z.number(),
    page_size: z.number(),
  })
  .partial();

export type GetTemplatesFilterSearchParams = z.infer<
  typeof GetDraftFilterSearchParamsSchema
>;

const TemplateSchema = z.object({
  getStartedQuestionnaire: GetStartedQuestionnaireBaseFormSchema,
  ticketDetailForm: TicketDetailFormSchemas,
  reviewForm: ReviewTemplateFormSchema,
});

const TemplateItemSchema = z.object({
  id: z.string(),
  user_id: z.string(),
  organization_id: z.string(),
  data: TemplateSchema,
  name: z.string(),
  description: z.string(),
  created_by: z.string(),
  used_count: z.number(),
  created_at: z.string(),
  updated_at: z.string(),
  version: z.number(),
});

export type Template = z.infer<typeof TemplateItemSchema>;

export const TemplateAPIResponseSchema =
  createPaginatedResponseSchema(TemplateItemSchema);

export type TemplateAPIResponse = z.infer<typeof TemplateAPIResponseSchema>;

export const TemplateAPIResponseOptionalSchema = makeSchemaOptional(
  TemplateAPIResponseSchema,
);

export const TemplateMetricsSchema = z.object({
  template_usage_percentage: z.number(),
});

export type TemplateMetrics = z.infer<typeof TemplateMetricsSchema>;

export const VALID_TYPES_BOARD_MAPPING = {
  'Circuit Extension': [
    { board: 'Field Services - MAC', readonly: false },
    { board: 'Field Services - Repair', readonly: false },
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'Equipment Install': [{ board: 'Field Services - MAC', readonly: false }],
  Move: [{ board: 'Field Services - MAC', readonly: false }],
  'POTS Extension': [{ board: 'Field Services - MAC', readonly: false }],
  'Smart Hands': [{ board: 'Field Services - MAC', readonly: false }],
  'Site Audit': [
    { board: 'Field Services - MAC', readonly: false },
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'Customer Equipment': [{ board: 'Field Services - Repair', readonly: false }],
  'Granite Equipment': [{ board: 'Field Services - Repair', readonly: false }],
  'Broadband Circuit': [{ board: 'Field Services - Repair', readonly: false }],
  'Fiber Extension': [{ board: 'Field Services - Repair', readonly: false }],
  POTS: [{ board: 'Field Services - Repair', readonly: false }],
  'Survey and Mount': [{ board: 'Field Services - EPIK', readonly: true }],
  SMCT: [{ board: 'Field Services - EPIK', readonly: true }],
  'Cutover and Test': [{ board: 'Field Services - EPIK', readonly: true }],
  Repair: [
    { board: 'Field Services - EPIK', readonly: true },
    { board: 'Field Services - GRID', readonly: true },
  ],
  'Activation Repairs': [{ board: 'Field Services - EPIK', readonly: true }],
  Decomm: [{ board: 'Field Services - EPIK', readonly: true }],
  Replacement: [{ board: 'Field Services - EPIK', readonly: true }],
  MAC: [
    { board: 'Field Services - EPIK', readonly: true },
    { board: 'Field Services - GRID', readonly: true },
  ],
  Survey: [{ board: 'Field Services - EPIK', readonly: true }],
  'Hosted Voice(ePOTS)': [
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'Extension Survey': [
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  Cradlepoint: [{ board: 'Field Services - Granite Network', readonly: true }],
  HPBX: [{ board: 'Field Services - Granite Network', readonly: true }],
  'LEC Prep Install': [
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'LEC Prep Survey': [
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'Switch Install': [
    { board: 'Field Services - Granite Network', readonly: true },
  ],
  'Turn Up': [{ board: 'Field Services - Granite Network', readonly: true }],
  'GRID Tail - Expansion': [{ board: 'Field Services - GRID', readonly: true }],
  'GRID Tail - Proper': [{ board: 'Field Services - GRID', readonly: true }],
  Billing: [{ board: 'Field Services - GRID', readonly: true }],
  GRID: [
    { board: 'Field Services - GRID', readonly: true },
    { board: 'Field Services - Repair', readonly: true },
  ],
  'Facilities Port': [{ board: 'Field Services - GRID', readonly: true }],
  'Facilities Audit': [{ board: 'Field Services - GRID', readonly: true }],
  'DIA Circuit': [{ board: 'Field Services - Repair', readonly: true }],
  Dispatch: [{ board: 'Field Services - Rollout', readonly: true }],
};
