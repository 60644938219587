import { useQuery } from 'react-query';
import {
  getNotificationSettings,
  getNotificationPreferences,
} from 'api/notifications/api';
import { useMemo, useState } from 'react';
import { Pillar } from 'components/Notifications/utils';
import {
  NotificationPreferenceForm,
  NotificationSetting,
} from 'api/notifications/schema';
import { useFeatureFlags } from 'feature-flags';
import { useAuth0User } from 'hooks/useAuth0User';
import { getAllowedPillarByCategory } from '../utils';

type Category = NotificationSetting['event_group']['event_category'];
type Group = NotificationSetting['event_group'];

export const useNotificationPreferences = () => {
  const { flags } = useFeatureFlags();
  const { roles: userRoles } = useAuth0User();
  const allowedPillarByCategory = useMemo(
    () => getAllowedPillarByCategory(userRoles),
    [userRoles],
  );
  const [activePillar, setActivePillar] = useState<Exclude<Pillar, 'all'>>(
    allowedPillarByCategory['Workspace'].find(({ name }) => name !== 'all')!
      .name as Exclude<Pillar, 'all'>,
  );
  const [activeTab, setActiveTab] = useState('In-app');
  const tabs = useMemo(() => {
    const _tabs = [
      {
        title: 'In-app',
        active: true,
        onClick: () => setActiveTab('In-app'),
      },
    ];
    if (flags.NOTIFICATIONS_EMAIL_ENABLED)
      _tabs.push({
        title: 'Email',
        active: false,
        onClick: () => setActiveTab('Email'),
      });
    if (flags.NOTIFICATIONS_SMS_ENABLED)
      _tabs.push({
        title: 'Push (SMS)',
        active: false,
        onClick: () => setActiveTab('Push (SMS)'),
      });
    return _tabs;
  }, [flags.NOTIFICATIONS_EMAIL_ENABLED, flags.NOTIFICATIONS_SMS_ENABLED]);

  const { data: notificationPreferences, isLoading: isLoadingPreferences } =
    useQuery(['notification-preferences', activePillar], () =>
      getNotificationPreferences(activePillar),
    );

  const { data: notificationSettings, isLoading: isLoadingSettings } = useQuery(
    ['notification-settings'],
    getNotificationSettings,
  );

  const pillarNotificationSettings = useMemo(
    () =>
      notificationSettings
        ?.filter(({ event_group }) => event_group.pillar === activePillar)
        .toSorted((a, b) => {
          if (a.event_group.event_category_id < b.event_group.event_category_id)
            return -1;
          if (a.event_group.event_category_id > b.event_group.event_category_id)
            return 1;
          return a.event_group_id - b.event_group_id;
        }) ?? [],
    [notificationSettings, activePillar],
  );

  const eventCategories = useMemo(
    () =>
      pillarNotificationSettings.reduce<Record<number, Category>>(
        (acc, setting) => {
          acc[setting.event_group.event_category_id] =
            setting.event_group.event_category;
          return acc;
        },
        {},
      ),
    [pillarNotificationSettings],
  );

  const eventGroups = useMemo(
    () =>
      pillarNotificationSettings.reduce<Record<number, Group>>(
        (acc, setting) => {
          acc[setting.event_group_id] = setting.event_group;
          return acc;
        },
        {},
      ),
    [pillarNotificationSettings],
  );

  const defaultPillarPreferences = useMemo(() => {
    return Object.values(eventGroups).reduce<
      NotificationPreferenceForm['preferences']
    >((acc, group, index) => {
      const preference: NotificationPreferenceForm['preferences'][number] = {
        event_group_id: group.id,
        in_app: group.config.default_settings.in_app,
        index,
      };
      if (group.config.event_types_filterable) {
        preference.filter_event_type = true;
        const event_ids = pillarNotificationSettings
          .filter(({ event_group_id }) => event_group_id === group.id)
          .map(({ id }) => id);
        preference.event_type_ids = {};
        event_ids.forEach((id) => {
          preference.event_type_ids[id] = {
            value: group.config.default_settings.event_types?.includes(id),
          };
        });
      }
      if (group.config.user_created_only_enabled) {
        preference.user_created_only =
          group.config.default_settings.user_created_only;
      }
      acc.push(preference);
      return acc;
    }, []);
  }, [pillarNotificationSettings, eventGroups]);

  const userPreferences = useMemo(() => {
    if (!notificationPreferences) return defaultPillarPreferences;
    return defaultPillarPreferences.map((preference) => {
      if (!preference.filter_event_type) {
        const existingPreference = notificationPreferences.find(
          ({ event_group_id }) => event_group_id === preference.event_group_id,
        );
        if (existingPreference) {
          return {
            ...preference,
            user_created_only:
              existingPreference.user_created_only ??
              preference.user_created_only,
            preference_id: existingPreference.id,
            in_app: existingPreference.in_app,
          };
        }
      } else {
        const existingPreferences = notificationPreferences.filter(
          ({ event_group_id }) => event_group_id === preference.event_group_id,
        );
        if (
          existingPreferences.length === 1 &&
          existingPreferences[0].event_type_id === null
        ) {
          const event_type_ids = { ...preference.event_type_ids };
          Object.keys(event_type_ids).forEach(
            (key) => (event_type_ids[key].value = true),
          );
          return {
            ...preference,
            user_created_only:
              existingPreferences[0].user_created_only ??
              preference.user_created_only,
            event_type_ids,
            filter_event_type: false,
            preference_id: existingPreferences[0].id,
            in_app: existingPreferences[0].in_app,
          };
        }
        if (existingPreferences.length > 0) {
          const event_type_ids = { ...preference.event_type_ids };
          existingPreferences.forEach(({ event_type_id, in_app, id }) => {
            event_type_ids[event_type_id as number] = {
              preference_id: id,
              value: in_app,
            };
          });
          return {
            ...preference,
            // user_created_only value is the same for all event_types for the event_group
            user_created_only:
              existingPreferences[0].user_created_only ??
              preference.user_created_only,
            filter_event_type: true,
            event_type_ids,
          };
        }
      }
      return preference;
    }) as NotificationPreferenceForm['preferences'];
  }, [defaultPillarPreferences, notificationPreferences]);

  return {
    allowedPillarByCategory,
    activePillar,
    setActivePillar,
    activeTab,
    tabs,
    notificationPreferences,
    isLoadingPreferences,
    notificationSettings,
    isLoadingSettings,
    pillarNotificationSettings,
    eventCategories,
    eventGroups,
    defaultPillarPreferences,
    userPreferences,
  };
};
