import { useState } from 'react';
import { useQuery } from 'react-query';
import { downloadCSVAsExcel } from 'shared/util/downloadCSVAsExcel';

const useDownloadConfigurations = ({
  fetchFn,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFn: () => Promise<any>;
}) => {
  const [downloadList, setDownloadList] = useState(false);

  const { isLoading } = useQuery(['download-list'], () => fetchFn(), {
    enabled: downloadList,
    retry: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onSettled: () => {
      setDownloadList(false);
    },
    onSuccess: (data) => {
      downloadCSVAsExcel(data, 'site_list');
    },
  });

  const onDownloadHandler = () => {
    setDownloadList(true);
  };

  return {
    isLoading,
    onDownloadHandler,
  };
};

export default useDownloadConfigurations;
