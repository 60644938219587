import { apiClient } from 'api/apiClient';

export const getCompanies = async (user_id: string | undefined) => {
  const response = await apiClient.get(`/api/v1/users/${user_id}/companies`);

  return response.data;
};

export const getCompanyAdmins = async () => {
  const response = await apiClient.get(`/api/v1/users/admin-contacts`);

  return response.data;
};

export const getSites = async () => {
  const response = await apiClient.get('/api/v1/companies/sites/download/');
  return response.data;
};
