import React from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PageTitle from 'components/PageTitle';
import { zodResolver } from '@hookform/resolvers/zod';
import { GraniteInput } from '../../components/V2/Input/GraniteInput';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { BadgeRole } from 'components/V2/BadgeRole/BadgeRole';
import { useAuth0User } from 'hooks/useAuth0User';
import { GraniteSelect } from 'components/Select/Select';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getProfileUser, updateUserProfile } from 'api/users/api';
import PhoneNumberInput from 'components/V2/Input/PhoneNumber';
import {
  UserProfileForm,
  UserProfileFormSchema,
  UserProfileRequest,
  UserProfileResponse,
} from 'api/users/schemas/UserProfile';
import showToast from 'components/Toast/Toast';
import { LoaderGranite } from 'components';
import { editProfileFormToRequest } from './utils';
import { UserRolePermissionFeatureSwitches } from 'components/UserPermissionFeatureSwitches/UserRolePermissionFeatureSwitches';
import Divider from 'components/Divider';
import DeleteAccount from 'screens/InviteMember/DeleteAccount';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { TokenUserRoles } from 'api/users/schemas/Users';
import { useChangePasswordMutation } from 'hooks/useChangePasswordMutation';
import { Key } from 'react-ionicons';

interface UnChangedDataType {
  email: string;
  companyName: string;
  roles: string[];
  sub?: string;
}

function UserProfileEditor({
  data,
  unChangedData,
  logout,
}: {
  data: UserProfileResponse;
  unChangedData: UnChangedDataType;
  logout: (options?: LogoutOptions | undefined) => Promise<void>;
}) {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserProfileForm>({
    resolver: zodResolver(UserProfileFormSchema),
    defaultValues: {
      ...data,
      role: {
        value: data.role,
        label: data.role,
      },
    },
  });
  const navigate = useNavigate();
  const breadcrumbs = [
    {
      icon: 'home',
      label: 'User management',
      onClick: () => navigate('/users'),
    },
  ];
  const createUserMutation = useMutation(
    (data: UserProfileRequest) => updateUserProfile(data),
    {
      onSuccess: () => {
        showToast.confirmation({
          message: 'Your profile has been successfully updated!',
        });
        queryClient.refetchQueries(['user-profile', 'my-profile']);
        navigate('/profile');
      },
      onError(error) {
        console.log(error, 'error');
      },
    },
  );

  const onSubmit = (data: UserProfileForm) => {
    createUserMutation.mutate(editProfileFormToRequest(data));
  };
  const onError = (err: unknown) => {
    console.log(err);
  };

  const dateAdded = data?.invited_at || data?.created_at;
  const changePasswordMutation = useChangePasswordMutation();

  return (
    <div className="invite-user mx-auto flex w-full max-w-[1376px] flex-col items-start justify-start">
      <div className="mb-12 flex items-end justify-start gap-6">
        <PageTitle title={data.name} breadcrumbs={breadcrumbs} />
        <div className="pb-[10px]">
          {unChangedData?.roles.map((role) => (
            <BadgeRole
              key={role}
              role={role}
              classNames="text-base py-1.5 h-[36px] px-3"
            />
          ))}
        </div>
      </div>
      <div className="flex w-full flex-col items-start justify-start gap-6">
        <div className="flex-justify-between col-span-12 flex w-full items-center rounded bg-background-base-surface-2 px-6 py-4">
          <div className="flex w-full items-center justify-start gap-3">
            <span className="h-4 w-4 rounded-full bg-ticket-status-techcheckedin"></span>
            <h2 className="text-xl font-bold text-content-base-default">
              Active
            </h2>
          </div>
          <div className="flex w-full flex-col items-end justify-start">
            <p className="text-base font-bold text-content-base-subdued">
              Date Added
            </p>
            <h2 className="text-base font-bold text-content-base-default">
              {dateAdded ? format(new Date(dateAdded), 'MM/dd/yyyy') : ' -- '}
            </h2>
          </div>
        </div>
        <form
          id="edit-profile-form"
          onSubmit={handleSubmit(onSubmit, onError)}
          className="w-full"
        >
          <div className="grid h-full w-full grid-cols-12 overflow-hidden rounded">
            <div className="col-span-4 flex h-full w-full flex-col items-start justify-start gap-4 bg-background-base-surface-3 px-8 py-6">
              <h2 className="text-2xl font-bold text-content-base-default">
                User details
              </h2>
              <div className="flex w-full flex-col items-start justify-start gap-6">
                <div className="w-full">
                  <GraniteInput
                    label="Name"
                    placeholder="Name"
                    {...register('name')}
                    error={errors.name?.message}
                  />
                </div>
                <div className="w-full">
                  <GraniteInput
                    value={unChangedData.email}
                    label="Email"
                    disabled={true}
                    placeholder="Email"
                  />
                </div>
                <div className="w-full">
                  <GraniteInput
                    label="Secondary email (optional)"
                    placeholder="Secondary email"
                    {...register('secondary_email')}
                    defaultValue={data?.secondary_email as string}
                    error={errors.secondary_email?.message}
                  />
                </div>

                <div className="w-full">
                  <GraniteInput
                    label="Company title (optional)"
                    placeholder="Company title"
                    {...register('company_title')}
                    error={errors.company_title?.message}
                  />
                </div>
                <div className="w-full">
                  <GraniteInput
                    label="Department (optional)"
                    placeholder="Department"
                    {...register('department')}
                    error={errors.department?.message}
                  />
                </div>
                <div className="w-full">
                  <PhoneNumberInput
                    name="phone_number"
                    label="Phone number"
                    placeholder="(555)-555-555"
                    control={control}
                    error={errors.phone_number?.message}
                  />
                </div>
                <div className="w-full">
                  <PhoneNumberInput
                    name="secondary_phone_number"
                    label="Secondary phone number (optional)"
                    placeholder="(555)-555-555"
                    control={control}
                    error={errors.secondary_phone_number?.message}
                  />
                </div>
                <div className="w-full">
                  <GraniteSelect
                    isDisabled={true}
                    label="Company"
                    value={{
                      value: unChangedData.companyName,
                      label: unChangedData.companyName,
                    }}
                    onChange={() => {
                      return;
                    }}
                  />
                </div>
              </div>
              <GraniteButton
                variant="secondary"
                size="large"
                className="mt-10 box-border w-full"
                onClick={() =>
                  changePasswordMutation.mutate(unChangedData.email)
                }
              >
                Reset password <Key />
              </GraniteButton>
            </div>
            <div className="col-span-8 flex h-full w-full flex-col gap-16 rounded-r bg-background-base-surface-2 px-8 py-6">
              <UserRolePermissionFeatureSwitches
                control={control}
                isProfile={true}
              />
              <Divider />
              <div className="mt-auto">
                <DeleteAccount
                  isDisabled={unChangedData.roles.some((role) =>
                    [
                      TokenUserRoles.SUPER_ADMIN,
                      TokenUserRoles.COMPANY_ADMIN,
                    ].includes(role as TokenUserRoles),
                  )}
                  isLoggedInUser
                  userId={unChangedData.sub ?? ''}
                  onSuccessHandler={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                />
                <Divider className="mt-16" />
              </div>
              <div className="flex gap-4">
                <GraniteButton
                  variant="secondary"
                  size="large"
                  onClick={() => navigate('/profile')}
                >
                  Cancel
                </GraniteButton>
                <GraniteButton
                  variant="primary"
                  size="large"
                  type="submit"
                  form="edit-profile-form"
                >
                  Save changes
                </GraniteButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const EditProfile: React.FC = () => {
  const { email, roles, companyName, sub } = useAuth0User();
  const { logout } = useAuth0();
  const { data, isLoading } = useQuery(
    ['user-profile', 'my-profile'],
    () => getProfileUser(),
    { staleTime: 0 },
  );

  return (
    <div className="w-full p-8">
      {!isLoading && data ? (
        <UserProfileEditor
          data={data}
          unChangedData={{ email: email as string, roles, companyName, sub }}
          logout={logout}
        />
      ) : (
        <LoaderGranite />
      )}
    </div>
  );
};

export default EditProfile;
