import {
  CreateQuoteRequest,
  CreateQuoteResponse,
  QEQuoteAPIResponse,
  QEQuoteAPIResponseSchema,
  QuoteLocationsResponseSchema,
  SubmitOrderPayload,
} from './schema';
import { apiClient } from '../apiClient';
import {
  AccessStaticDataType,
  QuoteIndexAPIResponse,
  QuoteIndexAPIResponseSchema,
  QuoteIndexFilterSearchParams,
  QuoteIndexFilterSearchParamsSchema,
} from './schemas';
import {
  FormalPricingResponse,
  FormalPricingSchema,
} from 'screens/SelectServices/schemas';

export const getQuote = async (
  quoteId: string,
): Promise<QEQuoteAPIResponse> => {
  const response = await apiClient.get(`/api/v1/quotes/${quoteId}`);
  const normalizedResult = Object.fromEntries(
    Object.entries(response.data['quote_results'] ?? {}).map(
      ([locationId, location]) => {
        const { metadata, ...productMatches } = location as {
          metadata: unknown;
        };
        return [locationId, { metadata, productMatches }];
      },
    ),
  );
  const normalizedResponse = {
    ...response.data,
    quote_results: normalizedResult,
  };

  return QEQuoteAPIResponseSchema.parse(normalizedResponse);
};

export const getQuoteFormalPricing = async (
  quoteId: string,
): Promise<FormalPricingResponse> => {
  const response = await apiClient.get(
    `/api/v1/quotes/${quoteId}/formalPricing`,
  );

  return FormalPricingSchema.parse(response.data);
};

export const submitQuoteOrder = async (
  quoteId: string,
  body: SubmitOrderPayload,
): Promise<FormalPricingResponse> => {
  const response = await apiClient.post(
    `/api/v1/quotes/${quoteId}/submitOrder`,
    body,
  );

  return response.data;
};

export const createQuoteRequest = async (
  quote: CreateQuoteRequest,
): Promise<CreateQuoteResponse> => {
  const response = await apiClient.post<CreateQuoteResponse>(
    '/api/v1/quotes/',
    quote,
  );

  return CreateQuoteResponse.parse(response.data);
};

export const getQuoteIndex = async (
  params: QuoteIndexFilterSearchParams = {},
): Promise<QuoteIndexAPIResponse> => {
  const queryParams = QuoteIndexFilterSearchParamsSchema.parse(params);

  const response = await apiClient.get('/api/v1/quotes/', {
    params: queryParams,
  });
  return QuoteIndexAPIResponseSchema.parse(response.data);
};

export const getQuoteLocations = async (search: string) => {
  const response = await apiClient.get(
    `/api/v1/quotes/locations?search=${search}`,
  );
  return QuoteLocationsResponseSchema.parse(response.data);
};

export const fetchAccessStatisData =
  async (): Promise<AccessStaticDataType> => {
    const response = await apiClient.get(`/api/v1/quotes/static`);
    return response.data;
  };

export const uploadFormalQuote = async (request: FormData) => {
  const response = await apiClient.post(
    `/api/v1/quotes/uploadFormalQuote`,
    request,
    {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const requestQuote = async (
  payload: unknown,
): Promise<{ success: boolean }> => {
  console.log({ payload });
  return new Promise((resolve) => {
    resolve({
      success: true,
    });
  });
};
