import {
  CloseButtonProps,
  ToastContainer as ReactToastify,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CloseCircleSharp } from 'react-ionicons';

const ToastContainer = () => {
  return (
    <ReactToastify
      position="top-right"
      toastClassName={() =>
        'bg-background-base-surface-0 min-w-[280px] flex p-4 rounded-lg justify-between'
      }
      bodyClassName={() => 'text-sm font-white font-med grow'}
      autoClose={5000}
      hideProgressBar={true}
      icon={false}
      closeOnClick={false}
      closeButton={(props: CloseButtonProps) => (
        <div className="cursor-pointer self-center" onClick={props.closeToast}>
          <CloseCircleSharp height={'24px'} width={'24px'} color={'#F8FAFC'} />
        </div>
      )}
    />
  );
};

export default ToastContainer;
