import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useNavigate,
} from 'react-router-dom';
import Home from 'screens/Home';
import { Login } from './screens/Login/Login';
import Missing from './screens/Missing/Missing';
import { ProtectedDefaultLayout } from './layouts/DefaultLayout/ProtectedDefaultLayout';
import TechExpress from 'screens/TechExpress/TechExpress';
import { TicketDetail } from './screens/TicketDetail/TicketDetail';
import UserManagement from 'screens/UserManagement';
import UserDetails from 'screens/UserDetails';
import { LetUsHelpRoutes } from './screens/LetUsHelp/WizardProvider/LetUsHelpWizard';
import { useAuth0User } from 'hooks/useAuth0User';
import EditProfile from 'screens/UserProfile/EditProfile';
import { Auth0ProviderWithNavigate } from './components/Auth0ProviderWithNavigate/Auth0ProviderWithNavigate';
import { AxiosInterceptor } from './components/AxiosInterceptor/AxiosInterceptor';
import { InviteUser } from './screens/InviteMember/InviteUser';
import { OpenQuoteRoutes } from './screens/OpenQuote/Wizard/OpenQuoteWizard';
import { G360Pillars, TokenUserRoles } from './api/users/schemas/Users';
import { EditUser } from './screens/InviteMember/EditUser';
import Companies from 'screens/Companies';
import { NOCExpress } from 'screens/NOCExpress/NOCExpress';
import SignupReceived from 'screens/SignupReceived/SignupReceived';
import { SessionExpired } from 'screens/SessionExpired';
import { SignUp } from 'screens/SignUp';
import NotVerified from 'screens/NotVerified/NotVerified';
import { OpenNetOpsTicketRoutes } from 'screens/OpenNetOpsTicket/WizardProvider/OpenNOCTicketWizard';
import { TicketDetails } from 'screens/NOCExpress/TicketDetails/TicketDetails';
import { NotificationsPage } from 'screens/NotificationsPage/NotificationsPage';
import { NotificationPreferencesPage } from 'screens/NotificationsPage/NotificationPreferences/NotificationPreferencesPage';
import Components from 'screens/Components';
import Mobility from 'screens/Mobility/Mobility';
import { Templates } from 'screens/TechExpress/Templates';
import { EditTemplateRoutes } from 'screens/TechExpress/Templates/WizardProvider/EditTemplateWizard';
import { TemplatePage } from 'screens/TechExpress/Templates/TemplatePage';
import Resources from 'screens/Resources';
import { FEATURE_FLAG, useFeatureFlags } from 'feature-flags';
import UploadQRCode from 'screens/OpenQuote/UploadQRCode/UploadQRCode';
import AccessExpress from 'screens/AccessExpress/AccessExpress';

const IS_UAT = process.env.REACT_APP_CURRENT_ENV === 'uat';
const JIRA_COLLECTOR_URL = process.env.REACT_APP_JIRA_COLLECTOR_PLUGIN_URL;

interface RoleProtectedRouteProps {
  roles: TokenUserRoles[];
}

const RoleProtectedRoute = ({ roles }: RoleProtectedRouteProps) => {
  const { roles: userRoles } = useAuth0User();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userRoles.some((role) => roles.includes(role))) {
      navigate('/');
    }
  }, [roles, navigate, userRoles]);

  if (userRoles.some((role) => roles.includes(role))) {
    return <Outlet />;
  }
};

interface PillarProtectedRouteProps {
  pillar: G360Pillars;
}

const PillarProtectedRoute = ({ pillar }: PillarProtectedRouteProps) => {
  const { pillars: userPillars, roles } = useAuth0User();
  const isSuperAdmin = roles.includes(TokenUserRoles.SUPER_ADMIN);

  if (!userPillars.has(pillar) && !isSuperAdmin) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

interface FlagProtectedRouteProps {
  flag: FEATURE_FLAG;
}

const FlagProtectedRoute = ({ flag }: FlagProtectedRouteProps) => {
  const { flags } = useFeatureFlags();
  if (!flags?.[flag]) return <Navigate to="/" />;
  return <Outlet />;
};

/**
 * RouterMiddleware is a wrapper component of context providers required in the
 * application that require being under the router context.
 *
 * This function wraps the context providers so that we don't have to nest them
 * in the app routes more than once.
 */
const RouterMiddleware = () => {
  return (
    <Auth0ProviderWithNavigate>
      <AxiosInterceptor>
        <ScrollRestoration />
        <Outlet />
      </AxiosInterceptor>
    </Auth0ProviderWithNavigate>
  );
};

export const applicationRouter = [
  { path: '/', element: <Home /> },
  ...LetUsHelpRoutes,
  {
    path: 'tech-express',
    element: (
      <RoleProtectedRoute
        roles={Object.values(TokenUserRoles).filter(
          (role) => role !== TokenUserRoles.QR_ADMIN,
        )}
      />
    ),
    children: [
      { index: true, element: <TechExpress /> },
      {
        path: 'templates',
        element: (
          <RoleProtectedRoute
            roles={[TokenUserRoles.SUPER_ADMIN, TokenUserRoles.COMPANY_ADMIN]}
          />
        ),
        children: [
          { index: true, element: <Templates /> },
          ...EditTemplateRoutes,
          {
            path: ':templateId',
            children: [
              { index: true, element: <TemplatePage /> },
              ...EditTemplateRoutes,
            ],
          },
        ],
      },
      { path: ':ticketId', element: <TicketDetail /> },
    ],
  },
  {
    path: 'access-express',
    element: <PillarProtectedRoute pillar={G360Pillars.AccessExpress} />,
    children: [{ index: true, element: <AccessExpress /> }, ...OpenQuoteRoutes],
  },
  { path: 'profile', element: <UserDetails /> },
  { path: 'profile/edit', element: <EditProfile /> },
  {
    path: '/users',
    element: (
      <RoleProtectedRoute
        roles={[TokenUserRoles.SUPER_ADMIN, TokenUserRoles.COMPANY_ADMIN]}
      />
    ),
    children: [
      { path: '', element: <UserManagement /> },
      { path: 'invite-user', element: <InviteUser /> },
    ],
  },
  {
    path: '/companies',
    element: <RoleProtectedRoute roles={[TokenUserRoles.SUPER_ADMIN]} />,
    children: [{ path: '', element: <Companies /> }],
  },
  { path: 'users/edit/:id', element: <EditUser /> },
  { path: 'components', element: <Components /> },
  {
    path: 'notifications',
    children: [
      { index: true, element: <NotificationsPage /> },
      { path: 'preferences', element: <NotificationPreferencesPage /> },
    ],
  },
  { path: 'users/:id', element: <UserDetails /> },
  ...OpenNetOpsTicketRoutes,
  {
    path: 'noc-express',
    element: (
      <RoleProtectedRoute
        roles={[
          TokenUserRoles.SUPER_ADMIN,
          TokenUserRoles.COMPANY_ADMIN,
          TokenUserRoles.USER,
          TokenUserRoles.VIEWER,
        ]}
      />
    ),
    children: [
      { index: true, element: <NOCExpress /> },
      { path: ':id', element: <TicketDetails /> },
    ],
  },
  {
    path: 'resources',
    element: <FlagProtectedRoute flag="RESOURCES_ENABLED" />,
    children: [{ index: true, element: <Resources /> }],
  },
  { path: '*', element: <Missing /> },
];

const protectedApplicationRouter = createBrowserRouter([
  {
    element: <RouterMiddleware />,
    children: [
      {
        children: [
          { path: '/login', element: <Login /> },
          { path: '/signup-received', element: <SignupReceived /> },
          { path: '/not-verified', element: <NotVerified /> },
        ],
      },
      {
        element: <ProtectedDefaultLayout />,
        children: applicationRouter,
      },
    ],
  },
  { path: '/session-expired', element: <SessionExpired /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/mobility', element: <Mobility /> },
  {
    element: <RouterMiddleware />,
    children: [
      {
        children: [
          {
            path: '/upload-qr',
            element: <UploadQRCode />,
          },
        ],
      },
      {
        element: <ProtectedDefaultLayout />,
        children: applicationRouter,
      },
    ],
  },
]);

const AppRouter: React.FC = () => {
  useEffect(() => {
    if (!IS_UAT || !JIRA_COLLECTOR_URL) return;
    const script = document.createElement('script');
    script.src = JIRA_COLLECTOR_URL;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <RouterProvider router={protectedApplicationRouter} />;
};

export default AppRouter;
