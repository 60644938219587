import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeepPartial } from 'react-hook-form';
import { useQuery } from 'react-query';

import { OpenQuoteLayout } from '../BaseComponents/OpenQuoteLayout';
import LocationSection from '../BaseComponents/LocationSection';
import { PricingTier } from 'api/accessexpress/schema';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { useModal } from 'hooks/useModal';
import { RequestQuoteModal } from '../BaseComponents/RequestQuoteModal';
import { useOpenQuoteContext } from '../Wizard/OpenQuoteWizardReducer';
import { getQuoteBucketsRequest, selectBucketsRequest } from './utils';
import { WithLoader } from 'components/WithLoader/WithLoader';
import { SiteAddress } from 'api/addresssearch/schema';
import DiscardProgressModal from '../DiscardProgressModal/DiscardProgressModal';

export interface SelectedTierInfo {
  tier: DeepPartial<PricingTier> | null;
  sendMePricing: boolean;
}

const REFETCH_DELAYS = [1, 2, 5, 5, 10, 20, 30, 60, 120, 120];

export const SelectServiceBundle = () => {
  const { quoteId } = useParams();
  const { wizardStore } = useOpenQuoteContext();
  const navigate = useNavigate();
  const { open, ...discardModalProps } = useModal();

  const retryIndex = useRef(0);
  const { data, isLoading, refetch } = useQuery(
    ['access-express-quote-buckets', quoteId],
    () => getQuoteBucketsRequest(quoteId ?? ''),
    {
      enabled: !!quoteId,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      onSuccess: (data) => {
        if (
          data?.status !== 'Service results ready' &&
          retryIndex.current < REFETCH_DELAYS.length
        ) {
          const delay = REFETCH_DELAYS[retryIndex.current] * 1000;
          setTimeout(() => {
            refetch();
          }, delay);
          retryIndex.current++;
        }
      },
    },
  );

  const locationPricingTiers = data?.pricingTiers;

  useEffect(() => {
    wizardStore.setStep(3);
  }, [wizardStore]);

  const [selectedTiers, setSelectedTiers] = useState<{
    [key: string]: SelectedTierInfo;
  }>({});

  const { openWithProps, ...modalProps } = useModal<{
    [key: string]: SelectedTierInfo;
  }>();

  useEffect(() => {
    if (locationPricingTiers) {
      const initialSelectedTiers = locationPricingTiers.reduce(
        (acc, loc) => {
          const advancedTier = loc.pricing_tiers.find((tier) => tier.selected);
          acc[loc.id] = {
            tier: advancedTier ? { ...advancedTier } : null,
            sendMePricing: loc.sendMePricing,
          };
          return acc;
        },
        {} as { [key: string]: SelectedTierInfo },
      );
      setSelectedTiers(initialSelectedTiers);
    }
  }, [locationPricingTiers]);

  const handleChooseService = useCallback(() => {
    navigate('/access-express/open-quote/choose-services', {
      state: {
        addressValue: {
          address_line_1:
            locationPricingTiers?.[0].location.address_line_1 ?? '',
          address_line_2:
            locationPricingTiers?.[0].location.address_line_2 ?? '',
          city: locationPricingTiers?.[0].location.city ?? '',
          state: locationPricingTiers?.[0].location.state ?? '',
          zip: locationPricingTiers?.[0].location.zip ?? '',
        } as SiteAddress,
      },
    });
  }, [locationPricingTiers, navigate]);

  if (!locationPricingTiers) return null;

  const handleSelectTier = async (
    locationId: string,
    tier: DeepPartial<PricingTier>,
  ) => {
    setSelectedTiers((prev) => ({
      ...prev,
      [locationId]: { ...prev[locationId], tier },
    }));

    selectBucketsRequest({
      quote_id: tier.quote_id ?? '',
      product_id: tier.product_id ?? '',
      bucket_id: tier.bucket_id ?? '',
    });
  };

  const onRequestQuoteClick = () => {
    openWithProps(selectedTiers);
  };

  return (
    <OpenQuoteLayout>
      <WithLoader isLoading={isLoading}>
        <div className="flex flex-col gap-8 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex w-full flex-col items-start justify-start">
            <h2 className="mb-2 text-2xl font-bold text-content-base-default">
              Choose from the below service packages for each location
            </h2>
          </div>
          {locationPricingTiers.map((location) => {
            return (
              <LocationSection
                key={location.id}
                location={location}
                onSelectedTier={(tier) => handleSelectTier(location.id, tier!)}
                selectedTier={selectedTiers[location.id]?.tier}
                pricingAvailable={data.status === 'Service results ready'}
                locationPricingTiersLength={locationPricingTiers.length}
              />
            );
          })}
          <div className="flex flex-row">
            <GraniteButton variant="secondary" onClick={handleChooseService}>
              Manage locations & services
            </GraniteButton>
          </div>
        </div>
        <div className="sticky top-8 flex flex-col gap-6 rounded bg-background-base-surface-2 p-6 shadow">
          <div className="flex gap-4">
            <GraniteButton
              variant="secondary"
              size="large"
              className="w-full"
              onClick={open}
            >
              Cancel
            </GraniteButton>
            <GraniteButton
              size="large"
              className="w-full"
              onClick={onRequestQuoteClick}
              disabled={!Object.values(selectedTiers).some((tier) => tier.tier)}
            >
              Request quote
            </GraniteButton>
          </div>
        </div>
        <RequestQuoteModal
          {...modalProps}
          selectedTiers={selectedTiers}
          quoteName={data.quote_name}
        />
        <DiscardProgressModal {...discardModalProps} />
      </WithLoader>
    </OpenQuoteLayout>
  );
};
