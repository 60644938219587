import { styled } from 'styled-components';

// background: ${({ theme }) => theme.colors.background.row};

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    align-items: end;
    flex-direction: column !important;

    > div:first-child {
      display: none;
    }
  }
`;
