import {
  AccountInformationForm,
  ChildAccountRequest,
  FullServiceDetailsFormType,
} from './schemas';

export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'U.S. Virgin Islands', abbreviation: 'VI' },
];

export const childAccountFormToRequest = (
  data: FullServiceDetailsFormType & AccountInformationForm,
): ChildAccountRequest => {
  const sameAsAbove = data.billing_address;
  const payload = {
    local_contact_email: data.local_contact_email,
    parent_account: data.parent_account.value,
    parent_name: data.parent_account.subvalue,
    ship_country: 'USA',
    billing_type: data.billing_type.value,
    invoice_delivery_method: data.invoice_delivery_method.value,
    invoice_style: data.invoice_style.value,
    state: data.state.value,
    country: data.country.value,
    business_name: data.business_name,
    address_line_1: data.address_line_1,
    address_line_2: data.address_line_2,
    city: data.city,
    zip_code: data.zip_code,
    local_contact_name: data.local_contact_name,
    local_contact_phone_number: data.local_contact_phone_number,
    general_ledger: data.general_ledger,
    store_id: data.store_id,
    billing_business_name: sameAsAbove
      ? data.business_name
      : data.billing_business_name,
    billing_contact_name: sameAsAbove
      ? data.local_contact_name
      : data.billing_contact_name,
    billing_contact_phone_number: sameAsAbove
      ? data.local_contact_phone_number
      : data.billing_contact_phone_number,
    billing_address_line_1: sameAsAbove
      ? data.address_line_1
      : data.billing_address_line_1,
    billing_address_line_2: sameAsAbove
      ? data.address_line_2
      : data.billing_address_line_2,
    billing_city: sameAsAbove ? data.city : data.billing_city,
    billing_state: sameAsAbove ? data.state.value : data.billing_state?.value,
    billing_zip_code: sameAsAbove ? data.zip_code : data.billing_zip_code,
    billing_country: sameAsAbove
      ? data.country.value
      : data.billing_country?.value,
  };

  return payload;
};
