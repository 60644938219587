export const VALID_PHONE_ERROR_LABEL =
  'Please enter a valid 10 digit phone number';

export const VALID_NUMBERS_ERROR_LABEL = 'Enter valid numbers only';

export const LETTERS_SPACES_HYPENS_ERROR_LABEL =
  'Only letters, spaces, and hyphens allowed';

export const LETTERS_SPACES_SPECIAL_CHARACTERS_ERROR_LABEL =
  'Only letters, numbers, spaces, hyphens, bullets, and standard spacing allowed';

export const UP_TO_6_DIGITS_ERROR_LABEL = 'Up to 6 digits';

export const VALID_US_DATE_FORMAT = 'Enter a valid date in US format';

export const ALPHANUMERIC_REGEX_ERROR =
  'Enter alphanumeric characters, numbers, spaces, hyphens, and slashes only';
