import { Control, useController } from 'react-hook-form';
import { UserRole } from '../../api/users/schemas/UserRole';
import { GraniteSelect } from '../Select/Select';
import { userRolesEnumToSelectOptions } from '../HeaderNav/utils';

export type FormWithPermissions = {
  role: { value: UserRole; label: string };
};

export interface UserPermissionFeatureSwitchesProps<
  FormType extends FormWithPermissions,
> {
  control: FormType extends FormWithPermissions ? Control<FormType> : never;
  isProfile?: boolean;
}

export const UserRolePermissionFeatureSwitches = <
  FormType extends FormWithPermissions,
>({
  control,
  isProfile,
}: UserPermissionFeatureSwitchesProps<FormType>) => {
  const {
    field: { ref, ...role },
    fieldState: { error: roleErrors },
  } = useController({ control, name: 'role' });

  const roles = userRolesEnumToSelectOptions();

  return (
    <div className="flex w-full flex-col">
      <h2 className="mb-4 text-2xl font-semibold text-content-base-default">
        Role & permissions
      </h2>
      <GraniteSelect
        {...role}
        onChange={(newValue) => {
          role.onChange(newValue);
        }}
        label="Role"
        options={roles}
        error={roleErrors?.message}
        isDisabled={isProfile}
      />
      <p className="mb-8 mt-2 text-sm text-content-base-subdued">
        {role.value?.value === 'Viewer' &&
          'This user will be limited to read only permissions'}
        {role.value?.value === 'User' &&
          'This user can have read & write or read only permissions'}
        {role.value?.value === 'Company Admin' &&
          'This user will have full read & write permissions and the ability to manage users'}
      </p>
    </div>
  );
};
