import {
  UserProfileForm,
  UserProfileRequest,
} from 'api/users/schemas/UserProfile';

export const editProfileFormToRequest = (
  payload: UserProfileForm,
): UserProfileRequest => {
  return {
    name: payload.name,
    phone_number: payload.phone_number,
    secondary_phone_number: payload.secondary_phone_number || null,
    secondary_email: payload.secondary_email || null,
    department: payload.department || null,
    company_title: payload.company_title || null,
  };
};
