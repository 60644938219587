import { useState } from 'react';
import { DeepPartial } from 'react-hook-form';
import { CloseCircle } from 'react-ionicons';

import { PricingTier, PricingTierResponse } from 'api/accessexpress/schema';
import Loader from 'components/Loader';

import PackageCard from './PackageCard';
import DetailsSummary from './DetailsSummary';
import Header from './Header';
import LookingForMoreServiceOptions from './LookingForMoreServiceOptions';

interface LocationSectionProps {
  location: PricingTierResponse;
  onSelectedTier: (selectedTier: DeepPartial<PricingTier> | null) => void;
  selectedTier: DeepPartial<PricingTier> | null;
  pricingAvailable: boolean;
  isPreview?: boolean;
  locationPricingTiersLength?: number;
}

const LocationSection = ({
  location,
  onSelectedTier,
  selectedTier,
  pricingAvailable,
  isPreview = false,
  locationPricingTiersLength,
}: LocationSectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMoreOptionsSelected, setIsMoreOptionsSelected] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMoreOptionsSelect = () => {
    setIsMoreOptionsSelected((prev) => !prev);
  };

  const handleTierSelect = (tier: DeepPartial<PricingTier>) => {
    if (selectedTier?.name === tier.name) {
      onSelectedTier(null);
    } else {
      onSelectedTier(tier);
      if (location.pricing_tiers.length < 3) {
        setIsMoreOptionsSelected(false);
      }
    }
  };

  const renderPackageCards = () => {
    const { pricing_tiers } = location;
    const numMissingCards = 3 - pricing_tiers.length;

    if (!pricingAvailable) {
      return (
        <div className="flex-3 col-span-3" key="three-missing">
          <div className="flex h-full w-full flex-col gap-2 rounded bg-background-base-surface-3 p-4">
            <div className="flex items-center gap-2">
              <p className="text-xl font-bold text-content-base-default">
                Results loading
              </p>
              <Loader animationClassname="border-4 !border-t-4 w-6 h-6" />
            </div>
            <p className="text-base font-semibold text-content-base-subdued">
              We’re gathering the options available for this location now. This
              process may take a few minutes.
            </p>
          </div>
        </div>
      );
    }

    const packageCards = pricing_tiers.map((data) => {
      return (
        <PackageCard
          key={data.name}
          data={{ ...data, productType: location.product_type }}
          selected={selectedTier?.name === data.name}
          onSelect={handleTierSelect}
        />
      );
    });

    if (numMissingCards >= 1) {
      packageCards.push(
        <div
          className={`col-span-${numMissingCards} flex-${numMissingCards}`}
          key="more-options"
        >
          <LookingForMoreServiceOptions
            onSelect={handleMoreOptionsSelect}
            isSelected={isMoreOptionsSelected}
            isPreview={isPreview}
          />
        </div>,
      );
    }

    if (numMissingCards === 3 && !isMoreOptionsSelected) {
      return (
        <div className="flex-3 col-span-3" key="three-missing">
          <div className="flex h-full w-full flex-col gap-2 rounded bg-background-base-surface-3 p-4">
            <div className="flex items-center gap-2">
              <p className="text-xl font-bold text-content-base-default">
                Pricing options are not available at this time
              </p>
              <CloseCircle color="#FF315E" />
            </div>
            <p className="text-base font-semibold text-content-base-subdued">
              A representative from Granite will contact you via email with more
              information about the available options at this location.
            </p>
          </div>
        </div>
      );
    }

    return packageCards;
  };

  const hasPricingTiers = location.pricing_tiers.length > 0;
  return (
    <div className="gap-4 rounded bg-background-base-surface-1 p-4">
      <Header
        address={`${location.location.address_line_1}, ${location.location.city}, ${location.location.state} ${location.location.zip}`}
        productType={location.product_type}
        isCollapsed={isCollapsed}
        onToggleCollapse={toggleCollapse}
        hasPricingTiers={hasPricingTiers}
      />
      <div className="my-4 w-full">
        <div className="h-[1px] flex-shrink-0 border-solid border-opacity-[.5] bg-stroke-base-subdued"></div>
      </div>
      {!isCollapsed ? (
        <div className="mt-6">
          <div className="grid grid-cols-3 gap-6">{renderPackageCards()}</div>
        </div>
      ) : (
        hasPricingTiers &&
        selectedTier && (
          <div>
            <DetailsSummary
              recommendation={`Recommended (${selectedTier?.avg_bandwidth} Mb/s)`}
              term={`${selectedTier?.term ?? '3'} years`}
              ipType={`${
                selectedTier?.ip_type?.toLocaleLowerCase() === 'static'
                  ? 'Static'
                  : 'Dynamic'
              } IP, ${selectedTier.ip_blocks} IP block`}
              price={selectedTier?.avg_mrc ?? ''}
              isSimpleFlow={
                locationPricingTiersLength === 1 &&
                location.product_type === 'Broadband'
              }
            />
          </div>
        )
      )}
    </div>
  );
};

export default LocationSection;
