import Divider from 'components/Divider';
import NotificationsAvatar from './Avatar';
import Marker from './Marker';
import { formatDate } from './utils';
import { Notification } from 'api/notifications/schema';
import clsx from 'clsx';

type CardProps = {
  markAsRead?: (item: Notification, read: boolean) => void;
  notification: Notification;
  className?: string;
};

export const Card = ({
  markAsRead,
  notification,
  className = '',
}: CardProps) => {
  const { event, title, subtitle, actions, created_at, read } = notification;
  return (
    <div
      className={clsx(
        'flex w-full cursor-pointer gap-4 rounded bg-background_base_surface-3_subdued p-4 hover:bg-background-base-surface-3',
        className,
      )}
    >
      <NotificationsAvatar
        type={event.event_type.event_group.avatar_type}
        name={event.creator_name ?? ''}
      />
      <div className="flex w-full flex-col gap-2 text-sm text-content-base-default">
        <div className="flex items-center justify-between">
          <div className="flex flex-col justify-between gap-1">
            <p className="font-bold">{title}</p>
            <p className="font-semibold">{subtitle}</p>
          </div>
          {markAsRead ? (
            <Marker
              onClick={() => markAsRead(notification, !read)}
              read={read}
            />
          ) : null}
        </div>
        {subtitle && (
          <div className="flex !hidden gap-2">
            <Divider
              layout="vertical"
              variant="default"
              className="h-full !w-1"
            />
            <p>{subtitle}</p>
          </div>
        )}
        {actions?.map((action, index) => {
          switch (action.type) {
            case 'link':
              return (
                <a
                  key={index}
                  href={action.href}
                  className={clsx('button primary small mt-1 w-min')}
                >
                  {action.label}
                </a>
              );

            default:
              return null;
          }
        })}
        <div className="mt-1 text-xs font-semibold text-content-base-subdued">
          {/* Append 'Z' at the end to indicate UTC timezone */}
          {formatDate(created_at + 'Z')}
        </div>
      </div>
    </div>
  );
};

export default Card;
