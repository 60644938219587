import clsx from 'clsx';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteInput } from 'components/V2/Input/GraniteInput';
import { useState } from 'react';
import { z } from 'zod';

interface AddEmailRecipientProps {
  appendFunc: ({ email }: { email: string }) => void;
}

const AddEmailRecipient = ({ appendFunc }: AddEmailRecipientProps) => {
  const [newEmail, setNewEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email: string) => {
    const isValid = !email || z.string().email().safeParse(email).success;
    setIsEmailValid(isValid);
    return isValid;
  };

  const handleAddEmail = () => {
    if (newEmail && validateEmail(newEmail)) {
      appendFunc({ email: newEmail });
      setNewEmail('');
    }
  };
  return (
    <div className="col-span-full flex items-center">
      <GraniteInput
        label="Add another email recipient"
        error={!isEmailValid && 'Invalid email'}
        placeholder="Type in email"
        className="flex-grow basis-[90%]"
        innerInputClassName="!rounded-r-none"
        onChange={(e) => {
          setNewEmail(e.target.value);
          validateEmail(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleAddEmail();
          }
        }}
        value={newEmail}
      />
      <GraniteButton
        variant={'secondary'}
        size="large"
        className={clsx(
          `!rounded-l-none ${
            !isEmailValid ? 'mt-3 !min-h-[46px]' : 'mt-auto !min-h-[46px]'
          } basis-[10%]`,
        )}
        onClick={handleAddEmail}
      >
        Add
      </GraniteButton>
    </div>
  );
};

export default AddEmailRecipient;
