import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { Auth0RedirectionLoader } from '../../components/Auth0RedirectionLoader/Auth0RedirectionLoader';

// TODO: add tests
export const Login = () => {
  // For the moment the only concern of this component is invitations
  return useCheckInvitation() || <Navigate to="/" />;
};

// TODO: add tests
const useCheckInvitation = () => {
  const [searchParams] = useSearchParams();
  const { loginWithRedirect } = useAuth0();

  const invitation = searchParams.get('invitation');
  const organization = searchParams.get('organization');

  if (invitation && organization) {
    loginWithRedirect({
      authorizationParams: {
        invitation,
        organization,
      },
    });
    return <Auth0RedirectionLoader />;
  }

  return;
};
