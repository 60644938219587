import { CheckmarkCircle, Trophy } from 'react-ionicons';
import { DarkSparkles } from 'components/Sparkles/DarkSparkles';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { LightSparkles } from 'components/Sparkles/LightSparkles';
import { Location } from './types';
import { PricingTier } from 'api/accessexpress/schema';
import { DeepPartial } from 'react-hook-form';

interface PackageCardProps {
  data: PricingTier & { productType: Location['productType'] };
  selected?: boolean;
  onSelect: (selectedTier: DeepPartial<PricingTier>) => void;
}

const PackageCard = ({ data, selected, onSelect }: PackageCardProps) => {
  const { avg_bandwidth, name, avg_mrc } = data;
  // const [isDetailsVisible, setIsDetailsVisible] = useState(selected);
  // const location = useLocation();

  // const flowType = location.state?.flowType;

  // const toggleDetails = () => {
  //   setIsDetailsVisible(!isDetailsVisible);
  // };

  const getIcon = (type: string, color: string) => {
    switch (name) {
      case 'Advanced':
        return <Trophy color={color} width={'21px'} height={'21px'} />;
      case 'Performance':
        return <DarkSparkles fill={color} />;
      case 'Starter':
        return <LightSparkles fill={color} />;
      default:
        return <Trophy color={color} />;
    }
  };

  return (
    <div
      className={`rounded-lg border-2 bg-background-base-surface-3 p-4 transition-all duration-200 ${
        selected ? 'text-stroke-accent-default' : 'border-transparent'
      }`}
    >
      <h2 className="mb-2 flex items-center justify-center gap-2 font-semibold text-content-base-default">
        <span>{getIcon(name, selected ? '#F9CB34' : '#94A3B8')}</span>
        <span>{name}</span>
      </h2>
      <div className="my-4 rounded bg-background-base-surface-2 py-3">
        <p className="text-gray-400 mb-1 text-center text-xs font-bold">
          Average MRC
        </p>
        <p className="mb-4 text-center text-3xl font-bold text-content-base-default">
          ${avg_mrc}
        </p>
        <p className="text-gray-400 mb-1 text-center text-xs font-bold">
          Average bandwidth
        </p>
        <p className="text-center text-xl font-bold text-content-base-default">
          {avg_bandwidth} <span className="text-base">Mb/s</span>
        </p>
      </div>

      <div className="flex">
        <GraniteButton
          className="align-center w-full"
          onClick={() => !selected && onSelect({ ...data })}
        >
          {selected ? 'Selected!' : 'Select'}
          {selected ? (
            <span className="fill-button-content-primary-default">
              <CheckmarkCircle width="20px" height="20px" color="inherit" />
            </span>
          ) : null}
        </GraniteButton>
      </div>
      {/* {flowType !== 'simple' && (
        <GraniteButton
          variant="ghost"
          onClick={toggleDetails}
          className="my-2 !box-border w-full text-content-base-default"
        >
          {isDetailsVisible ? 'Hide details' : 'Show details'}
          <IconMapper
            color="inherit"
            name={isDetailsVisible ? 'chevronUp' : 'chevronDown'}
          />
        </GraniteButton>
      )} */}
    </div>
  );
};

export default PackageCard;
