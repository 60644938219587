import styled from 'styled-components';

export const Badge = styled.div`
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0 8px;
  color: ${({ theme }) => theme.colors.text.white};

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
`;

export const TicketIdBadge = styled(Badge)`
  background: ${({ theme }) => theme.colors.background.baseSurface3};
`;

export const Priority1Badge = styled(Badge)`
  background: #1a4bab;
  font-weight: 600;
`;
export const Priority2Badge = styled(Badge)`
  background: #3d79ef;
  font-weight: 600;
`;
export const Priority3Badge = styled(Badge)`
  background: #96baff;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.dark};
`;

export const PremiumBadge = styled(Badge)`
  background: #2a2c89;
  font-weight: 600;
`;

export const StatusCell = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const StatusIndicator = styled.span<{ $status: string }>`
  background: ${({ $status }) => {
    switch ($status) {
      case 'open':
      case 'pendingtechassignment':
        return '#fbb979';
      case 'assigned':
      case 'techassigned':
        return '#9796f3';
      case 'inprogress':
      case 'techcheckedin':
        return '#82ff91';
      case 'onhold':
        return '#FCDF56';
      case 'cancelled':
      case 'pendingcustomer':
        return '#FF5F82';
      case 'completed':
      case 'complete':
        return '#82f0ff';
    }
  }};

  min-width: 12px;
  min-height: 12px;
  border-radius: 100%;
`;
