import { InputGranite } from 'components';
import Accordion from 'components/Accordion/Accordion';
import UserAvatar from 'components/Avatar/Avatar';
import Banner from 'components/Banner/Banner';
import Divider from 'components/Divider/Divider';
import HorizontalFieldWrapper from 'components/HorizontalFieldWrapper/HorizontalFieldWrapper';
import ListValues from 'components/ListValues/ListValues';
import Loader from 'components/Loader/Loader';
import Logo from 'components/Logo/Logo';
import { GraniteSelect } from 'components/Select/Select';
import Switch from 'components/Switch/Switch';
import Tag from 'components/Tag/Tag';
import showToast from 'components/Toast/Toast';
import Tooltip from 'components/Tooltip/Tooltip';

const listValuesItems = [
  { label: 'Label 1', value: 'Value 1' },
  { label: 'Label 2', value: 'Value 2' },
  { label: 'Label 3', value: 'Value 3' },
];

const accordionItems = [
  { title: 'Title 1', content: 'Sample content' },
  { title: 'Title 2', content: <ListValues items={listValuesItems} /> },
];

const productOptions = [
  { value: 'Broadband', label: 'Broadband' },
  { value: 'DIA', label: 'Direct internet access (DIA)' },
  { value: 'Broadband1', label: 'Broadband1' },
  { value: 'DIA1', label: 'Direct internet access (DIA)1' },
  { value: 'Broadband2', label: 'Broadband2' },
  { value: 'DIA2', label: 'Direct internet access (DIA)2' },
];

const Components = () => {
  return (
    <div className="mt-20 flex flex-col px-10">
      {/* item */}
      <div className="mb-10 flex w-[45%] flex-col">
        <label className="mb-5">Divider default</label>
        <Divider variant="default" layout="horizontal" />
      </div>
      {/* item */}
      <div className="mb-10 flex w-[45%] flex-col">
        <label className="mb-5">Divider subdued</label>
        <Divider variant="subdued" layout="vertical" />
      </div>
      {/* item */}
      <div className="mb-10 flex w-[45%] flex-col">
        <label className="mb-5">Accordion</label>
        <Accordion items={accordionItems} />
      </div>

      {/* item */}
      <div className="mb-10 flex w-[25%] flex-col">
        <label className="mb-5">List of Values</label>
        <ListValues items={listValuesItems} />
      </div>

      {/* item */}
      <div className="flex flex-row">
        <div className="mb-10 flex w-[25%] flex-col">
          <label className="mb-5">Switch</label>
          <Switch
            onChange={function (value: boolean): void {
              throw new Error(`Function not implemented.${value}`);
            }}
          />
        </div>
        <div className="mb-10 flex w-[25%] flex-col">
          <label className="mb-5">Switch with Label</label>
          <Switch
            label={'Label'}
            onChange={function (value: boolean): void {
              throw new Error(`Function not implemented.${value}`);
            }}
          />
        </div>
        <div className="mb-10 flex w-[25%] flex-col">
          <label className="mb-5">Switch - Disabled</label>
          <Switch
            disabled
            onChange={function (value: boolean): void {
              throw new Error(`Function not implemented. ${value}`);
            }}
          />
        </div>
      </div>
      {/* item */}
      <label className="mb-5">Banner</label>
      <div className="flex flex-row gap-5">
        <div className="mb-10 flex flex-col">
          <Banner
            type="generic"
            title="Generic"
            message="Generic type message."
            showCloseButton={true}
            showActions={true}
            className="mb-5 w-[375px]"
          />
        </div>
        <div className="mb-10 flex flex-col">
          <Banner
            type="confirmation"
            title="Confirmation"
            message="Confirmation type message."
            showCloseButton={true}
            showActions={true}
            className="mb-5 w-[375px]"
          />
        </div>
        <div className="mb-10 flex flex-col">
          <Banner
            type="informative"
            title="Informative"
            message="Informative type message."
            showCloseButton={true}
            showActions={true}
            className="mb-5 w-[375px]"
          />
        </div>
        <div className="mb-10 flex w-[25%] flex-col">
          <Banner
            type="error"
            title="Error!"
            message="Something went wrong."
            showCloseButton={true}
            showActions={true}
            className="mb-5 w-[375px]"
          />
        </div>
      </div>
      {/* item */}
      <label className="mb-5">Tag Default</label>
      <div className="mb-5 flex flex-row gap-5">
        <Tag
          status="success"
          style="default"
          content={{ type: 'label', label: 'Success' }}
        />
        <Tag
          status="danger"
          style="default"
          content={{
            type: 'label+icon',
            label: 'Danger',
            icon: <span>🔥</span>,
          }}
        />
        <Tag
          status="informative"
          style="default"
          content={{
            type: 'label',
            label: 'Informative',
          }}
        />
        <Tag
          status="caution"
          style="default"
          content={{
            type: 'label',
            label: 'Caution',
          }}
        />
        <Tag
          status="neutral"
          style="default"
          content={{
            type: 'label',
            label: 'Neutral',
          }}
        />
      </div>
      {/* item */}
      <label className="mb-5">Tag Subdued</label>
      <div className="flex flex-row gap-5">
        <Tag
          status="success"
          style="subdued"
          content={{ type: 'label', label: 'Success' }}
        />
        <Tag
          status="danger"
          style="subdued"
          content={{
            type: 'label+icon',
            label: 'Danger',
            icon: <span>🔥</span>,
          }}
        />
        <Tag
          status="informative"
          style="subdued"
          content={{
            type: 'label',
            label: 'Informative',
          }}
        />
        <Tag
          status="caution"
          style="subdued"
          content={{
            type: 'label',
            label: 'Caution',
          }}
        />
        <Tag
          status="neutral"
          style="subdued"
          content={{
            type: 'label',
            label: 'Neutral',
          }}
        />
      </div>
      <h2>Tooltip</h2>

      <div className="mt-5 flex flex-row gap-10">
        <Tooltip
          arrowDirection="up"
          arrowPosition="left"
          content="Tooltip up left"
          showHeader
        />
        <Tooltip
          arrowDirection="up"
          arrowPosition="middle"
          content="Tooltip up middle"
          showHeader
        />
        <Tooltip
          arrowDirection="up"
          arrowPosition="right"
          content="Tooltip up right"
          showHeader
        />
      </div>
      <div className="mt-5 flex flex-row gap-10">
        <Tooltip
          arrowDirection="left"
          arrowPosition="top"
          content="Tooltip left top"
          showHeader
        />
        <Tooltip
          arrowDirection="left"
          arrowPosition="middle"
          content="Tooltip left middle"
          showHeader
        />
        <Tooltip
          arrowDirection="left"
          arrowPosition="bottom"
          content="Tooltip left bottom"
          showHeader
        />
      </div>
      <div className="mt-5 flex flex-row gap-10">
        <Tooltip
          arrowDirection="right"
          arrowPosition="top"
          content="Tooltip right top"
          showHeader
        />
        <Tooltip
          arrowDirection="right"
          arrowPosition="middle"
          content="Tooltip right middle"
          showHeader
        />
        <Tooltip
          arrowDirection="right"
          arrowPosition="bottom"
          content="Tooltip right bottom"
          showHeader
        />
      </div>
      <div className="mb-10 mt-5 flex flex-row gap-10">
        <Tooltip
          arrowDirection="down"
          arrowPosition="top"
          content="Tooltip down top"
          showHeader
        />
        <Tooltip
          arrowDirection="down"
          arrowPosition="middle"
          content="Tooltip down middle"
          showHeader
        />
        <Tooltip
          arrowDirection="down"
          arrowPosition="bottom"
          content="Tooltip down bottom"
          showHeader
        />
      </div>
      {/* item */}
      <div className="mb-10 flex w-[25%] flex-col">
        <label className="mb-5">Avatar</label>
        <UserAvatar name="Eduana" isSelected={false} />
      </div>
      {/* item */}
      <div className="mb-[30px] flex w-[25%] flex-col">
        <label className="mb-5">Select</label>
        <GraniteSelect
          onChange={(value) => console.log({ value })}
          options={productOptions}
        />
      </div>
      {/* item */}
      <div className="mb-[30px] flex w-[25%] flex-col">
        <label className="mb-5">Multi Select</label>
        <GraniteSelect
          onChange={(value) => console.log({ value })}
          options={productOptions}
          isMulti
        />
      </div>
      <div className="mb-[30px] flex w-[25%] flex-col">
        <label className="mb-5">Disabled Select</label>
        <GraniteSelect
          onChange={(value) => console.log({ value })}
          options={productOptions}
          isDisabled
        />
      </div>
      <div className="mb-[300px] flex w-[25%] flex-col">
        <label className="mb-5">Toast</label>
        <button
          onClick={() =>
            showToast.generic({
              title: 'Dynamic Title',
              message: 'This is default message',
              buttonLabel: 'Label',
            })
          }
        >
          Click default
        </button>
        <button
          onClick={() => showToast.error({ message: 'This is error message' })}
        >
          Click error
        </button>
        <button
          onClick={() =>
            showToast.confirmation({ message: 'This is success message' })
          }
        >
          Click success
        </button>
        <button
          onClick={() =>
            showToast.informative({ message: 'This is info message' })
          }
        >
          Click info
        </button>
      </div>
      <div className="mb-[30px] flex w-[25%] flex-col">
        <label className="mb-5">Horizontal Field Wrapper</label>
        <HorizontalFieldWrapper
          title="Section Title"
          description="Section description"
          containerWidth="800px"
        >
          <InputGranite label="Label" info="Info message" />
        </HorizontalFieldWrapper>
      </div>
      <div className="mb-[30px] flex w-[25%] flex-col">
        <InputGranite
          label="Label"
          error={'Error message'}
          placeholder={'Text Input'}
        />
      </div>
      <div className="mb-[30px] flex w-[25%] flex-col">
        <Loader />
      </div>
      <div className="mb-[30px] flex w-[25%] flex-col">
        <Logo />
      </div>
    </div>
  );
};

export default Components;
