import { getCompanyAdmins } from 'api/companies/api';
import { Modal } from 'components/Modal/Modal';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ModalParams } from 'hooks/useModal';
import { useQuery } from 'react-query';

export interface CompanyAdminsProps extends ModalParams {}
interface CompanyAdmin {
  email: string;
  email_verified: boolean;
  name: string;
  status: string;
}

export const CompanyAdminsDialog = ({ isOpen, close }: CompanyAdminsProps) => {
  const { data } = useQuery(['company-admins'], () => getCompanyAdmins(), {
    refetchOnMount: false,
  });

  return (
    <Modal isVisible={isOpen} close={close}>
      <div className="flex h-auto w-[624px] flex-col items-start justify-start gap-8 rounded bg-background-base-surface-2 p-8">
        <div className="flex flex-col justify-center gap-2">
          <p className="text-2xl font-bold text-content-base-default">
            Contact a company administrator
          </p>
        </div>
        <div className="vertical-scrollbar flex h-[376px] max-h-[380px] w-full flex-col items-start justify-start rounded">
          <div className="w-full rounded bg-background-base-surface-1 p-4">
            <ul className="ml-[10px] flex list-inside list-disc flex-col items-start justify-start gap-3">
              {data?.length > 0
                ? data.map((item: CompanyAdmin, key: number) => (
                    <li key={key}>
                      <span className="font-semibold text-content-accent-default">
                        {item.email}
                      </span>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>
        <GraniteButton
          variant="secondary"
          size="large"
          onClick={close}
          className="mt-3"
        >
          Close
        </GraniteButton>
      </div>
    </Modal>
  );
};
