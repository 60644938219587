import { ReactNode, useEffect, useRef, useState } from 'react';
import { GraniteButton } from '../V2/Button/GraniteButton';
import { usePopper } from 'react-popper';
import { CaretDown } from 'react-ionicons';

export interface DropdownButtonProps {
  label: ReactNode;
  options: { label: ReactNode; onClick: () => void }[];
}

export const DropdownButton = ({ label, options }: DropdownButtonProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popperElementRef = useRef<HTMLDivElement>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [parentWidth, setParentWidth] = useState<number | undefined>(undefined);

  const { styles, attributes } = usePopper(
    anchorRef.current,
    popperElementRef.current,
    {
      placement: 'bottom-start',
    },
  );

  useEffect(() => {
    setParentWidth(anchorRef.current?.clientWidth);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutside =
        popperElementRef.current &&
        event.target instanceof Node &&
        !popperElementRef.current.contains(event.target);

      if (isClickOutside) {
        setIsMenuVisible(false);
      }
    };
    if (isMenuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuVisible]);

  return (
    <div>
      <div className="flex" ref={anchorRef}>
        <GraniteButton
          onClick={options.length > 0 ? options[0].onClick : undefined}
          className="rounded-r-none"
          size="large"
        >
          {label}
        </GraniteButton>
        <GraniteButton
          onClick={() => setIsMenuVisible((prev) => !prev)}
          className="rounded-l-none border-l border-teal-500 !px-4"
          size="large"
        >
          <CaretDown width="16px" height="16px" color="inherit" />
        </GraniteButton>
      </div>
      <div
        ref={popperElementRef}
        style={{
          ...styles.popper,
          ...(parentWidth ? { width: `${parentWidth}px` } : {}),
        }}
        {...attributes.popper}
      >
        {isMenuVisible && (
          <div className="flex flex-col rounded bg-background-base-surface-3">
            {options.map((opt, i) => (
              <GraniteButton
                key={i}
                onClick={opt.onClick}
                className="!justify-start px-3 py-2 hover:bg-background-base-surface-2"
                variant="unstyled"
              >
                {opt.label}
              </GraniteButton>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
