import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { ReactComponent as Vektor } from 'assets/images/looking_service_options.svg';
import { CheckmarkCircle } from 'react-ionicons';

interface LookingForMoreServiceOptionsProps {
  onSelect: () => void;
  isSelected: boolean;
  isPreview: boolean;
}

const LookingForMoreServiceOptions = ({
  onSelect,
  isSelected,
  isPreview = false,
}: LookingForMoreServiceOptionsProps) => {
  return (
    <div
      className={`relative z-10 flex h-full cursor-pointer flex-col gap-2 overflow-hidden rounded bg-background-base-surface-3 p-4 hover:!border hover:border-stroke-accent-default ${
        isSelected ? 'border-2 border-stroke-accent-default' : ''
      }`}
      onClick={() => (isPreview ? {} : onSelect())}
    >
      <div className="absolute bottom-0 right-0 w-full">
        <Vektor className="w-full" />
      </div>
      <p className="font-bold text-content-base-default">
        Looking for more service options?
      </p>
      <p className="text-sm font-semibold">
        Select this option to have a Granite representative contact you with
        more information about the available options at this location.
      </p>
      <GraniteButton
        size="medium"
        variant={isSelected ? 'primary' : 'secondary'}
        className="w-min-[133px] mt-4 w-[133px]"
        disabled={isPreview}
      >
        Contact me{' '}
        {isSelected ? (
          <CheckmarkCircle width="20px" height="20px" color="#191925" />
        ) : null}
      </GraniteButton>
    </div>
  );
};

export default LookingForMoreServiceOptions;
