import { useState } from 'react';

export const usePagination = (dataLength: number, itemsPerPage = 4) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemsPerPage);
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return {
    currentPage: page,
    pageCount: Math.ceil(dataLength / pageSize),
    currentRowsShown: 1,
    totalRows: dataLength,
    onPageChange: handlePageChange,
    pageSizeChanged: handlePageSizeChange,
    from: (page - 1) * pageSize,
    to: page * pageSize,
  };
};
