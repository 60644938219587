import React from 'react';
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaHome,
  FaChevronUp,
  FaLightbulb,
  FaPencilAlt,
} from 'react-icons/fa';
import {
  Add,
  ArrowDown,
  ArrowBackOutline,
  ArrowForwardOutline,
  ArrowUp,
  Calendar,
  CaretDown,
  CaretUp,
  Chatbox,
  Checkmark,
  ChevronBack,
  ChevronForward,
  Download,
  Exit,
  Search,
} from 'react-ionicons';

interface IconMapperProps extends React.HTMLProps<HTMLDivElement> {
  name: string;
  color?: string;
  size?: number;
}

const IconMapper: React.FC<IconMapperProps> = (props) => {
  const { name, size, color } = props;
  const iconMap: Record<string, React.ElementType> = {
    search: Search,
    create: FaPencilAlt,
    lightbulb: FaLightbulb,
    chevronDown: FaChevronDown,
    chevronUp: FaChevronUp,
    checkCircle: FaCheckCircle,
    download: Download,
    chevronLeft: FaChevronLeft,
    chevronRight: FaChevronRight,
    chevronBack: ChevronBack,
    chevronForward: ChevronForward,
    arrowDown: ArrowDown,
    arrowForward: ArrowForwardOutline,
    arrowBack: ArrowBackOutline,
    add: Add,
    checkmark: Checkmark,
    home: FaHome,
    arrowUp: ArrowUp,
    calendar: Calendar,
    caretDown: CaretDown,
    caretUp: CaretUp,
    exit: Exit,
    chatbox: Chatbox,
    // Add more mappings as needed
  };

  const IconComponent = iconMap[name];

  if (IconComponent) {
    return (
      <div {...props}>
        <IconComponent
          size={size}
          color={color}
          width={`${size}px`}
          height={`${size}px`}
        />
      </div>
    );
  }

  // Return a default icon or null if no matching icon is found
  return null;
};

export default IconMapper;
