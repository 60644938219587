import { InformationCircle } from 'react-ionicons';
import React, { Fragment, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { usePopper } from 'react-popper';
import { QuoteProductType } from '../utils';
import clsx from 'clsx';

export interface ProductTooltipProps {
  product: QuoteProductType;
  inReviewProducts?: boolean;
}

const QEProductDescription = observer(
  ({ product }: { product: QuoteProductType }) => {
    return (
      <Fragment>
        <li>Product: {product.product_type}</li>
        <li>Access type: {product.access_type ?? ''}</li>
        <li>
          Product speed: {product?.max_download}/{product?.min_download} Mbps
        </li>
        <li>
          IP type: {product?.ip_type === 'DYNAMIC' ? 'Dynamic IP' : 'Static IP'}
        </li>
        <li>Contract term length: {product?.term}</li>
      </Fragment>
    );
  },
);

export const ProductTooltip = observer(({ product }: ProductTooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerEltRef = useRef<HTMLDivElement>(null);
  const popperEltRef = useRef<HTMLDivElement>(null);

  const { styles, attributes } = usePopper(
    triggerEltRef?.current,
    popperEltRef?.current,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-16, 14],
          },
        },
      ],
    },
  );

  const isBroadband = product.product_type === 'Broadband';

  return (
    <Fragment>
      <div
        ref={triggerEltRef}
        className={clsx(
          'flex items-center justify-center gap-1 rounded-lg bg-orange-300 px-3 py-2 text-xs font-bold text-content-flip-default hover:bg-orange-200',
          !isBroadband &&
            'border border-status-info-default bg-transparent !text-content-base-default hover:bg-[#9796F3] hover:!text-content-flip-default [&>span>svg]:fill-[#9796F3] [&>span>svg]:hover:fill-[#191925]',
          isBroadband &&
            'border !border-orange-300 bg-transparent !text-content-base-default hover:!bg-orange-300 hover:!text-content-flip-default [&>span>svg]:!fill-[#FBB979] [&>span>svg]:hover:!fill-[#191925]',
        )}
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        <span>{`${product.max_download}/${product?.min_download} mbps`}</span>

        <InformationCircle width="16px" height="16px" color="inherit" />
      </div>
      <div ref={popperEltRef} style={styles.popper} {...attributes.popper}>
        {isVisible && (
          <div className="rounded-lg bg-background-base-surface-0 p-5 text-content-base-subdued shadow-elevation3 ">
            <ul className="list-inside list-disc text-sm text-content-base-subdued">
              <QEProductDescription product={product} />
            </ul>
          </div>
        )}
      </div>
    </Fragment>
  );
});
