import { useAuth0 } from '@auth0/auth0-react';
import { ColumnDef } from '@tanstack/react-table';
import { getCompanies } from 'api/companies/api';
import { PageTitleGranite } from 'components';
import Table from 'components/Table';
import Searchbar from 'components/Table/SearchBar';
import { GraniteButton } from 'components/V2/Button/GraniteButton';
import { GraniteLabel } from 'components/V2/Label/GraniteLabel';
import { useAuth0User } from 'hooks/useAuth0User';
import { ContentLayout } from 'layouts/ContentLayout/ContentLayout';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { TicketIdBadge } from 'screens/TechExpress/TechExpress.styles';

type Companies = {
  name: string;
  display_name: string;
  org_id: string;
  macnum: string;
};

const Companies: React.FC = () => {
  const { sub, org_id, companyName } = useAuth0User();

  const [companies, setCompanies] = useState<Companies[]>([]);

  const { data } = useQuery(['companies', sub], () => getCompanies(sub));

  useEffect(() => {
    if (data) {
      setCompanies(data);
    }
  }, [data]);

  const { loginWithRedirect } = useAuth0();

  const handleSilentRelogin = async (org_id: string) => {
    await loginWithRedirect({
      authorizationParams: { organization: org_id },
    });
  };

  const handleSearch = (query: string) => {
    const searchedData = companies.filter(
      (item) =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.macnum.toLowerCase().includes(query.toLowerCase()),
    );
    setCompanies(searchedData);
  };

  const CompaniesHeaders: ColumnDef<Companies>[] = [
    {
      header: 'Company macnum',
      cell: (row) => <TicketIdBadge>{row.row.original.macnum}</TicketIdBadge>,
    },
    {
      header: 'Company name',
      accessorKey: 'display_name',
      cell: (row) => (
        <div className="text-sm font-medium">
          {row.row.original.display_name}
        </div>
      ),
    },
    {
      header: 'Actions',
      cell: (row) => {
        return (
          <div
            className="text-sm font-medium text-content-base-default"
            onClick={() => handleSilentRelogin(row.row.original.org_id)}
          >
            {row.row.original.org_id === org_id ? (
              'Current company'
            ) : (
              <GraniteButton title="" size="small" variant="secondary">
                Switch to this company
              </GraniteButton>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <ContentLayout>
      <div className="flex w-full flex-col items-start justify-start">
        <PageTitleGranite title="Companies" />
        <div className="mb-8 mt-6 flex flex-col items-start justify-start gap-2">
          <div>
            <GraniteLabel className="text-base font-bold">
              Current company
            </GraniteLabel>
            <h1 className="text-2xl font-bold text-content-base-default">
              {companyName}
            </h1>
          </div>
          <p className="text-base font-semibold text-content-base-default">
            You are currently accessing Granite360 as a Granite Super Admin from{' '}
            {companyName}.
          </p>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-12">
          <Searchbar
            placeholder="Search by macnum or company name"
            onQueryClear={() => {
              setCompanies(data);
            }}
            onSearch={(query: string) => {
              handleSearch(query);
            }}
          />
          <Table
            data={companies ?? []}
            columns={CompaniesHeaders}
            specificColumnCount={9}
            columnGap={'80px'}
            title={'Companies'}
            handleRowClick={() => {
              return;
            }}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default Companies;
