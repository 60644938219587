import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GraniteLabel } from '../Label/GraniteLabel';
import { clsx } from 'clsx';
import { ErrorSubtext } from '../../ErrorSubtext/ErrorSubtext';
import { CheckmarkCircle, EyeOffSharp, EyeSharp } from 'react-ionicons';

export interface PasswordInputProps extends ComponentProps<'input'> {
  className?: string;
  label?: string;
  subtitle?: string;
  error?: string | boolean;
  variant?: 'primary' | 'ghost' | 'outlined';
  innerInputClassName?: string;
  showCriteria?: boolean;
}

export const PasswordInput = ({
  className,
  label,
  subtitle,
  error,
  showCriteria = true,
  variant = 'primary',
  innerInputClassName,
  ...props
}: PasswordInputProps) => {
  const variantToClassName = {
    primary: 'granite-input-primary',
    ghost: '',
    outlined: 'granite-input-outlined',
  }[variant];

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [type, setType] = useState('password');
  const wrapperRef = useRef<HTMLDivElement>(null);

  const passwordCriteria = useMemo(() => {
    const value = props.value as string;
    return {
      minLength: value?.length >= 12,
      // Added check for 'value' to handle edge cases where an empty string passes the /[a-z]/ test.
      lowerCase: !!value && /[a-z]/.test(value),
      upperCase: /[A-Z]/.test(value),
      number: /[0-9]/.test(value),
    };
  }, [props.value]);

  const togglePasswordVisibility = () =>
    setType(type === 'text' ? 'password' : 'text');

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        event.target instanceof Node &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    },
    [wrapperRef],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const EyeIcon = type === 'text' ? EyeSharp : EyeOffSharp;

  return (
    <div ref={wrapperRef} className="w-full">
      <GraniteLabel
        label={label}
        subtitle={subtitle}
        className={clsx(className, 'relative')}
      >
        <div className="relative grid w-full">
          <input
            aria-label={label}
            {...props}
            type={type}
            className={clsx(
              'granite-input',
              variantToClassName,
              error && 'error',
              innerInputClassName,
            )}
            onFocus={() => {
              if (showCriteria) {
                setIsMenuVisible(true);
              }
            }}
          />
          <span className="absolute right-4 top-3 cursor-pointer text-base font-light text-content-base-subdued">
            <EyeIcon color="#F8FAFC" onClick={togglePasswordVisibility} />
          </span>
        </div>
        <ErrorSubtext error={error} />
        <div className="absolute left-0 right-0 top-[52px] !z-50 bg-background-base-surface-2">
          {isMenuVisible && (
            <div className="flex w-full flex-col gap-3 rounded-b border border-b border-input-stroke-filled p-4 text-base text-content-base-default">
              <label>Your password must contain:</label>
              <PasswordValidationItem
                label={'At least 12 characters'}
                checked={passwordCriteria.minLength}
              />
              <PasswordValidationItem
                label={'Lowercase letters (a-z)'}
                checked={passwordCriteria.lowerCase}
              />
              <PasswordValidationItem
                label={'Uppercase letters (A-Z)'}
                checked={passwordCriteria.upperCase}
              />
              <PasswordValidationItem
                label={'Numbers (0-9)'}
                checked={passwordCriteria.number}
              />
            </div>
          )}
        </div>
      </GraniteLabel>
    </div>
  );
};

const PasswordValidationItem = ({
  label,
  checked,
}: {
  label: string;
  checked: boolean;
}) => {
  return (
    <div className="flex items-center justify-start gap-2">
      {checked ? (
        <CheckmarkCircle color={'#82F0FF'} width="16px" height="16px" />
      ) : (
        <UncheckedCircle />
      )}
      {label}
    </div>
  );
};

const UncheckedCircle = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333333 7C0.333333 3.31847 3.31847 0.333333 7 0.333333C10.6815 0.333333 13.6667 3.31847 13.6667 7C13.6667 10.6815 10.6815 13.6667 7 13.6667C3.31847 13.6667 0.333333 10.6815 0.333333 7Z"
        stroke="#94A3B8"
        strokeWidth="0.666667"
      />
    </svg>
  );
};
