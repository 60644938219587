import { Modal } from '../Modal/Modal';
import { ModalParams } from '../../hooks/useModal';
import { FileLike, FileUpload } from '../V2/FileUpload/FileUpload';
import React, { Fragment, useCallback, useState } from 'react';
import { GraniteButton } from '../V2/Button/GraniteButton';
import { useMutation } from 'react-query';
import { papaParseFileAsync } from '../../shared/util/PapaParseFileAsync';
import { z } from 'zod';
import {
  LETTERS_SPACES_HYPENS_ERROR_LABEL,
  VALID_PHONE_ERROR_LABEL,
} from '../../shared/constants/error-labels';
import { UserRoles } from '../../api/users/schemas/UserRole';
import {
  LETTERS_SPACES_HYPENS_REGEXP,
  VALID_PHONE_NUMBER,
} from '../../shared/constants/validation-regex-constants';
import { inviteUser } from '../../api/users/api';
import showToast from '../Toast/Toast';
import {
  InviteUserRequest,
  InviteUserResponse,
} from '../../api/users/schemas/Invitations';
import { useAuth0User } from '../../hooks/useAuth0User';
import { useInviteTableRefetch } from '../../screens/UserManagement/useInviteTableRefetch';

const CSVUsersSchema = z.array(
  z.tuple([
    z
      .string()
      .nonempty('Required')
      .max(100, 'Only 100 characters allowed')
      .regex(LETTERS_SPACES_HYPENS_REGEXP, {
        message: LETTERS_SPACES_HYPENS_ERROR_LABEL,
      }),
    z.string().nonempty().email(),
    z
      .string()
      .nonempty(VALID_PHONE_ERROR_LABEL)
      .length(10, VALID_PHONE_ERROR_LABEL)
      .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
    UserRoles,
  ]),
);

type CSVUsers = z.infer<typeof CSVUsersSchema>;

export interface BulkImportUsersDialogProps extends ModalParams {}

export const BulkImportUsersDialog = ({
  isOpen,
  close,
}: BulkImportUsersDialogProps) => {
  const { name: inviterName } = useAuth0User();
  const [selectedFiles, setSelectedFiles] = useState<FileLike[]>([]);

  const [fileFieldError, setFileFieldError] = useState<string | undefined>();
  const [validUserList, setValidUserList] = useState<InviteUserRequest>([]);

  const parseMutation = useMutation(
    async (csvFile: FileLike) => {
      if (csvFile instanceof File) {
        const parsedCSV = await papaParseFileAsync<CSVUsers>(csvFile as File);
        const csvData = parsedCSV.data.slice(1);

        if (csvData.length === 0) {
          throw Error('Empty file');
        }

        return CSVUsersSchema.parse(csvData);
      } else {
        throw Error('Not a valid File');
      }
    },
    {
      onSuccess: (parsedUsersFile) => {
        if (parsedUsersFile.length > 50) {
          setFileFieldError('A maximum of 50 users per file can be added');
        }

        const validUsers = parsedUsersFile.map(
          ([name, email, phone_number, role]) => ({
            name,
            email,
            phone_number,
            role,
            permissions: [],
            inviter_name: inviterName,
          }),
        );

        setValidUserList(validUsers);
      },
      onMutate: () => {
        setFileFieldError(undefined);
        setValidUserList([]);
      },
      onError: () => {
        setFileFieldError(
          'Something went wrong. Please make sure you’re using the template file and all fields are correctly filled out.',
        );
        setValidUserList([]);
      },
    },
  );

  const onClose = useCallback(() => {
    setSelectedFiles([]);
    setValidUserList([]);
    setFileFieldError(undefined);
    close();
  }, [close]);

  const refetchInviteTable = useInviteTableRefetch();

  const inviteUsersMutation = useMutation(
    (request: InviteUserRequest) => inviteUser(request),
    {
      onSuccess: (response) => {
        const { successful, failure } = response.reduce(
          (acc, val) => {
            if (val.result === 'Success') {
              acc.successful.push(val);
            } else {
              acc.failure.push(val);
            }

            return acc;
          },
          {
            successful: [] as InviteUserResponse[],
            failure: [] as InviteUserResponse[],
          },
        );

        if (successful.length > 0) {
          showToast.confirmation({
            message: `${successful.length} new user(s) have been added.`,
          });
        }
        if (failure.length > 0) {
          showToast.error({
            message: `${failure.length} user(s) could not be added.`,
          });
        }

        refetchInviteTable();
        onClose();
      },
    },
  );

  return (
    <Modal isVisible={isOpen} close={onClose} className="w-full max-w-[624px]">
      <h1 className="rounded-t-lg bg-background-base-surface-3 px-8 py-6 text-3xl text-[28px] font-bold text-content-base-default">
        Bulk invite users
      </h1>

      <div className="flex flex-col gap-12 rounded-b-lg bg-background-base-surface-2 p-8">
        <FileUpload
          label="Upload a file of user information"
          multiple={false}
          accept=".csv"
          error={fileFieldError}
          subtitle={
            <Fragment>
              Use a copy of this{' '}
              <a
                href="/bulk_user_import_template.csv"
                target="_blank"
                rel="noreferrer"
                download
                className="cursor-pointer text-content-accent-default underline visited:text-content-accent-default"
              >
                template file
              </a>
            </Fragment>
          }
          onChange={(files) => {
            setSelectedFiles(files);
            if (files.length > 0) {
              parseMutation.mutate(files[0]);
            } else {
              setFileFieldError('');
              setValidUserList([]);
            }
          }}
          value={selectedFiles}
        />

        <div className="flex gap-x-5">
          <GraniteButton size="large" variant="secondary" onClick={onClose}>
            Cancel
          </GraniteButton>
          <GraniteButton
            size="large"
            disabled={!validUserList.length}
            onClick={() => inviteUsersMutation.mutate(validUserList)}
          >
            Invite users{' '}
            {validUserList.length > 0 ? `(${validUserList.length})` : ''}
          </GraniteButton>
        </div>
      </div>
    </Modal>
  );
};
