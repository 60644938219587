import { z } from 'zod';
import { VALID_PHONE_ERROR_LABEL } from 'shared/constants/error-labels';
import { VALID_PHONE_NUMBER } from 'shared/constants/validation-regex-constants';

export const NetOpsTicketIssue = z.enum([
  'Add Service',
  'Alert Testing',
  'Allow/Block Traffic',
  'Assumed Dial/Prepend',
  'Audio Drop-Outs',
  'Battery Failover',
  'Battery Problem',
  "Can't Be Called",
  "Can't Call Long Distance",
  "Can't Call Out",
  'Cellular',
  'Change Service',
  'Comm-Failure',
  'Content Filtering',
  'Degraded Service',
  'Device Offline',
  'Disconnect Service',
  'Dropped Calls',
  'Equipment Issue',
  'Equipment Update',
  'Ethernet',
  'Extension Dialing',
  'Fax Issue',
  'Fax Trouble',
  'Feature Issue',
  'Firewall Rule Change',
  'Fraud Alert',
  'Firmware Update',
  'Hard Down',
  'High Latency',
  'High Memory Usage',
  'High Utilization',
  'Informational',
  'Intermittent Service',
  'LAN Hard Down',
  'Packet Loss',
  'Project',
  'Service Down',
  'Slow Speeds',
  'WAN Hard Down',
]);

export const NetOpsTicketService = z.enum([
  'GRID',
  'Broadband',
  'DIA',
  'Private IP',
  'Granite Switched Ethernet',
  'MPLS',
  'Rebill - DIA',
  'Rebill - Broadband',
  'edgeboot',
  'Managed SD-WAN',
  'Managed Switch',
  'Managed Wi-Fi',
  'Proactive Ticketing',
  'Advanced Monitoring',
  'EPIK',
  'Mobility Access / M2M',
  'Wireless Voice',
  'SIP',
  'Hosted PBX',
  'ePOTS',
  'Toll-Free Number',
  'RCF',
]);

export const EditNocTicket = z.object({
  issue: z.object({ value: z.string(), label: z.string() }),
  customer_ticket_number: z.string().optional(),
  prior_ticket: z.string().or(z.literal('')).optional(),
  local_contact_name: z.string().nonempty('Required'),
  local_contact_number: z
    .string()
    .nonempty(VALID_PHONE_ERROR_LABEL)
    .length(10, VALID_PHONE_ERROR_LABEL)
    .regex(VALID_PHONE_NUMBER, VALID_PHONE_ERROR_LABEL),
  recipients: z.array(z.object({ email: z.string().email().nonempty() })),
  automatic_email_cc_address: z.string().optional(),
});

const TicketDetailsWithMaintenanceRequired = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.literal(false),
  maintenance_window: z.string().nonempty('Required'),
});

const TicketDetailsWithMaintenanceOptional = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.literal(true),
  maintenance_window: z.string().optional(),
});

const TicketDetailsWithMaintenanceUndefined = EditNocTicket.extend({
  canTestingBeDoneAnytime: z.undefined(),
  maintenance_window: z.string().optional(),
});

export const EditTicketDetailsFormSchema = z.discriminatedUnion(
  'canTestingBeDoneAnytime',
  [
    TicketDetailsWithMaintenanceRequired,
    TicketDetailsWithMaintenanceOptional,
    TicketDetailsWithMaintenanceUndefined,
  ],
);

export const NetOpsResponse = z.object({
  id: z.number(),
  summary: z.string(),
  city: z.string(),
  issue: z.string(),
  state_identifier: z.string(),
  zip: z.string(),
  item: z.string().nullable(),
  status_name: z.string(),
  last_updated: z.string(),
  date_entered: z.string(),
  company_name: z.string(),
  site_name: z.string().nullable(),
  address_1: z.string(),
  address_2: z.string(),
  location_name: z.string().nullable(),
  customer_ticket_number: z.string().nullable(),
  prior_ticket: z.number().nullable(),
  initial_description: z.string().nullable(),
  type_name: z.string(),
  sub_type: z.string(),
  technician_name: z.string().nullable(),
  local_contact_name: z.string().nullable(),
  local_contact_number: z.string().nullable(),
  special_instructions: z.string().nullable(),
  attachments: z.array(z.any()).optional(),
  automatic_email_contact: z.boolean().nullable(),
  contact_email_address: z.string().nullable(),
  automatic_email_cc: z.boolean().nullable(),
  automatic_email_cc_address: z.string().nullable(),
  maintenance_window: z.string().nullable(),
  service_id_label: z.string().nullable(),
  chronic_flag: z.boolean(),
  sites: z
    .array(
      z.object({
        id: z.string(),
        address_line_1: z.string(),
        city: z.string(),
        state: z.string(),
        zip: z.string(),
        account_name: z.string(),
        maintenance_window: z.string(),
        is_chronic: z.boolean().default(false),
        parent_account: z.string(),
      }),
    )
    .optional(),
  g360_escalated: z.boolean().optional(),
  g360_status: z.string(),
  configurations: z.array(z.any()),
  priority: z.string().optional(),
  company_identifier: z.string(),
  is_followed: z.boolean(),
  rca_requested: z.boolean(),
});
export type NetOpsResponseForm = z.infer<typeof NetOpsResponse>;

export type EditNocTicketForm = z.infer<typeof EditTicketDetailsFormSchema>;

export const EscalateTicketForm = z.object({
  reason: z.object({ value: z.string(), label: z.string() }),
  additional_notes: z.string().optional().or(z.literal('')),
});

export const EscalateTicketRequest = z.object({
  reason: z.string(),
  additional_notes: z.string().optional().or(z.literal('')),
});

export type EscalateTicketRequestType = z.infer<typeof EscalateTicketRequest>;

export type EscalateTicketFormType = z.infer<typeof EscalateTicketForm>;

export const CloseAndResolveTicketForm = z.object({
  reason: z.object({ value: z.string(), label: z.string() }),
  additional_notes: z.string().optional().or(z.literal('')),
});

export const CloseAndResolveTicketRequest = z.object({
  resolution_reason: z.string(),
  additional_details: z.string().optional().or(z.literal('')),
});

export type CloseAndResolveTicketRequestType = z.infer<
  typeof CloseAndResolveTicketRequest
>;

export type CloseAndResolveTicketFormType = z.infer<
  typeof CloseAndResolveTicketForm
>;

export const FollowTicketRequest = z.object({
  follow: z.boolean(),
});

export type FollowTicketRequestType = z.infer<typeof FollowTicketRequest>;
