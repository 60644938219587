import React from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';

export interface LoaderGraniteProps {
  className?: string;
  animationClassname?: string;
}

const Loader = ({ className, animationClassname }: LoaderGraniteProps) => {
  return (
    <div className={clsx(className, 'flex items-center justify-center')}>
      <div
        className={clsx(
          `h-10 w-10 animate-spin rounded-full border-4 border-t-4 border-solid border-black border-t-button-background-primary-default`,
          animationClassname,
        )}
      ></div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
