import { useQuery } from 'react-query';
import { getEmbeddedDashboard } from '../../api/nocexpress/api';
import { useAuth0User } from '../../hooks/useAuth0User';
import { ComponentProps } from 'react';

export type DashboardIframeProps = ComponentProps<'iframe'>;
export const DashboardIframe = (props: DashboardIframeProps) => {
  const { sub } = useAuth0User();
  const { data } = useQuery(['noc', 'dashboard', sub], getEmbeddedDashboard, {
    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  return <iframe {...props} srcDoc={data} />;
};
